import React, { useEffect } from 'react';
import happy_emoji from "../../../assets/images/notification/emoji-happiness.svg";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

interface IProps {
    lang: string
} 

export default function SubscriptionReferalWidget(props:IProps) {

    const { t } = useTranslation();

    useEffect(() => {   
        if(props.lang) {
            i18n.changeLanguage(props.lang);
        }
    }, [])

    return(
        <div className="subscription-referral-widget">
            <div className="top-container">
                <p className="">{t("FREE TRIAL")}</p>
                <p className="">{t("90 DAY 'FAMILY' SUBSCRIPTION")}*</p>
            </div>
            <div className="emoji-conatiner">
                <img className="emoji" alt="simle-emoji" style={{width: "80px", marginTop: "-45px"}} src={happy_emoji}/>
            </div>
            <div className="info-text-container">
                <p>{t("Register to access")}</p>
                <p className="gray-text">* {t("Up to 10 family members can use your Subscription and together upload up to 10GB in media files")}</p>
            </div>
        </div>
    )
}