import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import { Context } from "../../home.page"
import { IMediaQuality } from "../../../interfaces/authentication";
import instance from "../../../api/api";
import Help from "../../../components/_helper/help.component";
import { IOption } from "../../../interfaces/family";
import config from "../../../config/config";
import UniqueSingleSelect from "../../../components/_helper/unique-single-select.component";
import Notification, { notificationImages } from '../../../components/notifications/notification.component';
import { NotificationType } from '../../../interfaces/notification';
import { useTranslation } from "react-i18next";

/**
 * /settings/newsletters page
 * 
 */

function MediaQuality() {

    const userDetails = useContext(Context).user;    

    const [ image, setImage ] = useState<string>( (userDetails.settings && userDetails.settings.media) ? (userDetails.settings.media as IMediaQuality).image : "FULLHD" );
    const [ video, setVideo ] = useState<string>( (userDetails.settings && userDetails.settings.media) ? (userDetails.settings.media as IMediaQuality).video : "HD" );
    const [ audio, setAudio ] = useState<string>( (userDetails.settings && userDetails.settings.media) ? (userDetails.settings.media as IMediaQuality).audio : "96" );

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();

    useEffect(() => {
        setPageName('Media Quality');     
    }, []);

    useEffect(() => {
        // run update only if anything changes
        if(userDetails.settings && userDetails.settings.media && (
            (userDetails.settings.media as IMediaQuality).image !== image ||
            (userDetails.settings.media as IMediaQuality).video !== video ||
            (userDetails.settings.media as IMediaQuality).audio !== audio )) {
            updateUserData();
        }
    }, [image, video, audio]);

    /**
     * list the selected as IOption[]
     * @returns
     */
    function getImageQualityText() {
        return (config.mediaQuality.image.find(data => data._id === image) as IOption) ?
            { _id: image, text: (config.mediaQuality.image.find(data => data._id === image) as IOption).text.slice(3), img: "" } :
            { _id: "", text: "", img: "" };
    }
    function getVideoQualityText() {
        return (config.mediaQuality.video.find(data => data._id === video) as IOption) ?
            { _id: video, text: (config.mediaQuality.video.find(data => data._id === video) as IOption).text.slice(3), img: "" } :
            { _id: "", text: "", img: "" };
    }
    function getAudioQualityText() {
        return (config.mediaQuality.audio.find(data => data._id === audio) as IOption) ?
            { _id: audio, text: (config.mediaQuality.audio.find(data => data._id === audio) as IOption).text.slice(3), img: "" } : 
            { _id: "", text: "", img: "" };
    }

    async function updateUserData() {

        const details = {
            settings: {...userDetails.settings,
                media: {
                    image: image,
                    video: video,
                    audio: audio
                },
            },
        }

        try {
            const response = await instance.put('/user/' + localStorage.userid, {details:details});
            updateLoggedInUserData(response.data);
        }
        catch(error) {
            console.log('error', error)
        }
    }

    function handleActionImage(data:IOption){
        if(userDetails.subscription || userDetails.account==="team"){
            setImage(data._id)
        }
    }

    function handleActionVideo(data:IOption){
        if(userDetails.subscription || userDetails.account==="team"){
            setVideo(data._id)
        }
    }      

    function handleActionAudio(data:IOption){
        if(userDetails.subscription || userDetails.account==="team"){
            setAudio(data._id)
        }
    }    

    return (
        <div className="settings final media-quality">
            <div className="settings-container-media">

            {!(userDetails.subscription || userDetails.account==="team") ?
                <Notification data={
                    { icon: notificationImages.neutral, 
                    title: "Media Quality Options", 
                    text: "Editable only by Subscription users", 
                    success: NotificationType.info, closeBtnOff: true }} close={() => null}>
                </Notification> : null}
            <Row className="">
                <Col xs={12}>                 
                    <p>{t("The higher the quality, the faster your subscription's upload limit will be reached.")}</p>
                </Col>
            </Row>             
            {(userDetails.settings && userDetails.settings.media) ? 
                <div>
                    <Row className="setting-option-row quality-container">
                        <Col xs={12}>
                            <UniqueSingleSelect
                                placeholder={"Image"} 
                                handleAction={(data:IOption)=>handleActionImage(data)}
                                data={getImageQualityText()}
                                options={config.mediaQuality.image.filter(x => x._id !== image)}
                                slice={3}
                                viewOnly={(userDetails.subscription || userDetails.account==="team") ? false : true}

                            />
                        </Col>
                    </Row>
                    <Row className="setting-option-row quality-container">
                        <Col xs={12}>
                            <UniqueSingleSelect
                                placeholder={"Video"} 
                                handleAction={(data:IOption)=>handleActionVideo(data)}
                                data={getVideoQualityText()}
                                options={config.mediaQuality.video.filter(x => x._id !== video)}
                                slice={3}
                                viewOnly={(userDetails.subscription || userDetails.account==="team") ? false : true}                                                                
                            />
                        </Col>
                    </Row>
                    <Row className="setting-option-row quality-container">
                        <Col xs={12}>
                            <UniqueSingleSelect
                                placeholder={"Audio"} 
                                handleAction={(data:IOption)=>handleActionAudio(data)}
                                data={getAudioQualityText()}
                                options={config.mediaQuality.audio.filter(x => x._id !== audio)}
                                slice={3}
                                viewOnly={(userDetails.subscription || userDetails.account==="team") ? false : true}                                                          
                            />
                        </Col>
                    </Row>
                </div>
                : null}
                <Row className="">
                    <Col xs={12}>
                        <p className="quality">{t("Quality changes will not affect previously uploaded files.")}</p>
                    </Col>
                </Row>  
            </div>                 
            <Help category={"SETTINGS"} pageName={"Media_Quality"}></Help>  
        </div>
        
    )
}

export default MediaQuality
