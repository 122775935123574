import { mdiChevronDown, mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Switch from "react-switch"
import GoogleMap, { ILocation } from './google-map.component';
import { ProgressBar } from "react-bootstrap";

interface IProps {
    metaData:IMeta[]
    addToStory:Function
    isLoading:boolean
    updateStory:Function
    uploadPercentage: number
}


interface IMeta {
    date: Date | boolean
    location : ILocation | boolean
}

interface IDisabled {
    date: boolean
    location : boolean   
}

export default function ImageMetaDataEdit(props: IProps) {

    //Date
    const [ date, setDate ] = useState<Date>(new Date())
    const [ showDate, setShowDate ] = useState<boolean>(false);

    //Location
    const [ location, setLocation ] = useState<ILocation>({lat:0, lng:0});
    const [ showLocation, setShowLocation ] = useState<boolean>(false);

    //disable
    const [ disabled, setDisabled ] = useState<IDisabled>({date:false, location:false})

    useEffect(() => {
        let hasDate = false;
        let hasLocation = false;

        props.metaData.forEach((data: IMeta) => {
            if(data.date) {
                let metaDate = (data.date as Date);
                hasDate =true;
                setDate(metaDate);
                setShowDate(true);
            }

            if(data.location) {
                let metaLoc = (data.location as ILocation);
                hasLocation = true;
                setLocation(metaLoc);
                setShowLocation(true);
            }
        })

        setDisabled({date:!hasDate, location:!hasLocation})
    }, [props.metaData]);
/*
    useEffect(() => {
        updateStoryDataFromMetaData();
    }, [date, location])
*/
    async function updateStoryDataFromMetaData() {
        if(showDate) {
            const metaDate = {
                startDate: {
                    date: date,
                    year: new Date(date).getFullYear(),
                    month: new Date(date).getMonth(),
                    day: new Date(date).getDate()
                }
            }
            props.updateStory("date", metaDate);
        }

        if(showLocation) {
            props.updateStory("sLocation", location);
            let country = await reverseGeoCode();
            if(country?.length) {
                props.updateStory("country", country)
            }
        }

        props.addToStory();
    }

    async function reverseGeoCode() {
        const google = window.google;
        if(google) {
            const geocoder = new google.maps.Geocoder();

            try {
                let geoRes = await geocoder.geocode({ location: location })
                let country = '';
                geoRes.results[0].address_components.forEach(data => {
                    if(data.types.includes('country')) {
                        country = data.short_name;
                    }
                })
    
                return country
            }
            catch(err) {
                return "HU"
            }
        }
        else {
            console.log('google did not worked')
        }
    }
/*
    function updateStoryLocation(loc:ILocation) {
        props.updateStory("sLocation", loc);
    }
*/
    function cancel() {
        props.updateStory("sLocation", {lat:0, lng:0});
        const date = new Date();
        const metaDate = {
            startDate: {
                date: date,
                year: new Date(date).getFullYear(),
                month: new Date(date).getMonth(),
                day: new Date(date).getDate()
            }
        }
        props.updateStory("date", metaDate);
        props.addToStory();
    }

    return(
        <div className="image-metadata-edit">
            <p className="main">Update story's date & location?</p>
            <p className="subtitle">Obtained from your media file(s)</p>
            <div className="date switch-container">
                <div className="infos">
                    <span>Date</span>
                </div>
                <div className="status">
                    <p className="switch-status">{showDate ? "On" : "Off"}</p>
                    <Switch
                        className={`date-container ${showDate ? "active-gold-switch" : ""}`}
                        offColor="#17243d"
                        offHandleColor="#8B919E"
                        onColor="#006238"
                        onHandleColor="#006238"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={32}
                        onChange={(checked) => setShowDate(checked)}
                        checked={showDate}
                        disabled={disabled.date}
                    />
                </div>
            </div>
            {showDate &&
                <div className="dates-input-container">
                    <div className={`date-container`}>
                        <label className="top-label">Day</label>
                        <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                        <ReactDatePicker
                            peekNextMonth
                            selected={date}
                            onChange={(date : Date) => setDate(date)}
                            dateFormat="dd"
                            dropdownMode="select"
                        />
                    </div>
                    <div className={`date-container`}>
                        <label className="top-label">Mth</label>
                        <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                        <ReactDatePicker
                            selected={date}
                            onChange={(date : Date) => setDate(date)}
                            dateFormat="MMM"
                            showMonthYearPicker
                        />
                    </div>
                    <div className="date-container">
                        <label className="top-label">Year</label>
                        <Icon className="calendar-icon" size={1} path={mdiMenuDown} />
                        <ReactDatePicker 
                            selected={date}
                            onChange={(date : Date) => setDate(date)}
                            showYearPicker 
                            dateFormat="yyyy" 
                        />
                    </div>
                </div>}
            <div className="location switch-container">
                <div className="infos">
                    <span>Location</span>
                </div>
                <div className="status">
                    <p className="switch-status">{showLocation ? "On" : "Off"}</p>
                    <Switch
                        className={`date-container ${showLocation ? "active-gold-switch" : ""}`}
                        offColor="#17243d"
                        offHandleColor="#8B919E"
                        onColor="#006238"
                        onHandleColor="#006238"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={32}
                        onChange={(checked) => setShowLocation(checked)}
                        checked={showLocation}
                        disabled={disabled.location}
                    />
                </div>
            </div>
            <div className="map-container">
                {showLocation && <GoogleMap id="meta-map" setLocation={setLocation} specificLocation={location} /> }
            </div>
            <button disabled={props.isLoading} className="default-button add-button" onClick={() => { updateStoryDataFromMetaData() }}>
                Add to story
                {props.isLoading ? <ProgressBar className="progressbar" now={props.uploadPercentage} /> : <></>}
            </button>
            {/*<button className="light-button" onClick={cancel}>Cancel</button>*/}
        </div>
    )
}