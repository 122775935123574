import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import instance from '../../../api/api';
import { IStory } from '../../../interfaces/story';
import { DisplayStory } from '../../../components/story/blocks/display-story.component';
import { mdiLightbulbOn } from '@mdi/js';
import Icon from '@mdi/react';


interface IProps {
    storyId:string
}

export default function AttachedStory(props:IProps) {

    const [ story, setStory ] = useState<IStory | null>(null);
    const [ error, setError ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(true);

    const history = useHistory();    

    useEffect(() => {
        getPublicStory();
    }, []);

    async function getPublicStory() {
        try {
            const response = await instance.get('/publishedstories/' + props.storyId);
            setStory(response.data)
            setLoading(false);
            // TODO: medium: if 403 then no access to story, pls ask... ------ ?? what is this sentance even mean ??

        }
        catch(err) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div className="public-story-page attached-story">
            <div className="new-block">
                <div className="add-story-svg">
                    <Icon size={2.5} path={ mdiLightbulbOn } />                    
                    {/*<img src={feather} alt="" style={{zIndex: 100, position: "relative", top: "-8px"}} />*/}
                    <div className="line"></div>
                </div>
            </div>
            <p className="attachment-title">Attached Story</p>            
            {loading ?
                <div className='loader-container'>
                    <Spinner animation="border" variant="#fff"/>
                </div>
                : error ?
                <div className='error-container'>
                    <p>You don't have access to the Requester’s story. Ask for access.</p>
                </div>
                : story !== null ?
                <div className="story-container">
                    <div className="story-details">
                        <div className="story-body">
                            <p className="title">{story.title}</p>
                            <DisplayStory story={story} showInteractions={false} />
                        </div>
                    </div>
                    <button className="default-button" onClick={() => history.push('/dashboard/story/' + story._id)}>Open Story</button>                    
                    <button className="cancel-button" onClick={() => window.scrollTo(0, 0)}>Back To Top</button>
                </div>            
            : null}
        </div>
    );
}
