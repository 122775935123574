import React, { useCallback, useState, useEffect, useContext, Dispatch } from "react";
import { Accordion, Form, InputGroup, FormControl } from "react-bootstrap";
import Icon from "@mdi/react";
import Markdown from "markdown-to-jsx";
import { mdiMagnify, mdiPlusCircleOutline, mdiMinusCircle } from "@mdi/js";
import { Context } from "../home.page";
import { IHelpCategory } from "../../components/_helper/help.component";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/actionCreators";

/**
 * Help page
 *
 */
function Help() {
  const dispatch: Dispatch<any> = useDispatch();
  const setPageName = useCallback(
    (currentPage: string) => dispatch(setCurrentPage(currentPage)),
    [dispatch]
  );
  setPageName("Help");

  // inner "accordion"
  const [currentAccordionOpened, setCurrentAccordionOpened] = useState("");
  const toggleAccordionItemHandler = (idx: string) => {
    idx === currentAccordionOpened ? setCurrentAccordionOpened("") : setCurrentAccordionOpened(idx);
  }

  // outer "accordion" categories
  const [currentCategoryOpened, setCurrentCategoryOpened] = useState("");
  const toggleCategoryItemHandler = (idx: string) => {
    idx === currentCategoryOpened ? setCurrentCategoryOpened("") : setCurrentCategoryOpened(idx);
    
    idx === currentCategoryOpened ? setAddShadow(false) : setAddShadow(true);
    };
  
  // drop shadow for current category accordion
  const [addShadow, setAddShadow] = useState(false);

 /* useEffect(() => {

  });*/


  const helpCategories: IHelpCategory[] = useContext(Context).helpCategories;
  let lastCategory = "";
  let titleMyAccount = "My Subscription";
  let titleRefer = "Refer & Save";

  return (
    <div className="help-page-container">
      <Accordion className="outer-accordion">
        {helpCategories.map((itemCategory, idx) => {
          return (
            <div id={("item-" + idx)}>
              {lastCategory !== itemCategory.attributes.category && (
                <p className="category-title">
                  {itemCategory.attributes.category === 'MyAccount' ? (
                    titleMyAccount
                  ) : itemCategory.attributes.category === 'ReferSave' ? (
                    titleRefer
                  ) : (
                    itemCategory.attributes.category
                  )}
                </p>
              )}
             <Accordion.Item eventKey={"cat-" + idx.toString()} className={("cat-" + idx.toString() === currentCategoryOpened) ? "accordion-shadow" : ""}>
                <Accordion.Header onClick={()=> { setCurrentAccordionOpened(""); setAddShadow(true); toggleCategoryItemHandler("cat-" + idx.toString());}}>
                  <p className="title category-name" >
                    {itemCategory.attributes.category.toUpperCase()} -{" "}
                    {itemCategory.attributes.page_name.toUpperCase()}
                  </p>
                </Accordion.Header>

                {itemCategory.attributes.help_questions?.data &&
                  itemCategory.attributes.help_questions.data.map((item, idxQA) => {
                    return (
                      <Accordion.Body>
                        <div
                          className="accordion-item-container"
                          onClick={() => {
                            toggleAccordionItemHandler(idxQA.toString());
                          }}
                        >
                          <div className="accordion-header">
                            <div className="icon-container">
                            <Icon size={1} path={ currentAccordionOpened === idxQA.toString() ? mdiMinusCircle : mdiPlusCircleOutline
                              }
                            />
                            </div>
                            <p className={"title " + (currentAccordionOpened === idxQA.toString() ? " opened" : "")}>{item.attributes.question}</p>
                          </div>
                          {currentAccordionOpened === idxQA.toString() && (
                            <p className="answer">
                              <Markdown>{item.attributes.answer}</Markdown>
                            </p>
                          )}
                        </div>
                      </Accordion.Body>
                    );
                  })}
              </Accordion.Item>

              <p className="hidden">{(lastCategory = itemCategory.attributes.category)}</p>
            </div>
          );
        })}
      </Accordion>
    </div>
  );
}

export default Help;
