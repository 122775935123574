import React, { useContext, useEffect, useRef, useState } from 'react';
import { mdiEmoticonOutline, mdiMenuDown, mdiMenuUp, mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import { Form } from 'react-bootstrap';
import Comment from './comment.component';
import { Context } from '../../pages/home.page';
import { io, Socket } from 'socket.io-client';
import commentAPIInstance from '../../api/comment_api';
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../notifications/notification.component";

// emojis
import emoji_crying from "../../assets/images/notification/emoji-crying.svg";
import emoji_happiness from "../../assets/images/notification/emoji-happiness.svg";
import emoji_in_love from "../../assets/images/notification/emoji-in_love.svg";
import emoji_laughing from "../../assets/images/notification/emoji-laughing.svg";
import emoji_like from "../../assets/images/notification/emoji-like.svg";
import emoji_supervised from "../../assets/images/notification/emoji-surprised.svg";
import instance from '../../api/api';

/**
 * Local interface for input properties.
 */
interface IProps {
    title:string
    related_entity:string
    access_entity?:string
    accessToken: string
    comment_type:ECommentType
    commentsEnabled:boolean
    addCommentCallback?: Function
}

interface IComment {
    comment_type:ECommentType
    related_entity:string
    access_entity?:string
    user:string
    data:string
    emoji:string
}

interface ISimpleUser {
    firstname:string,
    lastname:string,
    nickname?:string,
    avatar:string
}

export interface ICommentDB extends IComment {
    _id:string
    createdAt:Date
    updatedAt:Date
    userdata:ISimpleUser
    edited:boolean
}

export enum ECommentType {
    STORY="STORY",
    MEDIA="MEDIA",
    COAUTHOR="COAUTHOR",
    REQUEST="REQUEST"
}

export enum ECommentEmoji {
    NONE="NONE",
    LIKE="LIKE",
    HAPPY="HAPPY",
    LOVE="LOVE",
    HAHA="HAHA",
    WOW="WOW",
    SAD="SAD",
}

export const all_emojis:IGenericObject = {
    "LIKE":emoji_like,
    "HAPPY": emoji_happiness,
    "LOVE":emoji_in_love,
    "HAHA":emoji_laughing,
    "WOW":emoji_supervised,
    "SAD":emoji_crying,

}

interface IGenericObject {
    [key: string]: any;
}

/**
 * @param props :
 * @returns void
 */
function CommentsContainer(props:IProps) {

    const [ comments, setComments] = useState<ICommentDB[]>([]);
    const [ commentUserData, setCommentUserData ] = useState<any>({});

    const [notification, setNotification] = useState<INotification>({icon:notificationImages.crying, title:"", text:<p>Try again later and if problem persists contact  <a href = "mailto: info@simirity.com">info@simirity.com</a>.</p>, success: NotificationType.problem});

    //comment adding
    const [ commentText, setCommentText ] = useState<string>("");
    const commentTextRef = useRef<any>(undefined);
    useEffect(() => {
        commentTextRef.current = commentText;
    }, [commentText]);

    const [ commentEmoji, setCommentEmoji ] = useState<string>(ECommentEmoji.NONE);
    const commentEmojiRef = useRef<any>(undefined);
    useEffect(() => {
        commentEmojiRef.current = commentEmoji;
    }, [commentEmoji]);

    const [ typing, setTyping ] = useState<string[]>([]);
    const [ timeOut, setTimeOut ] = useState<any>(undefined);

    //unique input
    const [ focused, setFocused ] = useState<boolean>(false);

    //pagination
    const [ pageNo, setPageNo ] = useState<number>(0);
    const [ hasNextPage, setHasNextPage ] = useState<boolean>(false);

    //Textarea
    const [ rows, setRows ] = useState<number>(1);
    const [ minRows, ] = useState<number>(1);
    const [ maxRows, ] = useState<number>(15);


    const focusedRef = useRef<any>(undefined);
    useEffect(() => {
        focusedRef.current = focused;
    }, [focused]);

    const [ showEmojiRow, setShowEmojiRow ] = useState<boolean>(false);
    const onFocus = () => setFocused(true);
    const wrapperRef = useRef<any>(null);

    useEffect(() => {

        function handleClickOutside(event:any) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if(focusedRef.current && !commentTextRef.current.length && commentEmojiRef.current === ECommentEmoji.NONE) {
                    setShowEmojiRow(false);
                    setFocused(false)
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
            return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [wrapperRef]);
    
    //context
    const userData = useContext(Context).user;

    //Socket io
    const [ socket, setSocket ] = useState<Socket>();

    const commentsRef = useRef<ICommentDB[]>([]);

    useEffect(() => {
        commentsRef.current = comments;
    }, [comments]);

    const typingRef = useRef<string[]>([]);
    useEffect(() => {
        typingRef.current = typing;
    }, [typing]);

    const timeOutRef = useRef<any>(undefined);
    useEffect(() => {
        timeOutRef.current = timeOut;
    }, [timeOut]);

    useEffect(() => {
        getComments();
    }, [pageNo])

    useEffect(() => {
        if(window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL && userData) {        
            // connect to socket
            const socket = io(window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL, {
                reconnectionDelayMax: 10000,
                query: {
                    entity: props.related_entity
                }
            });

            setSocket(socket);

            socket.on("typing", data => {

                if(timeOutRef.current) {
                    clearTimeout(timeOutRef.current);
                    setTimeOut(undefined);
                }

                if(!(typingRef.current.includes(data)) && data !== userData.firstname + " " + userData.lastname) {
                    setTyping(prev => [...prev, data]);
                }

                let t = setTimeout(() => {
                    setTyping(prev => prev.filter(x => x !== data));
                }, 2000);
                
                setTimeOut(t)
            })
            

            socket.on(props.related_entity, data => {

                // delete on different user's screen
                if(data && data?.access_entity==="DELETE" && data.user !== userData._id && commentsRef.current){
                    const temp = commentsRef.current.filter(comment => comment._id !== data._id.toString());
                    setComments(temp);
                }

                // same user's screen
                if(data && data?.access_entity!=="DELETE" && userData && data.user === userData._id && commentsRef.current) {
                    const updated = commentsRef.current.find(comment => comment._id === data._id.toString());

                    if(updated) {
                        const temp = commentsRef.current.map(comment => {
                            if(comment._id === data._id) {
                                return data
                            }
                            else {
                                return comment
                            }
                        })
                        setComments(temp);
                    }
                }

                // different user's screen                
                if(data && data?.access_entity!=="DELETE" && data.user !== userData._id && commentsRef.current) {
                    if(commentsRef.current) {
                        const included = commentsRef.current.findIndex(comment => comment._id === data._id)
                        if(included===-1) {
                            // creates comment
                            const temp = [data, ...commentsRef.current];
                            setComments(temp);
                        } else {
                            // updates comment
                            let temp = [];
                            for(let i = 0; i < commentsRef.current.length; i++) {
                                temp[i] = commentsRef.current[i]._id === data._id ? data : commentsRef.current[i];
                            }
                            setComments(temp);
                        }
                    }
                }
            })
        }

        return () => {
            socket?.disconnect();
        }
    }, [userData])

    useEffect(() => {
        checkCommentUsers();
    }, [comments])

    async function checkCommentUsers() {
        comments.forEach(async (comment:IComment) => {
            if(!commentUserData.hasOwnProperty(comment.user)) {
                try {
                    const userResponse = await instance.get("/user/" + comment.user);

                    setCommentUserData((prev:any) => { return {
                        ...prev,
                        [comment.user] : {
                            firstname:userResponse.data.firstname,
                            lastname:userResponse.data.lastname,
                            avatar:userResponse.data.avatar
                        }
                    }})
                }
                catch(err:any) {
                    setCommentUserData((prev:any) => { return {
                        ...prev,
                        [comment.user] : {
                            firstname:"Deleted",
                            lastname:"User",
                            avatar:""
                        }
                    }})
                }
            }
        })
    }

    async function getComments() {
        try {
            if(!window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL) {
                return;
            }

            const res = await commentAPIInstance.get(window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL, {
                params: {
                    pageNo:pageNo,
                    query :{
                        related_entity:props.related_entity,
                        comment_type:props.comment_type
                    }
                },
                headers: {"Authorization" : `Bearer ${props.accessToken}`}
            });

            if(res.data) {
                setComments(prev => [...prev, ...res.data.result]);
                setHasNextPage(res.data.hasNextPage);
            }
        }
        catch(error) {
            console.log('Failed to get comments ', error)
        }
    }

    async function addComment() {
        if(!commentText.length && commentEmoji === ECommentEmoji.NONE) {
            return
        }

        try {

            if(!window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL) {
                return;
            }

            const commentData:IComment = {
                related_entity:props.related_entity,
                access_entity: props.access_entity,
                comment_type:props.comment_type,
                user:userData._id,
                data:commentText,
                emoji:commentEmoji
            }

            const res = await commentAPIInstance.post(window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL, {
                data: commentData,
            }, { headers: {"Authorization" : `Bearer ${props.accessToken}`} })
            if(res.data) {

                // send notification
                await instance.post('/notification/comment', {data:commentData});
                props.addCommentCallback && props.addCommentCallback()

                setComments([]);
                setCommentText('');
                setCommentEmoji(ECommentEmoji.NONE);
                setShowEmojiRow(false);
                setFocused(false);                

                if(pageNo === 0) {
                    getComments();
                }
                else {
                    setPageNo(0);
                }
            }
        }
        catch(error) {
            // display error notification
            setNotification({
                icon: notificationImages.crying,
                title: "Failed to create comment",
                text: (
                    <p>
                        Try again later and if problem persists contact <a href="mailto: info@simirity.com">info@simirity.com</a>.
                    </p>
                ),
                success: NotificationType.problem,
            })                        
            console.log('Failed to add comment ', error)
        }
    }

    function handleCommentChange(event:any) {
        socket?.emit(props.related_entity, `${userData.firstname} ${userData.lastname}`)

        const textareaLineHeight = 24;
		
		const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea 
            
            const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);
        
        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }
            
        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        if(!event.target.value.length) {
            setRows(1);
        }
        else {
            setRows(currentRows < maxRows ? currentRows : maxRows);

        }
       
        setCommentText(event.target.value);
    }

    function deleteComment(id:string) {
        const temp = comments.filter(comment => comment._id !== id);
        setComments(temp);
    }

    function enterSubmit(e:any) {
        if(e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            addComment();
        }
    }

    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    } 

    return(
        <div className="comments-container" >
            <p className='title'>{props.title}</p>
            <div ref={wrapperRef} className="add-comment-container">
                <div className="curently-typing">
                    {typing.length ? `${typing.join(", ")} is typing ...` : null} &nbsp;
                </div>                    
                {props.commentsEnabled ? 
                <div className={focused ? "input-container focused" : "input-container"}>
                    <Form.Group className="group">
                        <textarea
                            onFocus={onFocus}
                            rows={rows}
                            value={commentText}
                            placeholder={'Add comment…'}
                            className={'textarea'}
                            onKeyPress={enterSubmit}
                            onChange={handleCommentChange}
                        />
                    </ Form.Group>
                    {!focused && <div className={`send-icon-outer ${commentEmoji !== ECommentEmoji.NONE || commentText.length ? "enabled" : ""}`} onClick={() => addComment()}>
                        <Icon className="accept" size={1} path={mdiSend} />
                    </div>}
                </div>:
                <p className="closed">Comments are closed.</p>}
                {focused && 
                <div className="emoji-picker">
                    <div className={focused ? "action-row focused-emoji" : "action-row"}>
                        <div className='open-emoji-container' onClick={() => setShowEmojiRow(!showEmojiRow)}>
                            {!commentEmoji || commentEmoji === ECommentEmoji.NONE ? <div className="default-select">
                                <div>
                                    <Icon className="accept" size={1} path={mdiEmoticonOutline} />
                                </div>
                                <div>
                                    <Icon className="accept" size={0.9} path={showEmojiRow ? mdiMenuUp : mdiMenuDown} />
                                </div>
                            </div>
                            :
                            <div className='selected-emoji'>
                                <img src={all_emojis[commentEmoji]} alt="comment-emoji"/>
                                <p>{commentEmoji.toLowerCase()}</p>
                            </div>}
                        </div>
                        <div className={`send-icon-container ${commentEmoji !== ECommentEmoji.NONE || commentText.length ? "enabled" : ""}`} onClick={() => addComment()}>
                            <Icon className="accept" size={1} path={mdiSend} />
                        </div>
                    </div>
                </div>
                }
                {showEmojiRow && 
                <div className={`emoji-row-container`}>
                    {Object.entries(all_emojis).map(([key, value]) => {
                        return(
                            <div className='emoji' onClick={() => { setCommentEmoji(key); setShowEmojiRow(false) }}>
                                <img src={value} alt={key} />
                                <p>{key.toLowerCase()}</p>
                            </div>
                        )
                    })}
                </div>}
            </div>

            { notification.title.length ? <Notification data={notification} close={() => resetNotification()} /> : <></> }

            {comments.length ?
                <div>
                    <div className='comments'>
                        {comments.map((comment:ICommentDB) => {
                            return(
                                <Comment accessToken={props.accessToken} comment={comment} user={commentUserData[comment.user]} deleteComment={deleteComment} />
                            )
                        })}
                    </div>
                    {hasNextPage && <div className='next-page'>
                            <button className="bordered-button" onClick={() => setPageNo(prev => prev + 1 )}>Load more</button>
                        </div>}
                </div>
                : 
                <p className="no-comments">No comments yet.</p>
            }
        </div>
    );
};

export default CommentsContainer;