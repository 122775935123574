import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { dateFormat } from '../../../_helper/enum/enum';
import instance from "../../../api/api";
import { Context } from "../../../pages/home.page";
import default_avatar from "../../../assets/images/default-avatar.jpg";
import { IUserDetails } from '../../../interfaces/authentication';
import StoryRequest from './story-request-component';
import gold_flag from "../../../assets/images/bg-flag-gold.svg";
import TooltipHelp from "../../notifications/tooltiphelp.component";
import { useHistory, useParams, useLocation } from "react-router";
import { unReadTooltip } from "../story-published-all.component";

export interface IRequest {
    _id:string
    title:string
    from:IUserDetails
    to:IRequestTo[]
    createdAt:Date
    notes:string
    attachedStory?:string
    attachedInspirationQ?:string
    attachedInspirationCat?:string
    accessCommentToken?: string
}

export interface IRequestTo {
    _id:string
    user:IUserDetails
    status:string
    read:boolean
}

interface IProps {
    requestId?: string
}

export default function StoryReceivedRequests(props:IProps) {

    const [ receivedRequests, setReceivedRequests ] = useState<IRequest[]>([]);
    const [ isSingleRequest, setIsSingleRequest ] = useState<IRequest | boolean>(false);
    const [ showUnRead, setShowUnRead ] = useState<boolean>(false);
    const [ scroll, setScroll ] = useState<boolean>(false);    

    const userData = useContext(Context).user;
    const history = useHistory();
    const location: any = useLocation();
    const params : any = useParams();

    const pageData = useContext(Context).pageData;
    const updatePageData = useContext(Context).setPageData;

    useEffect(() => {
        if ( userData._id.length ) {
            getReceivedRequests();
        }
    }, [userData]);

    useEffect(() => {
        if(params.list==='list') { 
            setIsSingleRequest(false);      
        }
    }, [location]); 

    useEffect(() => {
        if(location.state) {         
            if(location.state.hasOwnProperty('scroll')) {
                setScroll(true);
            }
        }
    }, [location.state]); 

    useEffect(() => {
        // manage if requestId comes from a notification
        if(props.requestId && receivedRequests.length){
            const temp = receivedRequests.filter(request => request._id === props.requestId);
            if(temp) setIsSingleRequest(temp[0]);
            history.replace("/dashboard/requests", {});
        }
    }, [props.requestId, receivedRequests]);    

    async function getReceivedRequests() {
        try {
            const response = await instance.get("/requests?to=" + userData._id);
            setReceivedRequests(response.data);

            // add number of received requests to Context
            if(response.data){
                updatePageData({ ...pageData, receivedRequests:response.data.length});
            }            
        }
        catch(err) {

        }
    }

    function setList(){
        setIsSingleRequest(false);
    }

    function updateList(){
        getReceivedRequests();
    }    

    return(
        <div className="unpublished-stories">
            {!isSingleRequest ? receivedRequests.length ? <p className='information'>Received Requests awaiting decision</p> : <p className='information'>You haven't received any request yet.</p> : null}            
            {!isSingleRequest && receivedRequests?.map(request => {
                return(
                    <div key={request._id} className="story-requests-received up-story" onClick={() => {setIsSingleRequest(request);history.push("/dashboard/requests/3/request")}}>
                        <p className='story-title'>{request.title}</p>

                        <img className="user-picture" src={request.from.avatar ? request.from.avatar : default_avatar} alt="user" />
                        <p className="author">{request.from.nickname ? request.from.nickname  : request.from.firstname}</p>
                        

                        <div>
                            {!request.to.filter(x=>x.user._id === userData._id)[0].read ? 
                                <div className="flair" onClick={(e) => { e.stopPropagation(); setShowUnRead(true)}}>
                                    <img alt="flag" src={gold_flag}/>
                                </div> 
                            : null }
                        </div>

                        <p className="last-modified">{moment(request.createdAt).format(dateFormat.SHORT)}</p>                      
                    </div>                        
                );                
            })}
            {isSingleRequest ? <StoryRequest type={"received"} request={isSingleRequest} setList={setList} updateList={updateList} scroll={scroll} /> : null}
            <TooltipHelp
                title={unReadTooltip.title}
                subtitle={unReadTooltip.subtitle}
                text={unReadTooltip.text}
                show={showUnRead}
                clear={() => setShowUnRead(false)}
            />            
        </div>        
    );
}