import { useEffect, useState } from "react";
import { blockTypes, IBlock } from "../../../../interfaces/story";
import { INotification, NotificationType } from "../../../../interfaces/notification";
import Notification, { notificationImages } from "../../../notifications/notification.component";
import config from "../../../../config/config";
import Help from "../../../_helper/help.component";

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    description?: string
}

const errorNotificationData : INotification = {
    icon: notificationImages.crying,
    title: "Failed",
    text: "The link you entered does not match the format of the providers available.",
    success: NotificationType.problem
}

export default function LinkalbumBlockInput(props: IProps) {

    const [ data, setData ] = useState<string>('');
    const [ desc, setDesc ] = useState<string>('');

    //notifications
    const [ errorNotification, setErrorNotification ] = useState(false);    

    useEffect(() => {
        if(props.data) {
            setData((props.data as IBlock).data);
        }

        if((props.data as IBlock).description) {
            setDesc(((props.data as IBlock).description as string));
        }


    }, [])   

    function addToStory() {

        if(!data) return;

        let regex : RegExp;
        let hasMatch : string = "";

        config.linkAlbumProviders.forEach((item: any) => {
            regex = new RegExp(item.regExp, "g")                
            if(data.match(regex) && (data.match(regex) as string[])[0] === data) hasMatch = item._id;
        });         

        if(!hasMatch.length) {
            setErrorNotification(true)
            return;
        }

        props.addBlockToStory({data:data, description:desc, tags:[hasMatch], type:blockTypes.LINK_ALBUM})

    }      

    return(
        <div className="linkalbum-container">
            <div className="inner">
                <p className="subtitle">Connect existing online albums of your family with Simirity</p>           
                <div className="inputs">
                    <div className="group">
                        <textarea placeholder="Link" rows={3} value={data} onChange={(evt) => evt.target.value.length <= 100 ? setData(evt.target.value) : false} />
                        <label className="top-label">Link</label>
                        <p className="sub-subtitle right">{data.length} / 100</p>
                        <p>Allowed providers:</p>
                        <ul>
                            <li>Google Photos</li>
                            <li>Flickr</li>
                        </ul>
                    </div>
                    <div className="group">
                        <input placeholder="Description" value={desc} onChange={(evt) => evt.target.value.length <= 50 ? setDesc(evt.target.value) : false} />
                        <label className="top-label">Description</label>
                        <p className="sub-subtitle right">{desc.length} / 50</p>                    
                    </div>
                </div>                  

                {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}            

                <button className="default-button" disabled={(data.length > 0 ? false : true)} onClick={() => addToStory()}>Add to Story</button>
            </div>            
            <Help category={"PUBLISHING"} pageName={"Adding Link to Albums"}></Help>          
        </div>
        )
}