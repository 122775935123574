import React, { useContext, useEffect, useRef, useState } from 'react';
import { blockTypes, fileTypes, IBlock, IMedia } from '../../../interfaces/story';
import parse from 'html-react-parser';
import Icon from '@mdi/react';
import { mdiAccount, mdiArrowDown, mdiArrowUp, mdiBackspace, mdiCheck, mdiClose, mdiCloudDownload, mdiContentCopy, mdiDotsHorizontal, mdiMicrophone, mdiPause, mdiPencil, mdiPlay, mdiPlus, mdiVolumeHigh, mdiCommentOutline } from '@mdi/js';
import WaveSurfer from 'wavesurfer.js';
import { Button, Carousel, Form, Modal, Spinner } from 'react-bootstrap';
import UniqueListInput from '../../_helper/unique-list-input.component';
import { IOption } from '../../../interfaces/family';
import AudioRecorder from '../../_helper/audio-recorder.component';
import { Context } from '../../../pages/home.page';
import { instance } from "../../../api/api";
import { instanceUpload } from "../../../api/upload";
import Notification, { notificationImages } from '../../notifications/notification.component';
import { INotification, NotificationType } from '../../../interfaces/notification';
import VideoPlayer from '../../_helper/video-player.component';
import { numOfTaggedString } from './display-story.component';
import config from '../../../config/config';
import {ReactComponent as IconLifelesson} from "../../../assets/images/icon-life_lesson.svg";
import { formatBytes } from '../../../_helper/format-bytes';
import DOMPurify from 'dompurify';
import ConfirmModal from '../../_helper/confirm-modal.component';
import iconFlickr from "../../../assets/images/icon-flickr.png";
import iconGooglephotos from "../../../assets/images/icon-google_photos.png";
import Help from '../../_helper/help.component';
import { getDotClassName } from '../../../_helper/carousels';

interface IProps {
    block: IBlock
    updateBlock: Function
    index: number
    editBlock: Function
    deleteBlock: Function
    updateBlockMedia: Function
    showImageCarousel: Function
    isAuthor: boolean
    blocksLength: number
}

interface IDisplayComponent {
    updateBlockMedia: Function
    block:IBlock
    index:number
    showImageCarousel?: Function
    isAuthor?: boolean
    
}

export function Block(props:IProps) {

    const [ edit, setEdit ] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);    
    const wrapperRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);

    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node)) {
            setEdit(false);
        }
    };

    function displayBlock(block: IBlock, index:number) {
        switch (block.type) {
            case blockTypes.TEXT:
                return <DisplayTextEdit updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.IMAGE:
                return <DisplayImageEdit isAuthor={props.isAuthor} showImageCarousel={props.showImageCarousel} updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.VIDEO:
                return <DisplayVideoEdit isAuthor={props.isAuthor} updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.CAROUSEL:
                return <DisplayCarouselEdit isAuthor={props.isAuthor} updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.AUDIO:
                return <DisplayAudioEdit updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.LIFE_LESSON:
                return <DisplayLifelessonEdit updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.ATTACHMENT:
                return <DisplayAttachmentEdit updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
            case blockTypes.EMBED:
                return <DisplayEmbedEdit updateBlockMedia={props.updateBlockMedia} block={block} index={index} />                                     
            case blockTypes.LINK_ALBUM:
                return <DisplayLinkalbumEdit updateBlockMedia={props.updateBlockMedia} block={block} index={index} />
        }
    }

    function editData() {
        props.editBlock(props.block, props.index);
        setEdit(false);
    }

    function moveUp() {
        if(props.index >= 0) {
            props.updateBlock(props.block, props.index-1)
        }
        setEdit(false);

    }

    function moveDown() {
        props.updateBlock(props.block, props.index+1)
        setEdit(false);

    }

    async function remove() {

        props.deleteBlock(props.block._id);
        setEdit(false);
        setShowConfirmModal(false);
        // TODO: error handling? errors will not come back to confirm popup

    }

    function duplicate() {
        let newBlock = {...props.block}
        delete newBlock._id
        props.updateBlock(newBlock, props.index+1);
        setEdit(false);
    }

    return(
        <div className={"block " + props.block.type}>
            <div>
                <div className="edit-icon pen" onClick={() => editData()}>
                    <Icon className="story-edit" size={0.9} path={mdiPencil}/>
                </div>                
                <div className="edit-icon" onClick={() => setEdit(!edit)}>
                    <Icon className="story-edit" size={1.0} path={mdiDotsHorizontal}/>
                </div>
                {edit && <div className="story-block-edit" ref={wrapperRef}>
                    <p onClick={duplicate}><Icon className="edit-svg" size={0.8} path={mdiContentCopy}/> Duplicate</p>
                    {props.index > 0 &&<p onClick={moveUp}><Icon className="edit-svg" size={0.8} path={mdiArrowUp}/> Move up</p>}
                    {props.index+1 < props.blocksLength &&<p onClick={moveDown}><Icon className="edit-svg" size={0.8} path={mdiArrowDown}/> Move down</p>}
                    <p onClick={() => setShowConfirmModal(true)}><Icon className="edit-svg" size={0.8} path={mdiBackspace}/> Delete</p>
                </div>}
                {displayBlock(props.block, props.index)}
                {showConfirmModal ? 
                    <div className="edit" ref={wrapperRef}>
                    <ConfirmModal
                        show={showConfirmModal}
                        action="delete"
                        handleClose={() => setShowConfirmModal(false)}
                        modalTitle="Delete Story Block"
                        modalBody={() => (
                            <div>
                                <p className="subtitle">The story block will be permanantly deleted.</p>
                            </div>
                        )}
                        reject={() => {
                            setShowConfirmModal(false)
                        }}
                        confirm={remove}
                    />
                    </div>                 
                : null }
            </div>
        </div>
    );
}


/**
 * Local function for displaying audio block for Editing story screen
 */
export function DisplayAudioEdit(props:any) {

    //Audio elements
    const [ waveSurfer, setWaveSurfer ] = useState<any>(false);
    const [ play, setPlay ] = useState<boolean>(false);
    const [ pause, setPause ] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    //Edit title
    const [title, setTitle] = useState<string>('');
    const [editTitle, setEditTitle] = useState<boolean>(false);   
    
    //Edit desc
    const [desc, setDesc] = useState<string>('');
    const [editDesc, setEditDesc] = useState<boolean>(false);    

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(props.block.media.description && props.block.media.description !== "") {
            setDesc(props.block.media.description)
        }
        // TODO: MEDIUM: remove later: temporary data migration
        else if(props.block.media.title) {
            setDesc(props.block.media.title)
        }
    }, [])    

    useEffect(() => {
        // if(wrapperRef.current && !waveSurfer) {
            if(wrapperRef.current) {
            const audio = new Audio((props.block.media.data as string));

            const wavesurfer = WaveSurfer.create({
                container: '#waveform_' + props.index,
                backend: 'MediaElement',
                waveColor: 'grey',
                progressColor: 'grey',
                barWidth: 3,
                barGap:2,
                height:100
            });

            wavesurfer.load(audio)
            setWaveSurfer(wavesurfer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.block])

    useEffect(() => {
        if(waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time:any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1));
                if ((current + 0.2) >= max) {
                    setPlay(false);
                    setPause(false);
                }

                if(waveSurfer.isPlaying()) {
                    const totalTime = waveSurfer.getDuration();
                    const  currentTime = waveSurfer.getCurrentTime();
                    const remainingTime = totalTime - currentTime;

                    let hours = Math.floor(remainingTime / 3600);
                    let mins = Math.floor(remainingTime / 60) - (hours * 60);
                    let secs = Math.floor(remainingTime % 60);

                    const element = document.getElementById(`time-remaining ${props.index}`);
                    if(element !== null) {
                        element.innerText =  mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
                    }
                }
            });
            waveSurfer.on('ready', function () {
                setLoaded(true)              
            });            
        }
        return () => {
            if (waveSurfer) {            
                pauseAudio();
            }
        };          
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [waveSurfer]);

    function playAudio() {
        waveSurfer.play();
        setPlay(props.index);
        setPause(false);
    }

    function pauseAudio() {
        waveSurfer.pause();
        setPause(true);
    }

    async function handleSubmit(evt:any) {
        evt.preventDefault();

        const data = {
            description: desc
        }

        if(!props.block.media) {
            return;
        }

        try {
            const res = await instance.put('/media/' + props.block.media._id, data);
            props.updateBlockMedia(props.block._id, res.data)
        }
        catch(error) {
            console.log('Error', error)
        }

        setEditDesc(false);
    }

    function handleClose() {
        setDesc('');
        setEditDesc(false);
    }    
/*
    async function handleSubmit(evt:any) {
        evt.preventDefault();

        const data = {
            title: title
        }

        if(!props.block.media) {
            return;
        }

        try {
            const res = await instance.put('/media/' + props.block.media._id, data);
            props.updateBlockMedia(props.block._id, res.data)
        }
        catch(error) {
            console.log('Error', error)
        }

        setEditTitle(false);
    }


    function handleClose() {
        setTitle('');
        setEditTitle(false);
    }    
    */    

    return(
        <div className="display-audio">
            <div ref={wrapperRef} style={{display:'none'}} className="wave" id={'waveform_' + props.index}></div>
            {!loaded ? <Spinner animation="border" variant="#fff" /> :
            <div>
                {props.block.media.description ? <p className="optional-desc" onClick={() => setEditDesc(true)}>{props.block.media.description}</p> : !editDesc ? <p className="optional-desc" onClick={() => setEditDesc(true)}>Optional description</p> : null}
                    {editDesc &&
                        <div className='edit'>
                            <Form className="group" onSubmit={handleSubmit}>
                                <Form.Label className="top-label">Description</Form.Label>
                                <input autoFocus value={desc} onChange={(evt) => setDesc(evt.target.value)} type="text" name="" id="" />
                                <div className="submit-icon-container" onClick={handleSubmit}>
                                    <Icon size={1} path={mdiCheck} />
                                </div>
                                <div className="submit-icon-container" onClick={handleClose}>
                                    <Icon size={1} path={mdiClose} />
                                </div>
                            </Form>
                        </div>
                }
                {(play === props.index && !pause) ?
                        <button className="record-button pause" onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={ mdiPause }/></button>
                    : (play === props.index && pause) ?
                    <button className="record-button play" onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={ mdiPlay }/></button>
                    :
                        <button className="record-button play" onClick={() => (play === props.index && !pause) ? pauseAudio() : playAudio()}><Icon size={1} path={ mdiVolumeHigh }/></button>
                    }

                {/*props.block.mediaTitle ? <p className="optional-desc" onClick={() => setEditTitle(true)}>{props.block.mediaTitle}</p> : !editTitle ? <p className="optional-desc" onClick={() => setEditTitle(true)}>Optional title</p> : null}
                        {editTitle &&
                            <div className='edit'>
                                <Form className="group" onSubmit={handleSubmit}>
                                    <Form.Label className="top-label">Title</Form.Label>
                                    <input autoFocus value={title} onChange={(evt) => setTitle(evt.target.value)} type="text" name="" id="" />
                                    <div className="submit-icon-container" onClick={handleSubmit}>
                                        <Icon size={1} path={mdiCheck} />
                                    </div>
                                    <div className="submit-icon-container" onClick={handleClose}>
                                        <Icon size={1} path={mdiClose} />
                                    </div>
                                </Form>
                            </div>
                        */}
{/*                <!--p className="audio-title">{props.block.mediaTitle}</p--> */}
                {(play === props.index) ? <p className="audio-user"><span id={`time-remaining ${props.index}`}>00:00</span> remaining</p> : <p className="audio-user">{props.block.media.user.firstname} {props.block.media.user.lastname}</p>}
            </div>
            }            
        </div>
    )
}

/**
 * Only one without media !!
 * @param props
 * @returns
 * 
 * We use parse() here since we want to turn HTML string into JSX deliberately.
 * Do not use parse() without sanitizing / purifying as it is NOT XSS safe!
 * (The rich text editor - react-draft-wysiwyg - is XSS safe that is why in conjunction with parse() we are safe.)
 * 
 */
export function DisplayTextEdit(props:IDisplayComponent) {

    return(
        <div className="display-text">
            {parse(props.block.data)}
        </div>
    )
}

export function DisplayImageEdit(props:IDisplayComponent) {

    const [showAudio, setShowAudio] = useState<boolean>(false);
    const [showPeople, setShowPeople] = useState<boolean>(false);

    //Edit desc
    const [desc, setDesc] = useState<string>('');
    const [editDesc, setEditDesc] = useState<boolean>(false);

    useEffect(() => {
        if(props.block.media.description) {
            setDesc(props.block.media.description)
        }
    }, [])

    function showMainCarousel() {
        if(props.showImageCarousel) {
            props.showImageCarousel(props.index)
        }
    }

    async function handleSubmit(evt:any) {
        evt.preventDefault();

        const data = {
            description: desc
        }

        if(!props.block.media) {
            return;
        }

        try {
            const res = await instance.put('/media/' + props.block.media._id, data);
            props.updateBlockMedia(props.block._id, res.data)
        }
        catch(error) {
            console.log('Error', error)
        }

        setEditDesc(false);
    }

    function handleClose() {
        setDesc('');
        setEditDesc(false);
    }

    return(
        <div className="display-media">   
            {props.block.media.description ? <p className="optional-desc" onClick={() => setEditDesc(true)}>{props.block.media.description}</p> : !editDesc ? <p className="optional-desc" onClick={() => setEditDesc(true)}>Optional description</p> : null}
            {editDesc &&
                <div className='edit'>
                    <Form className="group" onSubmit={handleSubmit}>
                        <Form.Label className="top-label">Description</Form.Label>
                        <input autoFocus value={desc} onChange={(evt) => setDesc(evt.target.value)} type="text" name="" id="" />
                        <div className="submit-icon-container" onClick={handleSubmit}>
                            <Icon size={1} path={mdiCheck} />
                        </div>
                        <div className="submit-icon-container" onClick={handleClose}>
                            <Icon size={1} path={mdiClose} />
                        </div>
                    </Form>
                </div>
            }
            <div className="single-image-story-block">
                <img onClick={() => props.showImageCarousel ? showMainCarousel() : null} className="story-block-image" alt="Block" src={props.block.media.data} />
            </div>
            <div className="interactions">
                <div className="interaction-container comment">
                   <Icon className="big-icon disabled-icon" size={1} path={mdiCommentOutline} />
                </div>
                <div className="interaction-container people" onClick={() => setShowPeople(true)}>
                    {!(props.block.media.tags.users.length || props.block.media.tags.others) ?
                    <div className="small">
                        <Icon className="small-icon" path={mdiPlus}/>
                        <p className="add-text">Add</p>
                    </div>
                    :
                    <div className="added">
                        <p>{numOfTaggedString(props.block.media.tags.users, props.block.media.tags.others)}</p>
                    </div>}
                    <Icon className="big-icon" size={1} path={mdiAccount}/>
                </div>
                <div className="interaction-container audio" onClick={() => setShowAudio(true)}>
                    {!props.block.media.relatedMedia &&
                    <div className="small">
                        <Icon className="small-icon" path={mdiPlus}/>
                    </div>}
                    <Icon className="big-icon" size={1} path={props.block.media.relatedMedia ? mdiVolumeHigh : mdiMicrophone}/>
                    {props.block.media.relatedMedia ? <p className="record-user">{(props.block.media.relatedMedia as IMedia).user.firstname} {(props.block.media.relatedMedia as IMedia).user.lastname}</p> : <p className="record-user-add">Add</p>}
                </div>
            </div>
            <AddNarrationModal updateBlockMedia={props.updateBlockMedia} block={props.block} show={showAudio} setShow={setShowAudio} index={props.index}/>
            <AddPeopleModal isAuthor={props.isAuthor ? props.isAuthor : false} updateBlockMedia={props.updateBlockMedia} block={props.block} show={showPeople} setShow={setShowPeople} index={props.index}/>
        </div>
    )
}

export function DisplayVideoEdit(props:IDisplayComponent) {

    const [showPeople, setShowPeople] = useState<boolean>(false);

    //Edit desc
    const [desc, setDesc] = useState<string>('');
    const [editDesc, setEditDesc] = useState<boolean>(false);

    useEffect(() => {
        if(props.block.media.description) {
            setDesc(props.block.media.description)
        }
    }, [])

    async function handleSubmit(evt:any) {
        evt.preventDefault();

        const data = {
            description: desc
        }
        if(!props.block.media) {
            return;
        }

        try {
            const res = await instance.put('/media/' + props.block.media._id, data);
            props.updateBlockMedia(props.block._id, res.data)
        }
        catch(error) {
            console.log('Error', error)
        }

        setEditDesc(false);
    }

    function handleClose() {
        setDesc('');
        setEditDesc(false);
    }

    return(
        <div className="display-media">
            {props.block.media.description ? <p className="optional-desc" onClick={() => setEditDesc(true)}>{props.block.media.description}</p> : !editDesc ? <p className="optional-desc" onClick={() => setEditDesc(true)}>Optional description</p> : null}
            {editDesc &&
                <div className='edit'>
                    <Form className="group" onSubmit={handleSubmit}>
                        <Form.Label className="top-label">Description</Form.Label>
                        <input autoFocus value={desc} onChange={(evt) => setDesc(evt.target.value)} type="text" name="" id="" />
                        <div className="submit-icon-container" onClick={handleSubmit}>
                            <Icon size={1} path={mdiCheck} />
                        </div>
                        <div className="submit-icon-container" onClick={handleClose}>
                            <Icon size={1} path={mdiClose} />
                        </div>
                    </Form>
                </div>
            }
            <VideoPlayer url={props.block.media.data}/>
            <div className="interactions">
                <div className="interaction-container people" onClick={() => setShowPeople(true)}>
                    {!(props.block.media.tags.users.length || props.block.media.tags.others) ?
                    <div className="small">
                        <Icon className="small-icon" size={0.5} path={mdiPlus}/>
                        <p className="add-text">Add</p>
                    </div>
                    :
                    <div className="added">
                        <p>{numOfTaggedString(props.block.media.tags.users, props.block.media.tags.others)}</p>
                    </div>}
                    <Icon className="big-icon" size={1} path={mdiAccount}/>
                </div>
            </div>
            <AddPeopleModal isAuthor={props.isAuthor ? props.isAuthor : false} updateBlockMedia={props.updateBlockMedia} block={props.block} show={showPeople} setShow={setShowPeople} index={props.index}/>
        </div>
    )
}

export function DisplayCarouselEdit(props:IDisplayComponent) {

    const [activeIndex, setIndex] = useState<number>(0);

    //Edit desc
    const [desc, setDesc] = useState<string>('');
    const [editDesc, setEditDesc] = useState<boolean>(false);

    const handleSelect = (selectedIndex:number, e:any) => {
        setIndex(selectedIndex);
    };

    const [showAudio, setShowAudio] = useState<boolean>(false);
    const [showPeople, setShowPeople] = useState<boolean>(false);

    async function handleSubmit(evt:any) {
        evt.preventDefault();

        const data = {
            description: desc
        }
        if(!props.block.multipleMedia[activeIndex]) {
            return;
        }

        try {
            const res = await instance.put('/media/' + props.block.multipleMedia[activeIndex]._id, data);
            props.updateBlockMedia(props.block._id, res.data)
        }
        catch(error) {
            console.log('Error', error)
        }

        setDesc('');
        setEditDesc(false);
    }

    function handleClose() {
        setDesc('');
        setEditDesc(false);
    }

    return(
        <div className="display-carousel">
            {props.block.multipleMedia[activeIndex]?.description ? <p className="optional-desc" onClick={() => {setEditDesc(true); setDesc(props.block.multipleMedia[activeIndex].description as string)}}>{props.block.multipleMedia[activeIndex].description}</p> : !editDesc ? <p className="optional-desc" onClick={() => setEditDesc(true)}>Optional description</p> : null}
            {editDesc &&
                <div className='edit'>
                    <Form className="group" onSubmit={handleSubmit}>
                        <Form.Label className="top-label">Description</Form.Label>
                        <input autoFocus value={desc} onChange={(evt) => setDesc(evt.target.value)} type="text" name="" id="" />
                        <div className="submit-icon-container" onClick={handleSubmit}>
                            <Icon size={1} path={mdiCheck} />
                        </div>
                        <div className="submit-icon-container" onClick={handleClose}>
                            <Icon size={1} path={mdiClose} />
                        </div>
                    </Form>
                </div>
            }
            <Carousel indicatorLabels={getDotClassName(props.block.multipleMedia, activeIndex)} activeIndex={activeIndex} onSelect={handleSelect} interval={null} indicators={true}>
                {props.block.multipleMedia.map((media:IMedia, index:number) => (
                    <Carousel.Item key={index} >
                        {(media.fileType !== fileTypes.VIDEO ) ?
                            <img src={media.data} alt="uploaded" className="story-block-media" />
                            :
                            <VideoPlayer url={media.data}/>

                        }
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="interactions">
                <div className="interaction-container comment">
                   <Icon className="big-icon disabled-icon" size={1} path={mdiCommentOutline} />
                </div>
                <div className="interaction-container people" onClick={() => setShowPeople(true)}>
                    {!(props.block.multipleMedia[activeIndex]?.tags.users.length || props.block.multipleMedia[activeIndex]?.tags.others) ?
                    <div className="small">
                        <Icon className="small-icon" size={0.5} path={mdiPlus}/>
                        <p className="add-text">Add</p>
                    </div>
                    :
                    <div className="added">
                        <p>{numOfTaggedString(props.block.multipleMedia[activeIndex].tags.users, props.block.multipleMedia[activeIndex].tags.others)}</p>
                    </div>}
                    <Icon className="big-icon" size={1} path={mdiAccount}/>
                </div>
                {props.block.multipleMedia[activeIndex].fileType !== fileTypes.VIDEO &&
                    <div className="interaction-container audio" onClick={() => setShowAudio(true)}>
                        <div className="small">
                            <Icon className="small-icon" size={0.5} path={mdiPlus}/>
                        </div>
                        <Icon className="big-icon" size={1} path={mdiMicrophone}/>
                        {props.block.multipleMedia[activeIndex].relatedMedia ? <p className="record-user">{(props.block.multipleMedia[activeIndex].relatedMedia as IMedia).user.firstname} {(props.block.multipleMedia[activeIndex].relatedMedia as IMedia).user.lastname}</p> : <p className="record-user-add">Add</p>}
                    </div>
                }
            </div>
            <p className="current-page">Photo {activeIndex + 1} of {props.block.multipleMedia.length}</p>
            <AddPeopleModal isAuthor={props.isAuthor ? props.isAuthor : false} updateBlockMedia={props.updateBlockMedia} block={props.block} currentIndex={activeIndex} show={showPeople} setShow={setShowPeople} index={props.index}/>
            <AddNarrationModal updateBlockMedia={props.updateBlockMedia} block={props.block} currentIndex={activeIndex} show={showAudio} setShow={setShowAudio} index={props.index}/>
        </div>
    )
}

/**
 * Display Life Lesson
 * @param props
 * @returns
 */
export function DisplayLifelessonEdit(props:IDisplayComponent) {

    return(
        <div className="display-block-lifelesson">
            <div className="icon">
                <IconLifelesson />
            </div>
            <div className="container">
                <p>{props.block.data}</p>
                {props.block.tags && props.block.tags.length ?
                    <p>Life Lesson(s): {props.block.tags.map(tag => (config.lifelessonTags.find(data => data._id === tag) as IOption).text).join(", ")}</p>
                : null }
            </div>
        </div>
    )
}

/**
 * Display Attachment
 * @param props
 * @returns
 */
 export function DisplayAttachmentEdit(props:IDisplayComponent) {

    return(
        <div className="display-block-attachment">
            {props.block.media ?
                <div className="container">
                    {props.block.media.description && props.block.media.description.length ?
                        <p className="title">{props.block.media.description}</p>
                    : null}                
                    <div className="icon">       
                        <a href={props.block.media.data}>                         
                            <Icon size={1} path={ mdiCloudDownload }/>
                        </a>                             
                    </div>
                    <div className="attachment">               
                        <p>
                            <a className="underlined-link-button" href={props.block.media.data}>
                                {props.block.media.name}
                            </a>
                        </p>                        
                        {props.block.media.fileSize ? 
                            <p className="size">
                                {formatBytes(props.block.media.fileSize)}
                            </p>
                        : null 
                        }                 
                    </div>
                </div>
            : null }              
        </div>
    )
}

/**
 * Display External Album Link
 * @param props
 * @returns
 */
export function DisplayLinkalbumEdit(props:IDisplayComponent) {

    return(
        <div className="display-block-linkalbum">
            <div className="container">
                <div className="album">
                    {props.block.description && props.block.description.length ?
                        <p className="title">{props.block.description}</p>
                    : null}
                    <p className='view'><a className="link-button" href={props.block.data} target="_blank" rel="noopener noreferrer">View Photo Album</a></p>
                    {(props.block.tags as string[]).length ?
                    <p>
                        <a className="underlined-link-button" href={props.block.data} target="_blank" rel="noopener noreferrer">
                            
                            <img className="provider-icon" alt="provider-icon" src={(props.block.tags as string[])[0] === "FLICKR" ? iconFlickr : iconGooglephotos} />
                            on {config.linkAlbumProviders.find((data:any) => data._id === (props.block.tags as string[])[0]).text}
                        </a>
                    </p>                        
                    : null }
                </div>
            </div>
        </div>
    )
}

/**
 * Display Embed
 * @param props
 * @returns
 */
 export function DisplayEmbedEdit(props:IDisplayComponent) {

    return(
        <div className="display-block-embed">
            <div className="container">
                <div className="album">
                    {props.block.description && props.block.description.length ?
                        <p className="title">{props.block.description}</p>
                    : null}
                    {/*<p className='view'>View Photo Album</p>*/}
                    {(props.block.tags as string[]).length ?
                    <div>
                        {parse(DOMPurify.sanitize((props.block.data as string), {ALLOWED_TAGS: ['iframe']}))}
                        <p>From {config.embedProviders.find((data:any) => data._id === (props.block.tags as string[])[0]).text}</p>
                    </div>                        
                    : null }
                </div>
            </div>
        </div>
    )
}

/**
 * Add People Modal and data
 *
 */
interface IaddPeopleModal {
    block:IBlock
    show:boolean
    setShow:Function
    index:any
    currentIndex?:number
    updateBlockMedia:Function
    isAuthor: boolean
}

function AddPeopleModal(props:IaddPeopleModal) {

    const [ members, setMembers ] = useState<IOption[]>([]);
    const [ others, setOthers ] = useState<string>('');
    const [ options, setOptions ] = useState<IOption[]>([]);

    //Notifications
    const [errorNotification, setErrorNotification ] = useState<boolean>(false);
    const [successNotification, setSuccessNotification ] = useState<boolean>(false);

    const successNotificationData: INotification = { icon:notificationImages.happiness, title:"Success", text:"People have been added.", success:NotificationType.success }
    const errorNotificationData: INotification = { icon:notificationImages.crying, title:"Error", text:"Failed to add people.", success:NotificationType.problem }

    const loggedInUserData = useContext(Context).user;
    const media = (props.currentIndex !== undefined && props.block.multipleMedia) ? props.block.multipleMedia[props.currentIndex] : props.block.media

    useEffect(() => {

        if(media) {
            if(media.tags.others) {
                setOthers(media.tags.others);
            }
            else {
                setOthers('');
            }
            if(media.tags.users) {
                const options:IOption[] = [...media.tags.users.map((user:any) => { return { _id:user._id, text: `${user.firstname} ${user.lastname}`, img: user.avatar ? user.avatar : ""} }) ];
                setMembers(options);
            }
            else {
                setMembers([])
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.currentIndex])

    //Convert relations to options for unique select.
    useEffect(() => {
        const options:IOption[] = [...loggedInUserData.relations.map(relation => { return { _id:relation.userid._id, text: `${relation.userid.firstname} ${relation.userid.lastname}`, img: relation.userid.avatar ? relation.userid.avatar : ""} }), { _id:loggedInUserData._id, text: `${loggedInUserData.firstname} ${loggedInUserData.lastname}`, img:loggedInUserData.avatar ? loggedInUserData.avatar : ""}];
        setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserData, props.show])


    function updateIndividuals(selectedData:IOption) {
        if(members.filter((data:IOption) => data._id === selectedData._id).length) {
            const temp = members.filter((data:IOption) => data._id !== selectedData._id);
            setMembers(temp);
        }
        else {
            setMembers((prev:IOption[]) => { return [...prev, selectedData]})
        }
    }

    async function tagMembers() {
        const data = {
            tags: {
                users:members.map(x => x._id),
                others:others
            }
        }

        if(!media) {
            return;
        }

        try {
            const res = await instance.put('/media/' + media._id, data);
            props.updateBlockMedia(props.block._id, res.data)
            setSuccessNotification(true);
            setTimeout(() => {
                props.setShow(false);
                setSuccessNotification(false);
            }, 1000)
        }
        catch(error) {
            setErrorNotification(true);
            console.log('Error', error)
        }
    }


    return(
        <Modal dialogClassName="members-modal" backdrop="static" show={props.show} onHide={() => { props.setShow(false); setErrorNotification(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>Add People</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(media.fileType !== fileTypes.VIDEO ) ?
                    <img className="story-block-image" alt="Block" src={media.data} />
                :
                    <div className="video-cont">
                        <VideoPlayer url={media.data}/>
                    </div>
                }

                <div className="inputs">
                    {props.isAuthor ?<UniqueListInput iconRef={''} placeholder="Add family members" handleAction={updateIndividuals} data={members} options={options} icon={false}/> : <p className="co-author-info">Family members set by story creator</p>}
                    <Form.Group className="group">
                        <input type="text" className=""  placeholder="Add others" onChange={(evt) => setOthers(evt.target.value)} value={others}/>
                        <Form.Label className="top-label">Add Others</Form.Label>
                        <p className="sub-subtitle right">Name people not in your family list</p>
                    </Form.Group>
                </div>
                <div className="buttons">
                    {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)} /> : null}
                    {successNotification ? <Notification data={successNotificationData} close={() => setSuccessNotification(false)} /> : null}
                    <Button className="default-button proceed" onClick={() => tagMembers()} disabled={(!others.length && !members.length)}>Add to Story</Button>
                    <Button className="light-button cancel" onClick={() => props.setShow(false)}>Cancel</Button>
                </div>
                <Help category={"PUBLISHING"} pageName={"Adding People to Media Files"}></Help>             
            </Modal.Body>
        </Modal>
    );
}


/**
 * Add narration modal
 */
 interface IaddNarrationModal {
    block:IBlock
    show:boolean
    setShow:Function
    index:any
    currentIndex?:number
    updateBlockMedia: Function
}

function AddNarrationModal(props:IaddNarrationModal) {

    const [ hasMedia, setHasMedia ] = useState<boolean>(false);
    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ temp, setTemp ] = useState<string>("");

    const [ isPublishedBlock, setIsPublishedBlock ] = useState<boolean>(false);    

    //Audio elements
    const [ waveSurfer, setWaveSurfer ] = useState<any>(false);
    const [ play, setPlay ] = useState<boolean>(false);

    //Notifications
    const [errorNotification, setErrorNotification ] = useState<boolean>(false);
    const [successNotification, setSuccessNotification ] = useState<boolean>(false);
    const [infoNotification, setInfoNotification ] = useState<boolean>(false);

    const successNotificationData: INotification = { icon:notificationImages.happiness, title:"Success", text:"Narration have been added", success:NotificationType.success }
    const infoNotificationData: INotification = { icon:notificationImages.neutral, title:"Info", text:"You can update the narration by unpublishing the story first", success:NotificationType.info }    
    const errorNotificationData: INotification = { icon:notificationImages.crying, title:"Error", text:"Failed to add narration", success:NotificationType.problem }

    const wrapperRef = useRef<HTMLDivElement>(null);

    const media = (props.currentIndex !== undefined && props.block.multipleMedia) ? props.block.multipleMedia[props.currentIndex] : props.block.media

    useEffect(() => {

        if(wrapperRef.current && hasMedia) {
            if(!(media.relatedMedia as IMedia).data) {
                return;
            }
            const audio = new Audio(((media.relatedMedia as IMedia).data as string));

            const wavesurferLoc = WaveSurfer.create({
                container: '#waveform_' + media._id,
                waveColor: 'grey',
                progressColor: 'green',
                barWidth: 3,
                barGap:2,
                height:100,
                backend: 'MediaElement'
                // fillParent: false,
                // minPxPerSec: 130, 
            });

            wavesurferLoc.load(audio);
            setWaveSurfer(wavesurferLoc);
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMedia, props.show])

    useEffect(() => {
        if (waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time: any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1))
                if ((current + 0.2) >= max) {
                    setPlay(false);
                }
            });
            /*    
            waveSurfer.on('ready', function () {
                setLoaded(true)
            });
            */
        }
        return () => {
            if (waveSurfer) {            
                waveSurfer.pause();
                setPlay(true);                
            }
        };          
    }, [waveSurfer])    

    useEffect(() => {
        if(media.relatedMedia) {
            getIsPublishedBlock();
            setHasMedia(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [media])

    async function getIsPublishedBlock() {
        try {
            const exists = await instance.get("/stories/block/" + props.block._id);
            setIsPublishedBlock(exists.data);
        } catch (error) {
            console.log("Error while getting published block info")
        }        
    }

    async function addAudio(audioFile:File) {

        const fileType = audioFile.type && audioFile.type.match(new RegExp(/audio\/mp4.*/, "i")) ? "m4a" : "mp3";
        const fileName = media.relatedMedia ? (media.relatedMedia as IMedia).name : "audio."+fileType;
        const audio = new File([audioFile], fileName, {type:"audio/"+fileType, lastModified:new Date().getTime()});        

        //Init formdata and cookies
        const formdata = new FormData();

        formdata.append("file", audio);

        try {
            if(media.relatedMedia) {
                // overwrite existing narration in unpublished block
                if(props.block && props.block._id) {
                    formdata.append("blockId", props.block._id.toString());
                }
                await instance.put('/upload', formdata);
            } else {
                // new narration
                formdata.append("related", 'true');
                                
                const response = await instanceUpload.post('/upload', formdata);

                const data = {
                    relatedMedia: response.data._id
                }                
                const res = await instance.put('/media/' + media._id, data);
                props.updateBlockMedia(props.block._id, res.data)   

            }                

            setSuccessNotification(true);
            setTimeout(() => {
                props.setShow(false);
                setSuccessNotification(false);
                setHasMedia(true);
                setPlay(true);
            }, 1000)
        }
        catch(error) {
            setErrorNotification(true);
            console.log('Error while uploading narration: ', error)
        }
    }

    function reRecord() {
        setWaveSurfer(false);
        setPlay(false);
        setHasMedia(false)
    }

    return(
        <Modal dialogClassName="narration-modal" backdrop={hasMedia ? "default" : "static"} show={props.show} onHide={() => { props.setShow(false); setErrorNotification(false);}}>
            <Modal.Header closeButton>
                <Modal.Title>Add Narration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img className="story-block-image" alt="Block" src={media.data} />
                <div className="record-audio">
                    {!hasMedia ? <AudioRecorder editData={false} buttonName={'Re-record narration'} cancel={() => props.setShow(false)} seconds={60} addToStory={addAudio} hasTitle={false} title={''} /> :
                    <div className="display-audio">
                        <p className="audio-title">{props.block.media && props.block.media.description}</p>
                        {loaded && <div>
                            {play === props.index ?
                                <button className="record-button pause" onClick={() => { waveSurfer.pause(); setPlay(false)}}><Icon size={1.3} path={ mdiPause }/></button>
                            :
                                <button className="record-button play" onClick={() => { waveSurfer.play(); setPlay(props.index); }}><Icon size={1.3} path={ mdiPlay }/></button>
                            }
                        </div>}
                        <div>
                            <div ref={wrapperRef} className="wave" id={'waveform_' + media._id}></div>
                        </div>
                        <button className="default-button" onClick={() => !isPublishedBlock ? reRecord() : setInfoNotification(true)}>Re-record</button>
                        <p>{temp}</p>
                    </div>}
                    {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)} /> : null}
                    {successNotification ? <Notification data={successNotificationData} close={() => setSuccessNotification(false)} /> : null}
                    {infoNotification ? <Notification data={infoNotificationData} close={() => setInfoNotification(false)} /> : null}                    
                    <Help category={"PUBLISHING"} pageName={"Adding Photo Narration"}></Help>
                </div>
            </Modal.Body>
        </Modal>
    );
}
