import React, { useContext, useEffect, useRef, useState } from 'react';
import { mdiAccountGroup, mdiChevronLeft, mdiChevronRight, mdiCommentOutline, mdiStarOutline, mdiStar } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IUserDetails } from '../../interfaces/authentication';
import { dateFormat } from '../../_helper/enum/enum';
import { numOfTaggedString, PlayNarration } from './blocks/display-story.component';
import { blockTypes, IMedia } from '../../interfaces/story';
import { Context } from '../../pages/home.page';
import instance from '../../api/api';
import VideoPlayer from "../_helper/video-player.component"
import CommentsContainer, { ECommentType } from '../comments/comments-container.component';
import commentAPIInstance from '../../api/comment_api';

interface IProps {
    show:boolean
    setShow:Function
    visual:IMedia | undefined
    previousVisual: Function
    nextVisual: Function
}
export default function VisualDetailsModal(props:IProps) {

    const history = useHistory();

    const modalRef = useRef<any>();
    const taggedRef = useRef<any>();
    const commentsRef = useRef<any>();

    const [showAudio, setShowAudio] = useState<boolean>(false);

    const [ commentsLength, setCommentsLength] = useState<number>(0);

    const loggedInUserData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    useEffect(() => {
        if(props.visual) {
            fetchCommentData();
        }
    }, [props.visual])

    function displayTagged() {
        if(props.visual) {
            return props.visual.tags.users.map((x:any) => {
                return(<span><span onClick={() => history.push('/dashboard/profile/' + x._id + "/1")} className="tagged-user">{x.firstname} {x.lastname}</span>, </span>)
            })
        }
    }

    async function  addToFavorites() {
        if(props.visual) {
            if(!loggedInUserData.favorites.media.includes(props.visual?._id)) {
                const response = await instance.put(`/user/${loggedInUserData._id}`, { push: { "favorites.media": props.visual?._id }});
                updateLoggedInUserData(response.data)
            }
            else {
                const response = await instance.put(`/user/${loggedInUserData._id}`, { pull: { "favorites.media": props.visual?._id }});
                updateLoggedInUserData(response.data)
            }
        }
    }
    
    async function fetchCommentData() {
        if(!props.visual) {
            setCommentsLength(0);
            return;
        }

        try {
            const res = await commentAPIInstance.get("/", { 
                params: { 
                    pageNo:0,
                    query :{
                        related_entity:props.visual._id,
                        comment_type:ECommentType.MEDIA
                    }
                },
                headers: {"Authorization" : `Bearer ${props.visual.accessCommentToken}`}
            })
            setCommentsLength(res.data.totalRecords)
        }
        catch(error) {
            console.log('error')
        }
    }

    return(
        <Modal id="fixed-modal" dialogClassName={"visual-details-modal fixed-modal"} show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header ref={modalRef} closeButton>
                <div className="navigation-icons">
                    <div onClick={() => props.previousVisual()}>
                        <Icon size={1.4} path={mdiChevronLeft}/>
                    </div>
                    <div onClick={() => props.nextVisual()}>
                        <Icon size={1.4} path={mdiChevronRight}/>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="edit-card-content">
                {props.visual ? 
                    <div>
                        <div className="description-container">
                            <p className="description">{props.visual.description ? props.visual.description : ""}</p>
                        </div>
                        <div className="image-container">
                            <div className="swiper">
                                { props.visual.fileType === "image" ?
                                    <img alt="visual-detail" src={props.visual.dataM ? props.visual.dataM : props.visual.data} />
                                    : 
                                    <VideoPlayer url={props.visual.data}/>

                                }
                            </div>
                            <div className="interactions">
                                {props.visual.relatedMedia ? <PlayNarration block={{type:blockTypes.AUDIO, media:props.visual, data:'', createdBy:'', multipleMedia:[]}} show={showAudio} setShow={setShowAudio} index={1}/>  : null}

                                <div className={`fav-container ${loggedInUserData.favorites.media.includes(props.visual._id) ? "interaction-container favourite active" : "interaction-container favourite"}`} onClick={addToFavorites} >
                                    <Icon className="big-icon" size={1} path={loggedInUserData.favorites.media.includes(props.visual._id) ? mdiStar : mdiStarOutline}/>
                                    <div className='added'>
                                        <p>{loggedInUserData.favorites.media.includes(props.visual._id) ? "Favourite" : "Add to Favourites"}</p>
                                    </div>
                                </div>

                               {props.visual.relatedStory.commentsEnabled ? <div className="interaction-container comment" onClick={() => commentsRef.current?.scrollIntoView({behavior: "smooth"})}>
                                    <Icon className="big-icon" size={1} path={mdiCommentOutline}/>
                                    {commentsLength > 0 ? <div className="added">
                                        <p>{commentsLength}</p>
                                    </div> : null}
                                </div> : null}

                                {(props.visual.tags.users.length || props.visual.tags.others) ?
                                    <div className="interaction-container people"  onClick={() => taggedRef.current?.scrollIntoView({behavior: "smooth"})} >
                                        <div className="added">
                                            <p>{numOfTaggedString(props.visual.tags.users, props.visual.tags.others)}</p>
                                        </div>
                                        <Icon className="big-icon" size={1} path={mdiAccountGroup}/>
                                    </div> : null
                                }
                            </div>
                        </div>
                        {props.visual.relatedStory._id ? 
                            <div className="info-container">
                                <p className="from">from</p>
                                <p className='where'>{props.visual.relatedStory.title}</p>
                                <p className='date'>{moment(props.visual.relatedStory.date.startDate.date).format(dateFormat.SHORT)}</p>
                                <p className='name'>{(props.visual.relatedStory.featured as IUserDetails).firstname} {(props.visual.relatedStory.featured as IUserDetails).lastname}</p>
                                <button className="default-button" onClick={() => history.push(`/dashboard/story/${props.visual?.relatedStory._id}`)}>View Story<Icon size={1} path={mdiChevronRight}/></button>
                                
                            </div> : null}

                        {(props.visual.tags.users.length || props.visual.tags.others) ? <div className="featured-container" ref={taggedRef}>
                            <p className="featured">Featured people ({numOfTaggedString(props.visual.tags.users, props.visual.tags.others)})</p>
                            <div className="featured-list">
                                {props.visual.tags.users.length ?
                                    <div>
                                        <p>Family</p>
                                        <p>{displayTagged()}</p>
                                    </div> : null}
                                {props.visual.tags.others ?
                                    <div>
                                        <p>Others</p>
                                        <p>{props.visual.tags.others}</p>
                                    </div> : null}
                            </div>
                        </div> : null}
                        {props.visual.relatedStory.commentsEnabled && <div className="comments-container" ref={commentsRef}>
                            <div className="comment-section">
                                <p className="featured">Comments ({commentsLength})</p>
                                <CommentsContainer accessToken={props.visual.accessCommentToken || ""} commentsEnabled={true} title={""} related_entity={props.visual._id} access_entity={props.visual.relatedStory._id} comment_type={ECommentType.MEDIA} />
                            </div>
                        </div>}
                        <button onClick={() => modalRef.current ? modalRef.current.scrollIntoView() : null} className='light-button'>Back to top</button>
                    </div>
                    : 
                    <p>loading</p> }
            </Modal.Body>
        </Modal>
    );
}