import React, { useState, useCallback, FormEvent } from "react"
import { Button, Modal } from "react-bootstrap"
import Cropper from "react-easy-crop"
import { Point, Area } from "react-easy-crop/types"
import instance from "../../api/api"
import instanceUpload from "../../api/upload"
import { INotification, NotificationType } from "../../interfaces/notification"
import getCropped from "../../_helper/_crop-image"
import Notification, { notificationImages } from "../notifications/notification.component"
import * as Sentry from "@sentry/react";

/**
 * Local interface for input properties.
 */
interface IModalProps {
    show: boolean
    handleClose: () => void
    setProfilePicture: (picture: string) => void
    userid?: string
}

let errorNotificationData : INotification = {
    icon:notificationImages.crying,
    title:"Failed",
    text:"",
    success: NotificationType.problem
}

function PhotoUploader(props: IModalProps) {
    const [uploadedImage, setUploadedImage] = useState<string>("");
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [croppedImage, setCroppedImage] = useState<string>("");
    const [croppedImageFile, setCroppedImageFile] = useState<File>();

    const [ uploadError, setUploadError ] = useState<boolean>(false);

    const [clicked, setClicked] = useState<boolean>(false);

    const emptyCroppedImage:any = "";    

    const onCropComplete = useCallback(
        async (croppedArea: Area, croppedAreaPixels: Area) => {
            const newCroppedImage:any = await getCropped(uploadedImage, croppedAreaPixels)
            //console.log('onCropComplete, new cropped', newCroppedImage);

            if(newCroppedImage !== undefined) {
                setCroppedImage(newCroppedImage.blob)
                setCroppedImageFile(newCroppedImage.file)
            }
        },
        [uploadedImage]
    )

    async function saveCroppedImage() {
        setClicked(true);
        //props.setProfilePicture(croppedImage)
        setCroppedImage("")
        setUploadedImage("")
        setZoom(1)
        setCrop({ x: 0, y: 0 });

        console.log("t1:", (croppedImageFile?.type as string))

        try {

            if (croppedImageFile?.type){
                // nop
            } else{
                /*
                errorNotificationData.text = "Incorrect image type. Please select JPG or PNG.";
                setUploadError(true)
                return
                */
            }            

            if(croppedImageFile) {
                let blobFile = new File([croppedImageFile], (props.userid ? props.userid : localStorage.userid) + "_avatar.png", {type: "image/jpeg"});

                if (blobFile) {
                    setUploadedImage(URL.createObjectURL(blobFile));
        
                    const formdata = new FormData();

                    formdata.append("file", blobFile, (props.userid ? props.userid : localStorage.userid) + "_avatar.png");
                    formdata.append("avatar", "true");
                    formdata.append("avatarOwner", props.userid ? props.userid : localStorage.userid);

                    const response = await instanceUpload.post('/upload', formdata);
                    await instance.put('/user/' + (props.userid ? props.userid : localStorage.userid), {details: { avatar: response.data }} );

                    props.setProfilePicture(croppedImage)                    
                }
            }

            close();

            props.handleClose()
        }
        catch(error) {
            errorNotificationData.text = "Media server is not responding, try again a few minutes later";
            setUploadError(true)
            console.log('Error while uploading photo: ', error)
            Sentry.captureException(error, {user: {id: props.userid ? props.userid : localStorage.userid}, tags: { 'mediaErrMsg': 'Error while uploading avatar'}});
        }
    }

    async function fileUpload(event: FormEvent) {
        const target = event.target as HTMLInputElement;
        const files = target.files;

        // clear out on every upload click
        setCroppedImageFile(emptyCroppedImage)
        setUploadedImage("")
        setCroppedImage("")
        setZoom(1)
        setCrop({ x: 0, y: 0 })
        setClicked(false)
        setUploadError(false)        

        if (files?.length) {
            console.log("t2:", files[0].type)
            /*
            if (files[0].type === "image/heic") {
                console.log("heic")
            }
            */
            
            setUploadedImage(URL.createObjectURL(files[0]));

            // TODO: low: Cropper is skipped due to not being compatible with iOS mobile heic, MLP2-1083
            setCroppedImage(URL.createObjectURL(files[0]));
            setCroppedImageFile(files[0])
        }
    }

    function close() {
        setCroppedImageFile(emptyCroppedImage)        
        setUploadedImage("")
        setCroppedImage("")
        setZoom(1)
        setCrop({ x: 0, y: 0 })
        setClicked(false)
        setUploadError(false)
        props.handleClose()
    }

    return (
        <div>
            <Modal show={props.show} onHide={close} dialogClassName={"photo-uploader"}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload profile photo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="body">
                    <div className="crop-container">
                        {uploadedImage ? (
                            <div className="image">
                                {<img src={uploadedImage} alt="avatar" className="responsive" />}
                                {/*<div className="image-crop">
                                    {<Cropper image={uploadedImage} crop={crop} zoom={zoom} aspect={4 / 4} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />}
                                </div>*/}
                            </div>
                        ) : (
                            <div className="upload-container">
                                <label htmlFor="single" className="link-button btn">
                                    Upload profile photo
                                </label>
                                <input className="upload-button" accept="image/jpeg,image/png" type="file" id="single" onChange={fileUpload} hidden />
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/*uploadedImage && <p className="scroll-to-zoom">Scroll to zoom</p>*/}
                    <Button disabled={uploadedImage==="" || clicked} className="default-button btn btn-primary" onClick={saveCroppedImage}>
                        Save Changes
                    </Button>
                    {uploadError ? <Notification data={errorNotificationData} close={() => setUploadError(false)}/> : null}

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PhotoUploader
