import IRoute from "../interfaces/routes";
import NotFound from "../_helper/_404";
import Inspirations from "../pages/dashboard/inspirations.page";
import Stories from "../pages/dashboard/stories.page";
import CreateStory from "../pages/dashboard/story/create.page";
import Home from "../pages/home.page";
import FamilyUpdates from "../pages/dashboard/family-updates.page";
import Profile from "../pages/dashboard/profile.page";
import Settings from "../pages/dashboard/settings/settings.page";
import DeceasedUpdater from "../pages/dashboard/deceased-update.page";
import LaunchPad from "../pages/dashboard/story/launchpad.page";
import StoryDetails from "../pages/dashboard/story/story-details.page";
import Help from "../pages/dashboard/help.page";
import ContactUs from "../pages/dashboard/contact-us";
import ShareYourInspiration from "../pages/dashboard/share-your-inspiration";
import Terms from "../pages/dashboard/terms.page";
import LoginAndSecurity from "../pages/dashboard/settings/login-and-security.page";
import EmailAndNewsLetters from "../pages/dashboard/settings/email-and-newsletters.page";
import NotificationSettings from "../pages/dashboard/settings/notifications-settings.page";
import DeleteProfile from "../pages/dashboard/settings/delete-profile.page";
import ShareYourTips from "../pages/dashboard/share-your-tips";
import MediaQuality from "../pages/dashboard/settings/media-quality.page";
import Language from "../pages/dashboard/settings/language.page";
import Account from "../pages/dashboard/account/account.page";
import StoryRequests from "../components/story/requests/story-requests.component";
import Cookies from "../pages/dashboard/cookies.page";
import ReferAndSave from "../pages/dashboard/refer-and-save.page";
import AccountDataSettings from "../pages/dashboard/settings/account-data.page";

/**
 * Keep every route in one array, to be able to loop trough them, and have everything in one place to manage
 */
const dashboard_routes: IRoute[] = [
    {
        path:'/dashboard/stories/:page',
        name:'Stories',
        component: Stories, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/story/:id',
        name:'Stories',
        component: StoryDetails, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/inspiration',
        name:'Inspiration',
        component: Inspirations, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/launchpad/:page',
        name:'Launchpad',
        component: LaunchPad,
        exact:true,
        private:true
    },
    /*
    {
        path:'/dashboard/launchpad/:page/:subpage?',
        name:'Launchpad',
        component: LaunchPad,
        exact:true,
        private:true
    },
    {
        path:'/dashboard/launchpad/:page/:subpage?/:list?',
        name:'Launchpad',
        component: LaunchPad,
        exact:true,
        private:true
    },
    */
    {
        path:'/dashboard/requests/:subpage?/:list?',
        name:'Requests',
        component: StoryRequests,
        exact:true,
        private:true
    },       
    {
        path:'/dashboard/create/:menu?',
        name:'Create Story',
        component: CreateStory,
        exact:true,
        private:true
    },
    {
        path:'/dashboard/profile/:id/:mainMenu?/:familyPage?',
        name:'Profile',
        component: Profile, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/deceased',
        name:'User deceased',
        component: DeceasedUpdater, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/account/:mainMenu/:subMenu',
        name:'Account',
        component: Account, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/updates',
        name:'Family Updates',
        component: FamilyUpdates, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/referandsave/:mainMenu',
        name:'Refer',
        component: ReferAndSave, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/notifications',
        name:'Family Notifications',
        component: FamilyUpdates, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/help',
        name:'Help',
        component: Help, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/contactus',
        name:'Contact Us',
        component: ContactUs, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/shareyourinspiration',
        name:'Share Your Inspiration',
        component: ShareYourInspiration, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/shareyourtips',
        name:'Share Your Tips',
        component: ShareYourTips, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/terms',
        name:'Terms & Conditions',
        component: Terms, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/cookies',
        name:'Cookie Policy',
        component: Cookies, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings',
        name:'Settings',
        component: Settings, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings/security',
        name:'Email & Security',
        component: LoginAndSecurity, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings/security/account',
        name:'Account Data',
        component: AccountDataSettings, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings/media',
        name:'Media Quality',
        component: MediaQuality, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings/language',
        name:'Language',
        component: Language, 
        exact:true,
        private:true
    },  
    {
        path:'/dashboard/settings/newsletter',
        name:'Email & Newsletters',
        component: EmailAndNewsLetters, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings/notifications',
        name:'Notifications',
        component: NotificationSettings, 
        exact:true,
        private:true
    },
    {
        path:'/dashboard/settings/deleteprofile',
        name:'Delete Profile',
        component: DeleteProfile, 
        exact:true,
        private:true
    },
    {
        path:'*',
        name:'404',
        component:NotFound,
        exact:true,
        private:false
    }
];

export default dashboard_routes;