import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import instance from '../../../api/api';
import { INotification, NotificationType } from '../../../interfaces/notification';
import Notification, { notificationImages } from '../../notifications/notification.component';
import { IUserDetails } from '../../../interfaces/authentication';
import { profileTypes } from '../../../_helper/enum/enum';
import UniqueUserListInput from '../../_helper/unique-selects/unique-user-list-input.component';
import { Context } from '../../../pages/home.page';
import TooltipHelp from '../../notifications/tooltiphelp.component';
import UniqueListInput from '../../_helper/unique-list-input.component';
import { IOption } from '../../../interfaces/family';
import config from '../../../config/config';

interface IProps {
    updateGroups: Function
    ctaText: string
    close: Function
}

const successNotification: INotification = { icon:notificationImages.happiness, title:"Success", text:"Your group has been created.", success:NotificationType.success }
const errorNotification: INotification = { icon:notificationImages.crying, title:"Failed", text:"Error when updating group", success:NotificationType.problem }

export default function CreateGroup(props: IProps) {

    const [ groupName, setGroupName ] = useState<string>('');
    const [ groupDescription, setGroupDescription ] = useState<string>('');
    const [ members, setMembers ] = useState<IUserDetails[]>([]);
    const [ options, setOptions ] = useState<IUserDetails[]>([]);
    const [ successors, setSuccessors ] = useState<IUserDetails[]>([]);
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const [ showGroupGoalTooltip, setShowGroupGoalTooltip ] = useState<boolean>(false);    

    //Notification
    const [ showNotification, setShowNotification ] = useState<boolean>(false);
    const [ showErrorNotification, setShowErrorNotification ] = useState<boolean>(false);    

    const loggedInUserData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;

    useEffect(() => {
        return () => {
            //cleanup
            close();
        }
    }, [])


    //Convert relations to options for unique select.
    useEffect(() => {
        const options:IUserDetails[] = [...loggedInUserData.relations.filter(relation => relation.userid.profileType !== profileTypes.DECEASED).map(relation => { return relation.userid })];
        setMembers([loggedInUserData]);
        setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserData.relations])


    function updatePeople(selectedData:IOption) {
        if(members.filter((data:IUserDetails) => data._id === selectedData._id).length) {
            const temp = members.filter((data:IUserDetails) => data._id !== selectedData._id);
            //Have to check successors if a member is removed, and was successor have to remove
            const refactoredS = successors.filter((succ:IUserDetails) =>  succ._id !== selectedData._id)
            setSuccessors(refactoredS);
            setMembers(temp);
        }
        else {
            const selectedUser = options.find((x) => x._id === selectedData._id);
            if(!selectedUser) {
                return
            }            
            setMembers((prev:IUserDetails[]) => { return [...prev, selectedUser]})
        }
    }

    function updateGroupSuccesors(selectedData:IUserDetails) {
        if(successors.filter((data:IUserDetails) => data._id === selectedData._id).length) {
            const temp = successors.filter((data:IUserDetails) => data._id !== selectedData._id);
            setSuccessors(temp);
        }
        else {
            setSuccessors((prev:IUserDetails[]) => { return [...prev, selectedData]})
        }
    }

    async function createGroup() {

        const group = {
            name:groupName,
            description:groupDescription,
            administrator:localStorage.userid,
            members: members.map((member:IUserDetails) => member._id),
            successors:successors.map((successor:IUserDetails) => successor._id)
        }

        try {
            const groupRes = await instance.post('/group', group);
            setShowNotification(true);
            setGroupDescription("");
            setGroupName("");
            setSuccessors([]);
            setMembers([]);
            updateUserData({...loggedInUserData, groups:[...loggedInUserData.groups, groupRes.data]});

            props.updateGroups(groupRes.data)
            setTimeout(() => {
                close()
            }, config.modalCloseTimeout)
        }
        catch(error) {
            setShowErrorNotification(true);
        }
    }

    //Manage modal close
    function close() {
        setGroupName('');
        setGroupDescription('');
        setMembers([]);
        props.close();
    }

    function getMembers() {
        return members.map(user => { 
            if(user._id === loggedInUserData._id) {
                return {_id:user._id, text: `${user.firstname} ${user.lastname}`, img:user.avatar ? user.avatar : "", editable:false}

            }
            else {
                return {_id:user._id, text: `${user.firstname} ${user.lastname}`, img:user.avatar ? user.avatar : ""}
            }
        })
    }    

    return(
        <div className="create-group-container">
            <p className="subtitle">All members can use the group. <br /> Only the manager can edit the group.</p>
                <Form.Group className="group explanation">
                    <input placeholder="Group name" autoFocus value={groupName} onChange={(evt) => evt.target.value.length <= 30 ? setGroupName(evt.target.value) : null} type="text"></input>
                    <label className="top-label">Group name</label>
                    <p className="sub-subtitle right">{groupName.length} /30</p>
                </Form.Group>
                <Form.Group className="group goal explanation">
                    <input placeholder="Group goal" value={groupDescription} onChange={(evt) => evt.target.value.length <= 50 ? setGroupDescription(evt.target.value) : null} type="text"></input>
                    <label className="top-label">Group goal</label>
                    <p onClick={() => setShowGroupGoalTooltip(true)} className="tooltip-text">What's this?</p>                   
                    <p className="sub-subtitle right">{groupDescription.length} /50</p>
                </Form.Group>
                <Form.Group className="group people">
                    <UniqueListInput placeholder={"People in the group"} handleAction={updatePeople} data={getMembers()} options={options.map(user => { return { _id: user._id, text: `${user.firstname} ${user.lastname}`, img: "" }; })} icon={true} />
                </Form.Group>
                <Form.Group className="group successors">
                    <UniqueUserListInput placeholder={"Group Successor(s)"} handleAction={updateGroupSuccesors} data={successors} options={members.filter(x => x._id !== localStorage.userid && x.profileType !== profileTypes.CHILD )} />
                    <p onClick={() => setShowTooltip(true)} className="tooltip-text">What's this?</p>
                    {showNotification ? <Notification data={successNotification} close={() => setShowNotification(false)} /> : null}
                    {showErrorNotification ? <Notification data={errorNotification} close={() => setShowErrorNotification(false)} /> : null}                    
                </Form.Group>
                <button disabled={!(groupName.length && groupDescription.length && members.length)} onClick={() => createGroup()} className="default-button">{props.ctaText}</button>
                <p className="info-text">Add new family members to groups, <br/>so they can access the group's old stories</p>
                <TooltipHelp contextKey={'profile_family_groups_successors'} show={showTooltip} clear={() => setShowTooltip(false)} />
                <TooltipHelp contextKey={'profile_family_groups_goal'} show={showGroupGoalTooltip} clear={() => setShowGroupGoalTooltip(false)} />                
        </div>
    )
}