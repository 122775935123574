import React from 'react';
import { Modal } from 'react-bootstrap';
import CreateGroup from './create-group.component';

interface IProps {
    show:boolean
    handleClose: () => void
    updateGroups: Function
}


export default function CreateNewGroupModal(props:IProps) {

    function close() {
        props.handleClose()
    }

    return(
        <Modal className="create-new-group-modal" show={props.show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title className="title">Create new Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreateGroup close={close} updateGroups={props.updateGroups} ctaText={"Create Group"}/>
            </Modal.Body>
        </Modal>
    );
}