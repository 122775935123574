/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import instance from "../../api/api";
import { ILifelesson } from "../../interfaces/story";
import Icon from "@mdi/react";
import { mdiAccount, mdiClose, mdiPen, mdiScriptTextOutline } from "@mdi/js";
import StoryVisualsFiltersBar, { sortOptions } from "./story-visuals-filters-bar.component";
import { Spinner } from "react-bootstrap";
import StoryLifelessonFilters from "./story-lifelessons-filters.component";
import { IUserDetails } from "../../interfaces/authentication";
import { IOption } from "../../interfaces/family";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { Context, defaultLifelessonFilters } from "../../pages/home.page";
import { ISort } from "./story-published-all.component";
import { dateFormat } from "../../_helper/enum/enum";
import config from "../../config/config";
import default_avatar from "../../assets/images/default-avatar.jpg";
import { getStoryDate } from "../../_helper/getStoryDate";
import IFrameDisplay from "../_helper/iframe-display.component";

interface IProps {

}

export interface ILifelessonFilters {
    [key: string]: any
    author:IUserDetails[]
    featured:IUserDetails[]
    lifelessonTags: IOption[]
}

export const lifelessonSortOptions = ['PUBLISHED_DATE', 'OLDEST', 'NEWEST'];

const filterIcons : any = {
    featured:mdiPen,
    author:mdiAccount,
    lifelessonTags:mdiScriptTextOutline
}

export default function StoryLifelessons(props:IProps) {

    const [ lifelessonsData, setLifelessonsData ] = useState<ILifelesson[]>([]);
    const [ selected, setSelected ] = useState<string[]>([]);
    const [ selectScreen, setSelectScreen ] = useState<boolean>(false);
    const [ showSelectedDetails, setShowSelectedDetails ] = useState<boolean>(false);

    //filters
    const [ filtersActive, setFiltersActive ] = useState<boolean>(false);
    const [ stateLoaded, setStateLoaded ] = useState<boolean>(false);
    const [ showFilters, _setShowFilters ] = useState<boolean>(false);
    const [ queryString, setQueryString ] = useState<string>("");

    const [ sort, setSort ] = useState<sortOptions>(sortOptions.PUBLISHED_DATE);
    const [ search, setSearch ] = useState<string>('');

    const [ pageNum, setPageNum ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ hasMore, setHasMore ] = useState<boolean>(false);
    const [ totalCount, setTotalCount ] = useState<Number>(0);
    const [ sortOption, setSortOption ] = useState<ISort>({field:'createdAt', type:'desc'});

    const history = useHistory();

    const location: any = useLocation();

    const filters = useContext(Context).lifelessonFilters;
    const setFilters = useContext(Context).setLifelessonFilters;

    useEffect(() => {
        if(stateLoaded && !loading) {
            fetchPublishedStories();
        }
    }, [ queryString, pageNum]);

    useEffect(() => {
        //Only run automatic filtering if the state is already loaded.
        if(stateLoaded) {
            setupQueryString();
        }
        
    }, [filters, sortOption])

    useEffect(() => {
        if(location.state) {
            //have to get some information from rerouting to be able to fill out query
            if(location.state.hasOwnProperty('filters')) {
                if(location.state.filters.hasOwnProperty('lifelessons')) {
                    setFiltersActive(true);
                    const newFilters = {...defaultLifelessonFilters, lifelessonTags: [location.state.filters.lifelessons]};
                    setFilters(newFilters);
                    setStateLoaded(true);
                }
            }
        }else {
            setStateLoaded(true);
        }
    }, [])

    async function fetchPublishedStories() {
        try {
            setLoading(true)

            const response = await instance.get(`/lifelesson?${queryString}&pageNum=${pageNum}&type=lifelesson`);

            let temp:ILifelesson[] = [...lifelessonsData, ...response.data.result];
            setLifelessonsData(temp);
            setHasMore(response.data.hasNextPage);
            setTotalCount(response.data.totalRecords)
            setLoading(false);

        } catch (error) {
            console.log("Failed to get published stories", error)
        }
    }

    function removeAllfilters() {
        setFilters(defaultLifelessonFilters);
    }

    function selectAll() {
        setSelected(lifelessonsData.map(x => x._id));
    }

    function setShowFilters() {
        _setShowFilters(prev => !prev)
    }

    async function setupQueryString() {
        const refactoredFilters:any = {};

        Object.entries(filters).forEach(function([key, value]) {

            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            if(value) {
                if(key === "author") {
                    refactoredFilters[key] = value.map((x:IUserDetails) => x._id);
                }
                else {
                    if(key === "lifelessonTags") {
                        refactoredFilters[key] = value.map((x:IOption) => x._id);
                    }
                    else if(key === "featured") {
                        if(!value.length) {
                            return
                        }
                        refactoredFilters[key] = value.map((x:IUserDetails) => x._id);
                    }
                    else {
                        refactoredFilters[key] = value
                    }
                }
            }
        })

        let sortString = "";

        if(sortOption.field.length) {
            sortString = "sort=" + sortOption.field + "," + sortOption.type;
        }

        const filterString =   Object.keys(refactoredFilters).map(function(key) {
            return key + '=' + refactoredFilters[key]
        }).join('&');

        const queryString = sortString + (filterString.length ? "&" + filterString : '');

        if(queryString.length) {
            setPageNum(0);
            setLifelessonsData([])
            setQueryString(queryString);
            setFiltersActive(true);

        }
        else {
            setPageNum(0);
            setLifelessonsData([]);
            setQueryString('');
            setFiltersActive(false);
        }
    }

    useEffect(() => {
        setLifelessonsData([]); //Clear options;
        
        switch (sort){
            case sortOptions.PUBLISHED_DATE:
                setSortOption({field:'relatedStory.createdAt', type:'desc'})
                break
            case sortOptions.OLDEST:
                setSortOption({field:'relatedStory.date.startDate.date', type:'asc'})
                break
            case sortOptions.NEWEST:
                setSortOption({field:'relatedStory.date.startDate.date', type:'desc'})
                break
            default:
                setSortOption({field:'', type:''})
        }

    }, [ sort ])

    /**
     * Delete a filter by specific key.
     * @param key - The key for the filter we want to delete
     */
    function removeFilterByKey(key:string) {
        let tempFilterOject = { ...filters }
        if(tempFilterOject.hasOwnProperty(key)) {
            tempFilterOject[key] = defaultLifelessonFilters[key];
            setFilters({...tempFilterOject})
            setLifelessonsData([])            
        }
    }

    function filterRow() {
        let emptyFilters = true;
        const filterRow = Object.entries(filters).map(([key, value]) => {
            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            if(key === "date") {
                if((value.year === null && value.month === null && value.day === null)) {
                    return
                }
            }
            if(value) {
                emptyFilters = false;
                return(
                    <div className="active-filter-box">
                        <div>
                            <Icon className="info-icon" size={0.7} path={filterIcons[key]} />
                        </div>
                        {Array.isArray(value) ?
                            (key === "featured" || key === "author") ? <p>{value.map(x =>`${x.firstname} ${x.lastname}`).join(", ")}</p> : <p>{value.map(x => x.text).join(",")}</p>
                            :
                            <p>{value}</p>
                        }
                        <div className="remove" onClick={() => removeFilterByKey(key)}>
                            <Icon className="close-icon" size={0.7} path={mdiClose} />
                        </div>
                    </div>
                )
            }
        })

        if(emptyFilters) {
            setFiltersActive(false);
            setQueryString("sort=" + sortOption.field + "," + sortOption.type);
        }

        return filterRow;
    }

    return(
        <div className="story-visuals-container story-lifelessons-container">
            <div className="filters">
                <StoryVisualsFiltersBar removeAllfilters={removeAllfilters} filtersActive={filtersActive} showFilters={showFilters} setShowFilters={setShowFilters} setSort={setSort} sort={sort} search={search} setSearch={setSearch} sortOptions={lifelessonSortOptions}/>
            </div>
            {!showFilters ? <div>
                {filtersActive && <div className='active-filter-container'>{filterRow()}</div>}
                <div className="info-row">
                    <div>
                        {selectScreen ? <p className="result">{selected.length} selected</p> : <p className="result">{totalCount} Life Lesson(s)</p>}
                    </div>
                </div>
                <div className="visuals lifelessons unpublished-stories">
                    {lifelessonsData.map((lifelesson, index) => {
                        return(

                            <div key={index} className="up-story story-container" onClick={() => {history.push(`/dashboard/story/${lifelesson.relatedStory._id}`)}}>
                                <p className='story-title'>{lifelesson.relatedStory.title}</p>
                                <p>{lifelesson.data}</p>
                                
                                {/*
                                <div className="action-container" onClick={(e) => { e.stopPropagation(); }}>
                                    <Icon className="action" size={1} path={ mdiDotsHorizontal } />
                                </div>
                                */}
                                <div className="tags">
                                    {lifelesson.tags.map((tag) => {
                                            return(
                                                <div key={tag} className="tag" onClick={(e) => 
                                                        {   e.stopPropagation(); 
                                                            const newFilter = {...filters, lifelessonTags: [(config.lifelessonTags.find(data => data._id === tag) as IOption)] };
                                                            setFilters(newFilter);
                                                        }
                                                    }><p>{(config.lifelessonTags.find(data => data._id === tag) as IOption).text}</p></div>
                                        )}
                                    )}
                                </div>

                                <div className='img-author'>
                                    <img className="user-picture" src={(lifelesson.createdBy as IUserDetails).avatar ? (lifelesson.createdBy as IUserDetails).avatar : default_avatar} alt="user" />
                                    <p className="story-author">{(lifelesson.createdBy as IUserDetails).firstname}</p>
                                </div>
                                <p className="last-modified">{getStoryDate(lifelesson.relatedStory.date.startDate)}</p>                      
                            </div>                              

                        )
                    })}
                    {!lifelessonsData.length && <IFrameDisplay pageName={"stories-lifelessons"} />}
                    {!hasMore && lifelessonsData.length ? <p className="has-more">No more life lessons to display</p> : null}
                    {hasMore ? <button className="bordered-button" onClick={() => setPageNum(prev => prev+1)}> {loading ? <Spinner animation="border" variant="#fff" /> : "Load More"}</button> : null}

                </div>
            </div> : 
            <StoryLifelessonFilters count={totalCount} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} queryString={queryString}/>
            }
        </div>
    )
}