import React from 'react';
import { Modal } from 'react-bootstrap';
import { IMedia } from '../../interfaces/story';
import CommentsContainer, { ECommentType } from '../comments/comments-container.component';
import VideoPlayer from '../_helper/video-player.component';

interface IProps {
    show:boolean
    onHide:Function
    visual:IMedia | null
    addCommentCallback?: Function
    storyAccessToken: string
}

export default function StoryMediaCommentModal(props:IProps) {


    return(
        <Modal className="visual-details-modal media-comment-modal" show={props.show} onHide={props.onHide}>
            <Modal.Header className="" closeButton>
                <p>Comments</p>
            </Modal.Header>
            {props.visual !== null ? <Modal.Body className="edit-card-content">
                <div className="image-container">
                    { props.visual.fileType === "image" ?
                        <img alt="visual-detail" src={props.visual.dataM ? props.visual.dataM : props.visual.data} />
                        : 
                        <VideoPlayer url={props.visual.data}/>
                    }
                </div>
                <div className="comment-section">
                    <CommentsContainer addCommentCallback={props.addCommentCallback} commentsEnabled={true} title={""} related_entity={props.visual._id} access_entity={props.visual.relatedStory as any} accessToken={props.storyAccessToken || "" } comment_type={ECommentType.MEDIA} />
                </div>
            </Modal.Body> : <p>Failed to load Media.</p>}
        </Modal>
    );
}