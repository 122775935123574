import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react'
import { mdiSortVariant, mdiCheck, mdiMenuDown, mdiStarOutline, mdiStar } from '@mdi/js';
import { profileVoiceSortOptions } from './profile-voice.component';

interface IProps {
    setSort: Function
    sort: profileVisualSortOptions | profileVoiceSortOptions
    setFavourite: Function
    favourite: boolean
    container: EFilterBarContainer
}

export enum EFilterBarContainer {
    MEDIA="MEDIA",
    VOICE="VOICE"
}

export enum profileVisualSortOptions {
    UPLOAD_DATE="Upload date",
    OLDEST="Oldest story date",
    NEWEST="Newest story date",
    VIDEOS_FIRST="Videos first",
    IMAGES_FIRST="Images first",
}

export default function ProfileVisualsFiltersBar(props:IProps) {

    //sort
    const [showSort, setShowSort] = useState<boolean>(false)

    function updateSort(filter:profileVisualSortOptions | profileVoiceSortOptions) {
        props.setSort(filter);
        setShowSort(false)
    }

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);
    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShowSort(false);
        }
    };

    return(
        <div className='profile-filters-bar-visuals'>
            <div className="filters">
                <div className="left-filters">
                    <div ref={upperRef} className="sort-options" onClick={() => setShowSort(!showSort)}>
                        <Icon className="filter-icon" size={1} path={ mdiSortVariant } />
                        <p>{props.sort}</p>
                        <Icon className="selected-option" size={0.8} path={ mdiMenuDown } />
                    </div>
                    {showSort && 
                        <div ref={wrapperRef} className="options">
                            {Object.entries(props.container === EFilterBarContainer.MEDIA ? profileVisualSortOptions : profileVoiceSortOptions).map(([key, value]) => {
                                return(
                                    <div className={"option" + (props.sort === value ? " selected" : "")} onClick={() => updateSort(value)}>
                                        <p>{value}</p>
                                        {props.sort === value && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                                    </div>
                                )
                            })}
                        </div>}
                </div>
                <div className="right-filters">
                    <div className={"filter" + (props.favourite ? " active" : "")} >
                        <div className="icon-container">
                            <p className='favourite' onClick={() => props.setFavourite()}><Icon className="filter-icon" size={1} path={ props.favourite ? mdiStar : mdiStarOutline } />  My Favourites</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}