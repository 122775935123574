import React, { useEffect, useCallback, useContext, useState, Dispatch} from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom";
import Credit from "../../../components/account/credit.component";
import MediaBoost from "../../../components/account/media-boost.component";
import AccountPurchases from "../../../components/account/purchases.component";
import Subscription from "../../../components/account/subscription.component";
import TabSlider from "../../../components/menu-items/tab-slider";
import Help from "../../../components/_helper/help.component";
import { setCurrentPage } from "../../../store/actionCreators";
import { Context } from "../../home.page";
import Experts from "../../../components/account/experts.component";

export enum menu {
    SUBSCRIPTION = "subscription",
    MEDIA_BOOST = "media-boost",
    EXPERTS = "experts",
    PURCHASES = "purchases",

}

interface RouteParams {
    mainMenu: string
    subMenu:string
}

/**
 * Account page
 *
 */
function Account() {

    // params for the tab menu
    const params = useParams<RouteParams>();
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    //Loader
    const [ loaded, setLoaded ] = useState<boolean>(false);

    //tabs
    const [key, setKey] = useState<menu>(menu.SUBSCRIPTION);

    // for checking window width to toggle mobile tabs navigation
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const MAX_WIDTH = 500;
    const checkWindowWidth = (condition: string = "lte", threshold: number = MAX_WIDTH) => (condition === "lte" ? windowWidth <= threshold : windowWidth > threshold);
    const detectSize = () => { setWindowWidth(window.innerWidth); };
    
    const [activeTab, setActiveTab] = useState<menu>(menu.SUBSCRIPTION);
    const isTabActive = (tabName: menu) => (activeTab === tabName ? "active" : "");

    //Logged in user
    const updateUserData = useContext(Context).updateUserData;

    const history = useHistory();

    //translation
    const { t } = useTranslation();

    setPageName('My Subscription');

    //Update user data globally to check for uploads, and subscription related data
    useEffect(() => {
        console.log('getting user data')
        updateUserData();
    }, [])

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => { window.removeEventListener("resize", detectSize); };
    }, [windowWidth]);

    useEffect(() =>  {
        if(params.mainMenu && params.mainMenu !== key) {
            setKey(params.mainMenu as menu);
            setActiveTab(params.mainMenu as menu)
        }      
    }, [ params.mainMenu ])
    
    function handleSetKey(tabItem: menu) {
        history.push("/dashboard/account/" + tabItem + "/info");
    }


    return (
        <div className="profile-container account">
            <TabSlider checkWindowWidth={checkWindowWidth} maxWidth={MAX_WIDTH}>
                <li className={isTabActive(menu.SUBSCRIPTION)} onClick={() => { handleSetKey(menu.SUBSCRIPTION); }}>{t("Subscription")}</li>
                <li className={isTabActive(menu.MEDIA_BOOST)} onClick={() => { handleSetKey(menu.MEDIA_BOOST); }}>{t("Media Boost")}</li>
                <li className={isTabActive(menu.EXPERTS)} onClick={() => { handleSetKey(menu.EXPERTS); }}>{t("Experts")}</li>
                <li className={isTabActive(menu.PURCHASES)} onClick={() => { handleSetKey(menu.PURCHASES); }}>{t("Purchases")}</li>

            </TabSlider>
            
            {(checkWindowWidth() && isTabActive(menu.SUBSCRIPTION)) && 
                <div>
                    <Subscription />
                </div>
            }
            {(checkWindowWidth() && isTabActive(menu.MEDIA_BOOST)) && 
                <div>
                    <MediaBoost />           
                </div>
            }
            {(checkWindowWidth() && isTabActive(menu.EXPERTS)) && 
                <div>
                    <Experts />           
                </div>
            }
            {(checkWindowWidth() && isTabActive(menu.PURCHASES)) && 
                <div>
                    <AccountPurchases /> 
                    <Help category={"MyAccount"} pageName={"Purchases"}></Help>
                </div>
            }

            {checkWindowWidth("gt") &&
            <Tabs className="profile-tabs menu-tabs" activeKey={key} onSelect={(k) => { handleSetKey(k as menu); }}>
                <Tab eventKey={menu.SUBSCRIPTION} title={t("Subscription")}>
                    <Subscription />
                </Tab>
                <Tab eventKey={menu.MEDIA_BOOST} title={t("Media Boost")}>
                    <MediaBoost />
                </Tab>
                <Tab eventKey={menu.EXPERTS} title={t("Experts")}>
                    <Experts />
                </Tab>
                <Tab eventKey={menu.PURCHASES} title={t("Purchases")}>
                    <div>
                        <AccountPurchases />
                        <Help category={"MyAccount"} pageName={"Purchases"}></Help>
                    </div>
                </Tab>
            </Tabs>
            }
        </div>
    );
}

export default Account;
