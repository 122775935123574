import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import config from '../../../config/config';
import axios from 'axios';
import { mdiLightbulbOn } from '@mdi/js';
import Icon from '@mdi/react';
import { contentImage } from '../../../pages/dashboard/inspirations.page';

const date = new Date();

interface IProps {
    inspirationQuestionId:string    
}

export default function AttachedInspiration(props:IProps) {

    const [ question, setQuestion ] = useState<any | null>(null);
    const [ error, setError ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(true);

    const history = useHistory();    

    useEffect(() => {
        getInspiration();
    }, []);


    async function getInspiration() {        
        const response = axios.get(`${config.contentServerURL}/api/inspiration-questions/${props.inspirationQuestionId}?populate[0]=inspiration_image`).then(resp => { // Strapi v4 query format
            setQuestion(resp.data.data)
            console.log(resp.data.data)
            setLoading(false)
        }, error => {
            setLoading(false);
            setError(true);            
            console.log("Failed to get inspiration question", error)
        });
      
    }

    return (
        <div className="public-story-page attached-inspiration">
            <div className="new-block">
                <div className="add-story-svg">
                    <Icon size={2.5} path={ mdiLightbulbOn } />
                    <div className="line"></div>
                </div>
            </div>
            <p className="attachment-title">Inspiration</p>            
            {loading ?
                <div className='loader-container'>
                    <Spinner animation="border" variant="#fff"/>
                </div>
                : error ?
                <div className='error-container'>
                    <p>Can't load inspiration question.</p>
                </div>
                : question !== null ?
                <div className="story-container">
                    <div className="story-details">
                        <div className="story-body">
                            <p className="question">{question.attributes.question}</p>
                            {question.attributes.optional && <p className="follow-up">{question.attributes.optional}</p>}
                            <img src={contentImage(question.attributes.inspiration_image.data?.attributes.url)} alt="inspirational image" />
                        </div>
                    </div>
                </div>            
            : null}
        </div>
    );
}
