import React, { Dispatch, useCallback, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import Help from "../../../components/_helper/help.component";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * /settings/security page
 * 
 */

function LoginAndSecurity() {

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    useEffect(() => {
        setPageName('Login & Security');
    }, [])

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="settings">
            <div className="settings-container">
            <div className="setting-group-row">
                <p className="setting-group-title">{t("Account data")}</p>
            </div>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/security/account`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Account Data")}</p>
                    <p className="settings-description">Edit password</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>
            <div className="setting-group-row">
                <p className="setting-group-title">{t("Deletion")}</p>
            </div>
            <Row className="setting-option-row" onClick={() => history.push(`/dashboard/settings/deleteprofile`)}>
                <Col xs={11}>
                    <p className="settings-title">{t("Delete Profile")}</p>
                </Col>
                <Col className="icon-container" xs={1}>
                    <Icon className="right-icon" size={1.5} path={ mdiChevronRight } />
                </Col>
            </Row>
            </div>
            <Help category={"SETTINGS"} pageName={"Login_Security"}></Help>
        </div>
    )
}

export default LoginAndSecurity
