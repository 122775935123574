import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import instance from '../../api/api';
import { IRegUser } from '../../interfaces/authentication';
import IPage from '../../interfaces/page';
import { IRegistrationResponse } from '../../interfaces/responses';
import * as EmailValidator from 'email-validator';
import { INotification, NotificationType } from '../../interfaces/notification';
import Notification, { notificationImages } from '../../components/notifications/notification.component';
import simirity_logo from "../../assets/images/simirity-logo.png";
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import SubscriptionReferalWidget from '../../components/account/subscription/subscription-referral-registration-widget.component';
import SubscriptionFamilyVipWidget from '../../components/account/subscription/subscription-family-vip-registration-widget.component';

const defaultUserObject : IRegUser = {
    firstname:'',
    lastname:'',
    email:'',
    password:''
}

/**
 * Registration page for user authentication
 * @param props : unused for now
 * @returns void
 */
const Registration: React.FunctionComponent<IPage> = props => {

    const [ userData, setUserData ] = useState<IRegUser>({...defaultUserObject});
    const [ inputError, setInputError] = useState<IRegUser>({...defaultUserObject});
    const [ confirmPassword, setConfirmPassword] = useState<string>('');
    const [ tos, setTos ] = useState<boolean>(false);

    const [ referral, setReferal ] = useState<string>("");
    const [ familyVip, setFamilyVip ] = useState<string>("");    
    const [ validReferal, setValidReferal ] = useState<boolean>(false);
    const [ validFamilyVip, setValidFamilyVip ] = useState<boolean>(false);    

    const [ notification, setNotification ] = useState<INotification>({icon:'', title:'', text:'', success:NotificationType.problem});

    const history = useHistory();
    
    const { t } = useTranslation();

    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
        }        
        if(props.location.state) {
            if(props.location.state.id) {
                getInvitation(props.location.state.id)
            }

            if(props.location.state.referral) {
                setReferal(props.location.state.referral);
                validateReferal(props.location.state.referral);
            }

            if(props.location.state.familyVip) {
                setFamilyVip(props.location.state.familyVip);
                validateFamilyVip(props.location.state.familyVip);
            }            
        }
    }, [])

    //Set error to empty string after a while.
    function resetNotification() {
        setNotification({icon:'', title:'', text:'', success:NotificationType.problem})
    }

    // Set the register button to disabled if one of the input fields is empty
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        let dataIsMissing:boolean = false

        Object.entries(userData).forEach(([key, value]) => {
            if (!value.length) {
                dataIsMissing = true
            }
        })

        if (!confirmPassword.length) {
            dataIsMissing = true
        }

        setButtonDisabled(dataIsMissing)
    }, [userData, confirmPassword])

    async function validateReferal(id:string) {
        try {
            const response = await instance.get("/subscriptions/public/" + id);
            if(response.data.valid) {
                setValidReferal(true);
            }
        }
        catch(err) {
            console.log('err', err)
        }
    }

    async function validateFamilyVip(id:string) {
        try {
            const response = await instance.get("/subscriptions/family-vip/public/" + id);
            if(response.data.length) {
                setValidFamilyVip(true);
            }            
        }
        catch(err) {
            console.log('err', err)
        }
    }    

    /**
     * Login request
     * response:
     *      - 200: token
     *      - 403: Unauthorized
     */
    async function handleRegistration(evt: React.FormEvent) {
        evt.preventDefault();

        let isValidData:boolean = true

        //Email validation
        if(!EmailValidator.validate(userData.email)) {
            setNotification({...notification, icon:notificationImages.crying, title:'Error', text:'Invalid e-mail format!'})
            return
        }

        if(userData.password !== confirmPassword || !confirmPassword.length) {
            setNotification({...notification, icon:notificationImages.crying, title:'Error', text:"Passwords do not match"})
            return
        };

        if(userData.password.length < 8 || !(/\d/.test(userData.password))) {
            setNotification({...notification, icon:notificationImages.crying, title:'Error', text:"Password has to be at least 8 character, and must contain a number."})
            return
        }

        if(!tos) {
            setNotification({...notification, icon:notificationImages.crying, title:'Error', text:"Term of service required."})
            return
        }

        if (isValidData === true) {
            try {

                // add language if there is any
                const sendUserData = props.match.params.lang ? {...userData, language:props.match.params.lang} :  userData;

                let response = await instance.post<IRegistrationResponse>('/auth/registration', { userData: sendUserData });
                
                if(response && response.data.user && response.data.user._id) {

                    if(response.data.user._id) {
                        localStorage.setItem('userid', response.data.user._id);
                    }
                    history.push(`/verification${props.match.params.lang ? "/"+props.match.params.lang : ''}`, { email: response.data.user.email, referral:referral, familyVip: familyVip, firstname: response.data.user.firstname, lastname:response.data.user.lastname });

                } else if(response && !response.data.verified && response.data.user.email) {
                    // already existing but not verified user                
                    history.push(`/verification${props.match.params.lang ? "/"+props.match.params.lang : ''}`, { email: response.data.user.email, referral:referral, familyVip: familyVip, firstname: response.data.user.firstname, lastname:response.data.user.lastname });
                } else {
                    // already existing user
                    setNotification({
                        ...notification,
                        icon: notificationImages.crying,
                        title: "Error",
                        text: (
                            <div>
                                <p>Email already registered</p>
                                <p className="forgot-password-link" onClick={() => history.push("/forgotten-password")}>
                                    Forgotten password?
                                </p>
                            </div>
                        ),
                    })                    
                }
            }
            
            catch(err) {
                setNotification({...notification, icon:notificationImages.crying, title:'Error', text:"Registration failed. Please notify us at info@simirity.com"})
            }
        }
    }

    async function getInvitation(id:string) {
        const invitation = await instance.get('/invitation/' + id);
        if(invitation.data) {
           setUserData(prev => {return {...prev, email:invitation.data.email}})
        }
    }

    return(
        <Container className="registration-container">
            <img src={simirity_logo} alt="Logo" className="logo" />            
            <p className="title">{t("Register")}</p>
                <Form className="registration-form" onSubmit={handleRegistration}>
                    <Form.Group className="group firstname">
                        <input required autoFocus={true} type="text" className={inputError.firstname.length ? "string-input warning": "string-input"} placeholder={t("First name")} value={userData.firstname} onChange={(evt) => setUserData(prev => {return {...prev, firstname:evt.target.value}})} />
                        <Form.Label className="top-label">{t("First name")}</Form.Label>
                        {inputError.firstname.length ? <p className="warning-message">{inputError.firstname}</p> : null}
                    </Form.Group>

                    <Form.Group className="group lastname">
                        <input required type="text" className={inputError.firstname.length ? "string-input warning": "string-input"} placeholder={t("Last name")} value={userData.lastname} onChange={(evt) => setUserData(prev => {return {...prev, lastname:evt.target.value}})} />
                        <Form.Label className="top-label">{t("Last name")}</Form.Label>
                        {inputError.lastname.length ? <p className="warning-message">{inputError.lastname}</p> : null}
                    </Form.Group>

                    <Form.Group className="group email">
                        <input required type="email" className={inputError.firstname.length ? "string-input warning": "string-input"} placeholder={t("Email")} value={userData.email} onChange={(evt) => setUserData(prev => {return {...prev, email:evt.target.value}})} />
                        <Form.Label className="top-label">{t("Email")}</Form.Label>
                        <Form.Label className="bottom-label">{t("Invited to Simirity? Use your invitation's email address.")}</Form.Label>
                        {inputError.email.length ? <p className="warning-message">{inputError.email}</p> : null}
                    </Form.Group>

                    <Form.Group className="group password">
                        <input required type="password" className={inputError.firstname.length ? "string-input warning": "string-input"} placeholder={t("Password")} value={userData.password} onChange={(evt) => setUserData(prev => {return {...prev, password:evt.target.value}})} />
                        <Form.Label className="top-label">{t("Password")}</Form.Label>
                        <Form.Label className="bottom-label">{t("At least 8 characters, with letters and numbers")}</Form.Label>
                        {inputError.password.length ? <p className="warning-message">{inputError.password}</p> : null}
                    </Form.Group>

                    <Form.Group className="group confirm-password">
                        <input required type="password" className={inputError.firstname.length ? "string-input warning": "string-input"} placeholder={t("Confirm password")} value={confirmPassword} onChange={(evt) => setConfirmPassword(evt.target.value)}/>
                        <Form.Label className="top-label">{t("Confirm password")}</Form.Label>
                    </Form.Group>

                    <Form.Group className="checkbox">
                        <div onClick={() => setTos(prev => !prev)}>
                            <Form.Check required className="checkbox-input" type="checkbox" label={t("I am 18+ years old and agree to use Simirity according to the Terms & Conditions and accept emails relating to my subscription")} checked={tos} />
                        </div>
                    </Form.Group>

                    {referral.length && validReferal 
                        ?
                            <SubscriptionReferalWidget lang={props.match.params.lang ? props.match.params.lang : ''}/>
                        : 
                            (referral.length && !validReferal )
                        ?
                            <p>{t("Expired referral")}</p> :
                        null 
                    }

                    {familyVip.length && validFamilyVip
                        ?
                            <SubscriptionFamilyVipWidget />
                        : 
                            (familyVip.length && !validFamilyVip )
                        ?
                            <p>t{("Expired Family-VIP")}</p> :
                        null 
                    }                    

                    {notification.title.length ? <Notification data={notification} close={() => resetNotification()}/> : null}

                    <div className="button-group">
                        <Button disabled={isButtonDisabled} className="registration-button default-button" variant="primary" type="submit">{t("Register")}</Button>
                    </div>
                    <div className="tos">
                        <p>
                            <a href="https://www.simirity.com/terms-conditions/" className="underlined-link-button" rel="noreferrer" target="_blank">{t("Terms & Conditions")}</a> and <a href="https://www.simirity.com/data-privacy/" className="underlined-link-button" rel="noreferrer" target="_blank">{t("Privacy Policy")}</a>
                        </p>
                    </div>
                </Form>
        </Container>
    );
};

export default Registration;
