import { useCallback, Dispatch, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/actionCreators";
import { Context } from "../home.page";
import TabSlider from "../../components/menu-items/tab-slider";
import { useHistory, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Refer from "../../components/refer-and-save/refer.component";
import Save from "../../components/refer-and-save/save.component";
import Help from "../../components/_helper/help.component";

export enum menu {
    REFER = "refer",
    SAVE = "save",
}

interface RouteParams {
    mainMenu: string
}

export default function ReferAndSave() {

    const params = useParams<RouteParams>();
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);
    setPageName('Refer & Save');

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const isTabActive = (tabName: menu) => (key === tabName ? "active" : "");
    const MAX_WIDTH = 500;
    const checkWindowWidth = (condition: string = "lte", threshold: number = MAX_WIDTH) => (condition === "lte" ? windowWidth <= threshold : windowWidth > threshold);
    const detectSize = () => { setWindowWidth(window.innerWidth); };

    const user = useContext(Context).user;
    const history = useHistory();

    //tabs
    const [key, setKey] = useState<menu>(menu.REFER);

    function handleSetKey(tabItem: menu) {
        history.push("/dashboard/referandsave/" + tabItem);
    }

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => { window.removeEventListener("resize", detectSize); };
    }, [windowWidth]);


    useEffect(() =>  {
        if(params.mainMenu !== key) {
            setKey(params.mainMenu as menu);
        }      
    }, [ params.mainMenu ])

    useEffect(() => {
        if(!user.subscription) {
            history.goBack();
        }
    }, [user])

    const { t } = useTranslation();
 
    return(
        <div className="profile-container refer-and-save">
            {user.subscription ? <>
                <TabSlider checkWindowWidth={checkWindowWidth} maxWidth={MAX_WIDTH}>
                    <li className={isTabActive(menu.REFER)} onClick={() => { handleSetKey(menu.REFER); }}>{t("Refer")}</li>
                    <li className={isTabActive(menu.SAVE)} onClick={() => { handleSetKey(menu.SAVE); }}>{t("Save")}</li>

                </TabSlider>
                
                {(checkWindowWidth() && isTabActive(menu.REFER)) && 
                    <div>
                        <Refer />
                        <Help category={"REFERSAVE"} pageName={"Refer"}></Help>
                    </div>
                }
                {(checkWindowWidth() && isTabActive(menu.SAVE)) && 
                    <div>
                        <Save />   
                        <Help category={"REFERSAVE"} pageName={"Save"}></Help>
                    </div>
                }

                {checkWindowWidth("gt") &&
                <Tabs className="profile-tabs menu-tabs" activeKey={key} onSelect={(k) => { handleSetKey(k as menu); }}>
                    <Tab eventKey={menu.REFER} title={t("Refer")}>
                        <Refer />
                        <Help category={"REFERSAVE"} pageName={"Refer"}></Help>
                    </Tab>
                    <Tab eventKey={menu.SAVE} title={t("Save")}>
                        <Save />
                        <Help category={"REFERSAVE"} pageName={"Save"}></Help>
                    </Tab>
                </Tabs>
                }
            </> : <p></p>}
        </div>
    );
}