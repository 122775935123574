import React, { useState, useEffect, useRef, FormEvent } from "react"

// components
import ConfirmModal from "../_helper/confirm-modal.component"

// packages
import DatePicker from "react-datepicker"
import countries from "countries-list"

// bootstrap
import { Form } from "react-bootstrap"

// enums and helpers
import instance from "../../api/api"
import { profileTypes, userFields } from "../../_helper/enum/enum"
import checkUnderAge from "../../_helper/check-underage"

// icons
import Icon from "@mdi/react"
import { mdiGreasePencil, mdiPlusCircle, mdiLockOutline, mdiCheck, mdiCheckCircle, mdiCloseCircle, mdiCalendar } from "@mdi/js"

// imports for phone number
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import moment from "moment"

interface IProps {
    title: string
    displayedData: string
    hasEditRights: boolean
    userid: string
    field: string
    updateUser: Function
    websites?: string[]
    birthdate?: Date
    diedon?: Date
}

function InfoField(props: IProps) {

    // state to the new input value
    const [inputValue, setInputValue] = useState<string>("")
    const [dateInput, setDateInput] = useState<Date | null>(null)
    const startYear = new Date(1000, 1, 1);

    useEffect(() => {
        setInputValue(props.displayedData)
    }, [props.displayedData])

    // state to handle the showing of the input field
    const [showEditField, setShowEditField] = useState<Boolean>(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const upperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.displayedData, inputValue])

    function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            if (inputValue && props.displayedData !== inputValue) {
                setShowConfirmModal(true)
            } else {
                setShowEditField(false)
            }
        }
    }

    async function handleSubmit(evt: FormEvent<EventTarget>) {
        evt.preventDefault()
        try {

            // request to update the selected inputfield with the inputvalue
            let response
            if (props.field === userFields.WEBSITES && props.websites !== undefined) {
                if (!inputValue) {
                    return
                }
                response = await instance.put(`/user/${props.userid}`, { details: { [props.field]: [...props.websites, inputValue] } })
            } else if (props.field === userFields.BIRTHDATE) {
                if (!dateInput) {
                    return
                }
                const date = moment(dateInput.toLocaleString()).parseZone().toISOString();
                response = await instance.put(`/user/${props.userid}`, { details: { [props.field]: date } })
            } else if (props.field === userFields.DIEDON) {
                if (!dateInput) {
                    return
                }
                const date = moment(dateInput.toLocaleString()).parseZone().toISOString();
                response = await instance.put(`/user/${props.userid}`, { details: { [props.field]: date } })
            }
            else {
                if (!inputValue) {
                    return
                }
                response = await instance.put(`/user/${props.userid}`, { details: { [props.field]: inputValue } })
            }

            // update the data on the profile info page
            props.updateUser(props.field, response.data[props.field])
            // close the input field
            setShowEditField(false)
            setShowConfirmModal(false)
            if (props.field === userFields.WEBSITES) {
                setInputValue("")
            }
        } catch (error) {
            // TODO: error notification (design?)
            console.log("error (update field")
        }
    }

    async function setToAdult() {
        try {
            const response = await instance.put(`/user/${props.userid}`, { details: { profileType: profileTypes.ADULT } })
            // update the data on the profile info page
            props.updateUser(props.field, response.data.profileType)
        } catch (error) {
            // TODO: error notification (design?)
            console.log("error (update field")
        }
    }

    function setInputField() {
        if (props.field === userFields.COUNTRY) {
            const countryNames = Object.values(countries.countries).map((item) => item.name).sort();
            return (
                <div>
                    <Form>
                        <Form.Label className="top-label">{props.title}</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(evt) => setInputValue((evt.target as HTMLInputElement).value)} value={inputValue}>
                            <option disabled value=""></option>
                            {countryNames.map((country) => {
                                return (
                                    <option key={country} value={country}>
                                        {country}
                                    </option>
                                )
                            })}
                        </Form.Select>
                        <div className="submit-icon-container" onClick={handleSubmit}>
                            <Icon size={1} path={mdiCheck} />
                        </div>
                    </Form>
                </div>
            )
        } else if (props.field === userFields.PROFILETYPE) {
            return props.birthdate && !checkUnderAge(props.birthdate) ? (
                <div>
                    <p className="top-label">{props.title}</p>
                    <p className="change-profiletype">
                        Set profile type to adult?
                        <span onClick={setToAdult}>
                            <Icon size={1} path={mdiCheckCircle} />
                        </span>
                        <span onClick={() => setShowEditField(false)}>
                            <Icon className="refuse" size={1} path={mdiCloseCircle} />
                        </span>
                    </p>
                </div>
            ) : (
                <p>A user under the age of 18 can not have adult profile type.</p>
            )
        } else if (props.field === userFields.BIRTHDATE) {
            return (
                <Form onSubmit={handleSubmit}>
                    <Form.Label className="top-label">Birth Date</Form.Label>
                    <Icon className="calendar-icon" size={1} path={mdiCalendar} />
                    <DatePicker
                        autoFocus
                        required
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd-MMM-yyyy"
                        dropdownMode="select"
                        selected={dateInput instanceof Date ? dateInput : null}
                        minDate={startYear}
                        maxDate={new Date()}
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        onChange={(date) => {
                            date instanceof Date ? setDateInput(date) : setDateInput(null)
                        }}
                    />
                    <div className="submit-icon-container" onClick={handleSubmit}>
                        <Icon size={1} path={mdiCheck} />
                    </div>
                </Form>
            )
        } else if (props.field === userFields.DIEDON) {
            return (
                <Form onSubmit={handleSubmit}>
                    <Form.Label className="top-label">Death Date</Form.Label>
                    <Icon className="calendar-icon" size={1} path={mdiCalendar} />
                    <DatePicker
                        autoFocus
                        required
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd-MMM-yyyy"
                        dropdownMode="select"
                        selected={dateInput instanceof Date ? dateInput : null}
                        minDate={startYear}
                        maxDate={new Date()}
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        onChange={(date) => {
                            date instanceof Date ? setDateInput(date) : setDateInput(null)
                        }}
                    />
                    <div className="submit-icon-container" onClick={handleSubmit}>
                        <Icon size={1} path={mdiCheck} />
                    </div>
                </Form>
            )
        } else if (props.field === userFields.PHONE) {
            return (
                <div>
                    <p className="top-label">{props.title}</p>
                    <PhoneInput autofocus placeholder="Enter phone number" value={inputValue} onChange={(val) => (val ? setInputValue(val as string) : null)} />
                    <div className="submit-icon-container" onClick={handleSubmit}>
                        <Icon size={1} path={mdiCheck} />
                    </div>
                </div>
            )
        } else {
            return (
                <Form onSubmit={handleSubmit}>
                    <Form.Label className="top-label">{props.title}</Form.Label>
                    <input autoFocus value={inputValue} onChange={(evt) => setInputValue(evt.target.value)} type="text" name="" id="" />
                    <div className="submit-icon-container" onClick={handleSubmit}>
                        <Icon size={1} path={mdiCheck} />
                    </div>
                </Form>
            )
        }
    }

    return (
        <div className="detail-item" ref={upperRef}>
            {showEditField ? (
                <div className="edit" ref={wrapperRef}>
                    {setInputField()}
                    <ConfirmModal
                        show={showConfirmModal}
                        handleClose={() => setShowConfirmModal(false)}
                        modalTitle="Unsaved Information"
                        modalBody={() => (
                            <div>
                                <p><span className="field-name">{`${props.title}: `}</span>{inputValue}</p>
                                <p>Save this update?</p>
                            </div>
                        )}
                        reject={() => {
                            setInputValue(props.displayedData)
                            setShowEditField(false)
                            setShowConfirmModal(false)
                        }}
                        confirm={handleSubmit}
                    />
                </div>
            ) : (
                <div className="infos">
                    <p className="label">{props.title}</p>
                    <div className="detail-text">
                        {props.field !== userFields.WEBSITES && <p>{props.displayedData ? props.displayedData : "-"}</p>}
                        {props.hasEditRights && (
                            <div className="edit-icon-container" onClick={() => (props.title !== "Email" ? setShowEditField(!showEditField) : null)}>
                                <Icon
                                    className={props.title === "Email" ? "email" : "editable"}
                                    size={1}
                                    path={props.title === "Email" ? mdiLockOutline : props.title === "Related websites" ? (props.displayedData.length ? mdiGreasePencil : mdiPlusCircle) : (props.displayedData ? mdiGreasePencil : mdiPlusCircle) }
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default InfoField
