import Carousel from "react-bootstrap/Carousel";
import Markdown from 'markdown-to-jsx';
import { useHistory } from "react-router";
import IFrameDisplay from "../_helper/iframe-display.component";


interface IProps {
    id: string
    attributes: IPropsAttributes;
}

interface IPropsAttributes {
    title: string;
    submitter: string;
    tip: string;
    vimeo_id?: string;
}

interface UserTipsData {
    data: IProps[];
}

/**
 * check if user supplied link is valid (no xss)
 *
 * @see https://github.com/cure53/DOMPurify/blob/main/src/regexp.js#L8
 */
const IS_ALLOWED_URI = /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i; // eslint-disable-line no-useless-escape

/**
 * Component to show user tips on the stories page
 *
 * @param props
 */
function UserTips(props: UserTipsData) {

    const history = useHistory()

    return (
        <div className="usertips-container">
            <div className="usertips-content">
                <Carousel interval={null}>
                    {props.data && props.data.map((item, itemKey) => (
                        <Carousel.Item key={itemKey}>
                            <Carousel.Caption>
                                <small>User Tips</small>
                                <h3 className="usertips-title">{item.attributes.title}</h3>
                                    <div>
                                        <IFrameDisplay pageName={`user_tips-${item.id}`} />
                                        <div className="usertips-main-content">
                                            <Markdown>{item.attributes.tip}</Markdown>
                                        </div>
                                        <p className="usertips-author">
                                            {item.attributes.submitter}
                                        </p>
                                    </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <button className="default-button usertips-share-button" onClick={() => history.push('/dashboard/shareyourtips')}>
                Share Your Tips
            </button>
        </div>
    );
}

export default UserTips;
