import React, { useState, useEffect } from 'react';
import { mdiHelpCircle, mdiCloseCircle, mdiLinkVariant, mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import { Form } from 'react-bootstrap';
import { IStory } from '../../../interfaces/story';
import TooltipHelp from '../../notifications/tooltiphelp.component';
import Switch from "react-switch"
import instance from "../../../api/api"
import config from '../../../config/config';
import { INotification, NotificationType } from '../../../interfaces/notification';
import Notification, { notificationImages } from '../../notifications/notification.component';
import { useTranslation } from "react-i18next";

// maximum number of emails
const maximumEmail = 10

interface IProps {
    setShowToolTip:Function
    showTooltip:boolean
    story:IStory
    setNotificationEmails: Function
    updateStory: Function
    modal?:boolean
    handleFinish: Function
}

const errorNotification: INotification = {
    icon: notificationImages.crying,
    title: "Warning",
    text: "Maximum reached for this email send",
    success: NotificationType.problem,
}

const failedGetNotification: INotification = {
    icon: notificationImages.crying,
    title: "Error",
    text: "We can't generate an URL right now",
    success: NotificationType.problem,
}

const successNotification: INotification = { icon:notificationImages.happiness, title:"Success", text:"", success:NotificationType.success }

export default function PrivateSharingUrl(props:IProps) {

    const [email, setEmail] = useState<string>('');
    const [emails, setEmails] = useState<string[]>([]);
    const [copied, setCopied] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');

    // only used when modal is true (~ success message). otherwise the component works with "checked".
    const [isPublic, setIsPublic] = useState<boolean>(false);

    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [ showErrorNotification, setShowErrorNotification ] = useState<boolean>(false);
    const [ showSuccessNotification, setShowSuccessNotification ] = useState<boolean>(false);    

    const { t } = useTranslation();    

    useEffect(() => {
        if(props.story.public || isPublic) {
            getPrivateUuid();
        }
    }, [props.story.public, isPublic])

    function copyHelper() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, config.modalCloseTimeout)
    }

    function updatePrivateSharing(checked:boolean) {
        if(!checked) {
            setEmail('');
            setEmails([]);
            props.setNotificationEmails([]);
        }
        if(!props.modal) {
            props.updateStory("public", checked)
        }
        setIsPublic(checked)
    }

    async function makeStoryPublic() {
        if(!isPublic) {
            return
        };

        try {
            await instance.put("/stories/" + props.story._id, {data: { public: true }})
            await instance.post(`/stories/publish`, { id: props.story._id });

            setShowSuccessNotification(true);

            setTimeout(() => {
                setShowSuccessNotification(false);                
                props.setNotificationEmails(emails);
                // don't close top success message so more shares or person adding to family can be done later on
                props.handleFinish(); //TODO: low: send: setShareModal(false);??                
            }, config.modalCloseTimeout)
        }
        catch(err:any) {
            setShowErrorNotification(true);
            console.log(`Unexpected error when making story ${props.story._id} public`)
        }
    }

    async function getPrivateUuid() {
        try {
            const response = await instance.get("/publishedstories/public/generate/" + props.story._id);
            setUrl(window.location.origin + "/story/" +  encodeURIComponent(response.data))
            props.updateStory("privateId", response.data)
        }
        catch(err:any) {
            setShowErrorNotification(true);
            setUrl("Story isn't public.")
        }
    }

    async function reGenerateUuid() {
        try {
            const response = await instance.get("/publishedstories/public/generate/" + props.story._id, {
                params: {
                    new:true
                }
            });
            setUrl(window.location.origin + "/story/" +  encodeURIComponent(response.data))
            props.updateStory("privateId", response.data)

        }
        catch(err:any) {
            setShowErrorNotification(true);
            setUrl("Story isn't public.")
        }
    }

    function addEmail(){
        if(emails.length<maximumEmail) {
            setEmails(prev => [...prev, email]);
            props.setNotificationEmails((prev:string[]) => [...prev, email]); 
            setEmail('');
        } else {
            setShowNotification(true);
        }
    }

    return(
        <div>
            <div className="switch-container">
                <div className="infos">
                    <span>{t("Share with Private Link")}</span>
                    <span onClick={() => props.setShowToolTip(true)}>
                        <Icon size={1} path={mdiHelpCircle} />
                    </span>
                </div>
                <div className="status">
                    <span className="switch-status">{props.story.public || isPublic ? t("On") : t("Off")}</span>
                    <Switch
                        className={` ${props.story.public || isPublic ? "active-gold-switch" : ""}`}
                        offColor="#17243d"
                        offHandleColor="#8B919E"
                        onColor="#006238"
                        onHandleColor="#006238"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={32}
                        onChange={(checked:boolean) => updatePrivateSharing(checked)}
                        checked={props.story.public || isPublic}
                    />
                </div>
                <TooltipHelp contextKey={'story_publish_private_sharing'}
                    show={props.showTooltip}
                    clear={() => props.setShowToolTip(false)}
                />
            </div>
            {props.story.public || isPublic ? (
                <div className="private-sharing-url-container">
                    <div className="input-container">
                        <Form onSubmit={(evt:any) => { evt.preventDefault(); addEmail()}}>
                            <Form.Group className="group">
                                <input type="email" className=""  placeholder="Email" onChange={(evt) => setEmail(evt.target.value)} value={email}/>
                                <Form.Label className="top-label">Email</Form.Label>
                            </Form.Group>
                            <button disabled={!email.length} className="add-button" type="submit">Add</button>
                        </Form>
                        <p className="info-text">{t("Automatically emails a link to your story")}</p>
                        {showNotification ? <Notification data={errorNotification} close={() => setShowNotification(false)} /> : null}                        
                    </div>
                    {emails.length ? <div className="added-emails">
                        {emails.map(x => {
                            return(
                                <div className="email">
                                    <p>{x}</p>
                                    <div className="icon-container" onClick={() => {setEmails(prev => prev.filter(email => email !== x)); props.setNotificationEmails((prev:string[]) => prev.filter(email => email !== x))}}>
                                        <Icon path={mdiCloseCircle} size={0.8}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null}
                    {/* <p className="info-text">Sharing link(tap to copy link):</p> */}
                    <span className="icon-container">
                        <Icon size={0.8} path={mdiLinkVariant} />
                    </span>
                    <p className="link-type" onClick={() => {navigator.clipboard.writeText(url); copyHelper()}}>{url}</p>
                    {copied && <p className="copy">{t("Copied!")}</p>}

                    <span className="icon-container refresh" onClick={reGenerateUuid}>
                        <Icon size={0.8} path={mdiRefresh} />
                    </span>
                    {showErrorNotification ? <Notification data={failedGetNotification} close={() => setShowErrorNotification(false)} /> : null}

                    <div className="buttons">                    
                        {props.modal ? 
                            <button className='default-button' onClick={() => makeStoryPublic()}>{t("Save and Share")}</button> : null}
                        {props.modal ? 
                            <button className='link-button' onClick={() => props.handleFinish()}>{t("Cancel")}</button> : null}
                    </div>
                    
                    {showSuccessNotification ? <Notification data={successNotification} close={() => setShowSuccessNotification(false)} /> : null}                    
                </div>
            ) : null}
        </div>
    );
}