import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react'
import { mdiClose, mdiMagnify, mdiSortVariant, mdiCheck, mdiMenuDown, mdiFilterOutline, mdiCheckCircle } from '@mdi/js';
import { Form } from "react-bootstrap"

interface IProps {
    setSort: Function
    sort: sortOptions
    search: string
    setSearch: Function
    setShowFilters: Function
    showFilters: boolean
    filtersActive: boolean
    removeAllfilters: Function
}

export enum sortOptions {
    PUBLISHED_DATE="Creation date", 
    //PUBLISHED_DATE="Published date", // new feature to come? MLP2-994
    OLDEST="Oldest story date",
    NEWEST="Newest story date",
    COMMENT="Most commented",
    RECENTLY="Recently read",
}

export default function StoryFiltersBar(props:IProps) {

    const [ showSearch, setShowSearch] = useState(false);
    //sort
    const [showSort, setShowSort] = useState<boolean>(false)

    function updateSort(filter:sortOptions) {
        props.setSort(filter);
        setShowSort(false)
    }

    const wrapperRef = useRef<HTMLDivElement>(null);
    const upperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);
    function handleClickOutside(event:any) {
        if(wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setShowSort(false);
        }
    };

    return(
        <div className='story-filters-bar'>
            {!props.showFilters ? <div className="filters">
                <div className="left-filters">
                    <div ref={upperRef} className="sort-options" onClick={() => setShowSort(!showSort)}>
                        <Icon className="filter-icon" size={1} path={ mdiSortVariant } />
                        <p>{props.sort}</p>
                        <Icon className="selected-option" size={0.8} path={ mdiMenuDown } />
                    </div>
                    {showSort && 
                    <div ref={wrapperRef} className="options">
                        <div className={"option" + (props.sort === sortOptions.PUBLISHED_DATE ? " selected" : "")} onClick={() => updateSort(sortOptions.PUBLISHED_DATE)}>
                            <p>{sortOptions.PUBLISHED_DATE}</p>
                            {props.sort === sortOptions.PUBLISHED_DATE && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                        </div>
                        <div className={"option" + (props.sort === sortOptions.OLDEST ? " selected" : "")} onClick={() => updateSort(sortOptions.OLDEST)}>
                            <p>{sortOptions.OLDEST}</p>
                            {props.sort === sortOptions.OLDEST && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                        </div>
                        <div className={"option" + (props.sort === sortOptions.NEWEST ? " selected" : "")} onClick={() => updateSort(sortOptions.NEWEST)}>
                            <p>{sortOptions.NEWEST}</p>
                            {props.sort === sortOptions.NEWEST && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                        </div>
                        {/* <div className={"option" + (props.sort === sortOptions.COMMENT ? " selected" : "")} onClick={() => updateSort(sortOptions.COMMENT)}>
                            <p>{sortOptions.COMMENT}</p>
                            {props.sort === sortOptions.COMMENT && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                        </div>
                        <div className={"option" + (props.sort === sortOptions.RECENTLY ? " selected" : "")} onClick={() => updateSort(sortOptions.RECENTLY)}>
                            <p>{sortOptions.RECENTLY}</p>
                            {props.sort === sortOptions.RECENTLY && <Icon className="selected-option" size={0.8} path={ mdiCheck } />}
                        </div> */}
                    </div>}
                </div>
                <div className="right-filters">
                    <div className={"filter" + (props.filtersActive ? " active" : "")} >
                        <div className="icon-container" onClick={() => props.setShowFilters()}>
                            <Icon className="status-icon" size={0.8} path={ mdiFilterOutline } />
                            {props.filtersActive ? <div className="active-additional-icon"><Icon className="close-icon" size={0.6} path={mdiCheckCircle} /></div> : null}
                            <p>Filters</p>
                        </div>
                    </div>
                    <div className={"search" + (props.search.length ? " active" : "")} >
                    <div className="icon-container">
                        <span onClick={() => setShowSearch(!showSearch)}><Icon className="status-icon" size={1} path={ mdiMagnify } /></span>
                            {props.search.length ? <div className="active-additional-icon"><Icon className="close-icon" size={0.6} path={mdiCheckCircle} /></div> : null}
                        </div>
                        {showSearch && (
                        <div className="show-search">
                            <Icon className="search-icon" size={1.1} path={ mdiMagnify } />
                            <Form.Control
                                autoFocus
                                type="text"
                                value={props.search}
                                placeholder="Type to add keyword"
                                onChange={(evt) => props.setSearch((prev:any) => {return {...prev, search:evt.target.value}})}

                                />
                            <div className="close-icon">
                                <span onClick={() => setShowSearch(!showSearch)}><Icon size={1} path={ mdiClose } /></span>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
            :
            <div className="filters filters-enabled">
                <div className="left-filters">
                    <div className="sort-options set-filter">
                        <Icon size={1} path={ mdiFilterOutline } />
                        <p>Set Filters</p>
                    </div>
                    <div className="right-filters close">
                        {props.filtersActive && <p className="remove" onClick={() => props.removeAllfilters()}>Remove all</p>}
                        <div onClick={() => props.setShowFilters()}>
                            <Icon size={1} path={ mdiClose } />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}