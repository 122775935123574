import React, { useContext, useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import instance from '../../../api/api';
import { INotification, NotificationType } from '../../../interfaces/notification';
import Notification, { notificationImages } from '../../notifications/notification.component';
import { IGroup, IUserDetails } from '../../../interfaces/authentication';
import { profileTypes } from '../../../_helper/enum/enum';
import UniqueUserListInput from '../../_helper/unique-selects/unique-user-list-input.component';
import { Context } from '../../../pages/home.page';
import TooltipHelp from '../../notifications/tooltiphelp.component';
import { IOption } from '../../../interfaces/family';
import UniqueListInput from '../../_helper/unique-list-input.component';

interface IProps {
    updateGroups: Function
    ctaText: string
    group: IGroup
    modalClose: Function
}

const successNotification: INotification = { icon:notificationImages.happiness, title:"Success", text:"Group updated successfully", success:NotificationType.success }
const errorNotification: INotification = { icon:notificationImages.crying, title:"Failed", text:"Error when updating group", success:NotificationType.problem }

export default function UpdateGroup(props: IProps) {
    const [ successors, setSuccessors ] = useState<IUserDetails[]>([]);
    const [ groupName, setGroupName ] = useState<string>('');
    const [ groupDescription, setGroupDescription ] = useState<string>('');
    const [ members, setMembers ] = useState<IUserDetails[]>([]);
    const [ options, setOptions ] = useState<IUserDetails[]>([]);

    //Tooltip
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const [ showGroupGoalTooltip, setShowGroupGoalTooltip ] = useState<boolean>(false); 

    //Notification
    const [ showNotification, setShowNotification ] = useState<boolean>(false);
    const [ showErrorNotification, setShowErrorNotification ] = useState<boolean>(false);
    const [ showCaution, setShowCaution ] = useState<boolean>(false);
    const [ caution, setCaution ] = useState<string>('');

    //User feedback
    const [ loading, setLoading ] = useState<boolean>(false)

    const loggedInUserData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;


    useEffect(() => {
        setMembers(props.group.members);
        setGroupName(props.group.name);
        setGroupDescription(props.group.description);
        setSuccessors(props.group.successors);

        return () => {
            setShowNotification(false);
            setShowErrorNotification(false);
        }
    }, [props.group])

    useEffect(() => {
        const options:IUserDetails[] = loggedInUserData.relations.filter(relation => relation.userid.profileType !== profileTypes.DECEASED).map(relation => { return relation.userid });
        setOptions(options);
    }, [loggedInUserData.relations])

    function updatePeople(selectedData:IOption) {

        setShowCaution(false);        
        if(members.filter((data:IUserDetails) => data._id === selectedData._id).length) {
            const temp = members.filter((data:IUserDetails) => data._id !== selectedData._id);
            const refactoredS = successors.filter((succ:IUserDetails) =>  succ._id !== selectedData._id)
            setSuccessors(refactoredS);
            setMembers(temp);
            setCaution("Removed members will lose access to the group's stories.");
            setShowCaution(true);
        }
        else {
            const selectedUser = options.find((x) => x._id === selectedData._id);
            if(!selectedUser) {
                return //TODO: error handling
            }
            setMembers((prev:IUserDetails[]) => { return [...prev, selectedUser]});
            setCaution("New members will gain access to the group's historical stories.");            
            setShowCaution(true);
        }
    }

    function updateGroupSuccesors(selectedData:IUserDetails) {
        if(successors.filter((data:IUserDetails) => data._id === selectedData._id).length) {
            const temp = successors.filter((data:IUserDetails) => data._id !== selectedData._id);
            setSuccessors(temp);
        }
        else {
            setSuccessors((prev:IUserDetails[]) => { return [...prev, selectedData]})
        }
    }

    async function updateGroup() {
        setLoading(true);

        const memberIds:string[] = members.map((member:IUserDetails) => {return member._id})
        const successorIds:string[] = successors.map((successor:IUserDetails) => {return successor._id})
        const groupData = {
            name:groupName,
            description:groupDescription,
            administrator:localStorage.userid, //TODO: what
            members:memberIds,
            successors:successorIds
        };

        try {
            const response = await instance.put('/group', { _id:[props.group._id], query:groupData });
            props.updateGroups(response.data);
            updateUserData({...loggedInUserData, groups:[...loggedInUserData.groups.filter(x => x._id !== response.data._id), response.data]})

            setShowNotification(true);
        }
        catch(error) {
            setShowErrorNotification(true)
        }
        setTimeout(() => {
            setLoading(false);
            props.modalClose();
        }, 3000)
    }

    function getMembers() {
        return members.map(user => { 
            if(user._id === props.group.administrator._id) {
                return {_id:user._id, text: `${user.firstname} ${user.lastname}`, img:user.avatar ? user.avatar : "", editable:false}

            }
            else {
                return {_id:user._id, text: `${user.firstname} ${user.lastname}`, img:user.avatar ? user.avatar : ""}
            }
        })
    }


    return(
        <div className="update-group-container">
            <p className="manager">Managed by <span>{props.group.administrator.firstname} {props.group.administrator.lastname}</span> </p>
            <Form.Group className="group">
                <input placeholder="Group name" value={groupName} onChange={(evt) => evt.target.value.length <= 30 ? setGroupName(evt.target.value) : null} type="text"></input>
                <label className="top-label">Group name</label>
                <p className="sub-subtitle right">{groupName.length} /30</p>
            </Form.Group>
            <Form.Group className="group goal">
                <input placeholder="Group goal" value={groupDescription} onChange={(evt) => evt.target.value.length <= 50 ? setGroupDescription(evt.target.value) : null} type="text"></input>
                <label className="top-label">Group goal</label>
                <p onClick={() => setShowGroupGoalTooltip(true)} className="tooltip-text">What's this?</p>           
                <p className="sub-subtitle right">{groupDescription.length} /50</p>
            </Form.Group>
            <Form.Group className="group">
                <UniqueListInput icon={true} placeholder={"People in group"} handleAction={updatePeople} data={getMembers()} options={options.map(user => { return {_id:user._id, text: `${user.firstname} ${user.lastname}`, img:""}})} />
            </Form.Group>
            {showCaution ? <Notification data={{ icon:notificationImages.noimage, title:"Caution", text: caution, success:NotificationType.info }} close={() => setShowCaution(false)} /> : null}
            <Form.Group className="group successors">
                <UniqueUserListInput placeholder={"Group Successor(s)"} handleAction={updateGroupSuccesors} data={successors} options={members.filter(x => x._id !== localStorage.userid && x.profileType !== profileTypes.CHILD)} />
                <p onClick={() => setShowTooltip(true)} className="tooltip-text">What's this?</p>
            </Form.Group>
            {showNotification ? <Notification data={successNotification} close={() => setShowNotification(false)} /> : null}
            {showErrorNotification ? <Notification data={errorNotification} close={() => setShowErrorNotification(false)} /> : null}
            <button disabled={!(groupName.length && groupDescription.length && members.length)} onClick={() => updateGroup()} className="default-button">{!loading ? "Save changes" : <div className="button-loader"><Spinner animation="border" variant="#fff" /></div>}</button>
            <TooltipHelp contextKey={'profile_family_groups_successors'} show={showTooltip} clear={() => setShowTooltip(false)} />
            <TooltipHelp contextKey={'profile_family_groups_goal'} show={showGroupGoalTooltip} clear={() => setShowGroupGoalTooltip(false)} />            
        </div>
    )
}