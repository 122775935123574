import React, { useEffect, useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import { fileTypes, IBlock } from '../../interfaces/story';
import VideoPlayer from '../_helper/video-player.component';

interface IProps {
    show:boolean
    handleClose:Function
    blocks:IBlock[]
    index:number
}

export default function StoryImagesCarousel(props:IProps) {

    const [ index, setIndex ] = useState<number>(props.index);

    function handleSelect(selectedIndex:number) {
        setIndex(selectedIndex);
    };

    function setupCarousel() {
        return props.blocks.map((block:IBlock, index:number) => {
            if(block.media) {
                if(block.media.fileType === fileTypes.VIDEO || block.media.fileType === fileTypes.IMAGE) {

                    return(
                        <Carousel.Item key={block._id} className="item">
                            {(block.media.fileType !== fileTypes.VIDEO ) ? 
                                <img src={block.media.data} alt="uploaded" className="story-block-image" /> 
                                :
                                <VideoPlayer url={block.media.data}/>

                            }
                        </Carousel.Item>
                    );
                }
            }   
        })
    }

    return(
        <Modal dialogClassName="detailed-media-modal" show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Default Title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel activeIndex={index} interval={null} onSelect={handleSelect} slide={true} touch={true} indicators={false}>
                    {setupCarousel()}
                </Carousel>
            </Modal.Body>
        </Modal>
    )
}