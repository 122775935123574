import moment from "moment"
import { Modal } from "react-bootstrap"
import { useHistory } from "react-router"
import config from "../../config/config"
import { IUserDetails } from "../../interfaces/authentication"
import { IOption } from "../../interfaces/family"
import { IStory } from "../../interfaces/story"
import { storyPageMenu } from "../../pages/dashboard/stories.page"
import { dateFormat } from "../../_helper/enum/enum"
import { getStoryDate } from "../../_helper/getStoryDate"

export interface IProps {
    story: IStory,
    text?:() => JSX.Element,
    clear: () => void,
    show: boolean
}

function Infocard(props: IProps) {

    const history = useHistory();
    
    return (
        <Modal className="tooltip-container infocard-container" show={props.show} onHide={props.clear}>
            <Modal.Header className="tooltip-header infocard-header" closeButton>
                <Modal.Title className="title">Info Card</Modal.Title>
            </Modal.Header>
            <Modal.Body className="tooltip-content infocard-content">
                <div className="body">
                    <div className="infocard-text-section">
                        {props.story.date && props.story.createdAt && (
                            <div>
                                <p>Story date</p>
                                <p>
                                    {getStoryDate(props.story.date.startDate)} {props.story.date.endDate && <span> - {getStoryDate(props.story.date.endDate)}</span>}
                                </p>
                            </div>
                        )}
                        {props.story.createdAt && (
                            <div>
                                <p>Created</p>
                                <p>
                                    {moment(props.story.createdAt).format(dateFormat.SHORT)}
                                </p>
                            </div>
                        )}
                        {props.story.storyTags.length ? ( 
                            <div className="story-tags">                                 
                                <p>Story Tag(s)</p>
                                <p>
                                {props.story.storyTags.map(x => {
                                    return(
                                        <a className="underlined-link-button" onClick={() => history.push({pathname: '/dashboard/stories/2', state: { filters: { story: {storyTags: [(config.storyTags.find(data => data._id === x) as IOption)]} }} })}>
                                            {((config.storyTags.find(data => data._id === x) as IOption).text)}
                                        </a>
                                    )                                  
                                })}
                                </p>
                            </div>                                 
                        ) : null}
                        <div>                                       
                            <p>Featured Profile</p>
                            <p>
                                <a className="underlined-link-button" href={`/dashboard/profile/${(props.story.featured as IUserDetails)._id}/1`}>
                                    {`${(props.story.featured as IUserDetails).firstname} ${(props.story.featured as IUserDetails).lastname}`}
                                </a>
                            </p>     
                        </div>
                        {(props.story.author as IUserDetails)._id !== (props.story.featured as IUserDetails)._id ? (                    
                        <div>
                            <p>Author</p>
                            <p>
                                <a className="underlined-link-button" href={`/dashboard/profile/${(props.story.author as IUserDetails)._id}/1`}>
                                {`${(props.story.author as IUserDetails).firstname} ${(props.story.author as IUserDetails).lastname}`}
                                </a>
                            </p>
                        </div>
                        ) : null}                    
                            {props.story.coAuthors.length ? (
                                <div>                            
                                    <p>Co-author(s)</p>
                                    <p>
                                        {(props.story.coAuthors as IUserDetails[]).map((coauthor, index) => {
                                            return( 
                                                <div>
                                                    <a className="underlined-link-button" href={`/dashboard/profile/${(coauthor as IUserDetails)._id}/1`}>
                                                        <span>{` ${coauthor.firstname} ${coauthor.lastname}`}</span>
                                                    </a>
                                                    <span>{`${index === props.story.coAuthors.length - 1 ? "" : ", "}`}</span>
                                                </div>
                                            )                                  
                                        })}
                                    </p>
                                </div>                                
                            ) : null}
                            {props.story.participants.length ? (
                            <div>                                                               
                                    <p>Participant(s)</p>
                                    <p>
                                        {(props.story.participants as IUserDetails[]).map((participant, index) => {
                                            return (
                                                <div>
                                                    <a className="underlined-link-button" href={`/dashboard/profile/${(participant as IUserDetails)._id}/1`}>                                        
                                                        <span>{` ${participant.firstname} ${participant.lastname}`}</span>
                                                    </a>
                                                    <span>{`${index === props.story.participants.length - 1 ? "" : ", "}`}</span>
                                                </div>  
                                            )                             
                                        })}
                                    </p>
                            </div>
                            ) : null}
                        </div>
                        {props.story.sLocation.lat && props.story.sLocation.lng ?
                        <div className="displayed-google-map">
                            <iframe 
                                title="google-maps" 
                                width="100%"
                                height="350"
                                loading="lazy"
                                src={`https://maps.google.com/maps?q=${props.story.sLocation.lat},${props.story.sLocation.lng}&z=15&output=embed`} />
                        </div>
                        : null}
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default Infocard
