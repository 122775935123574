import React, { useState, useContext, useEffect } from 'react';
import Switch from 'react-switch';
import instance from '../../api/api';
import { IGroup, IUserDetails } from '../../interfaces/authentication';
import default_avatar from "../../assets/images/default-avatar.jpg";
import { INotification, NotificationType } from '../../interfaces/notification';
import Notification, { notificationImages } from '../notifications/notification.component';
import RelationshipPicker from './relationship-picker.component';
import { IOption, IPendingRelation } from '../../interfaces/family';
import { Context } from '../../pages/home.page';
import config from '../../config/config';
import { alreadyConnectedNotificationData } from './add-to-family.component';
import { useTranslation } from "react-i18next";
import UniqueUserListInput from '../_helper/unique-selects/unique-user-list-input.component';
import UniqueListInput from '../_helper/unique-list-input.component';
import TooltipHelp from '../notifications/tooltiphelp.component';

/**
 * Local interface for input properties.
 */
interface IProps {
    user: IUserDetails
    familymembers: IOption[]
    handleUpdateConnectionsFromProfile: () => void
    handleClose: (addMore: boolean) => void
    groups: IGroup[]
}

/**
 * 
 * @param props : 
 * @returns void
 */
function ExistingProfile(props: IProps) {

    const [relation, setRelation] = useState<string>("-");
    const [relationError, setRelationError] = useState<boolean>(false);
    //const [connectToFamily, setConnectToFamily] = useState<boolean>(false);
    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem });
    //const [connectToIndividuals, setConnectToIndividuals] = useState<IOption[]>([]);

    //const [showAllFamMember, setShowAllFamMember] = useState(false)
    //const maxLengthOfMembersLess = 180

    //Groups
    //const [connectToGroups, setConnectToGroups] = useState<IGroup[]>([]);
    //const [addToGroupIds, setAddToGroupIds] = useState<string[]>([]);

    const [ selectedFamily, setSelectedFamily ] = useState<IOption[]>([]);

    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);

    const userData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;    

    const [clicked, setClicked] = useState<boolean>(false);

    const { t } = useTranslation();

    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    }
/*
    useEffect(() => {
        if(props.familymembers.length) {
            setConnectToFamily(true);
        }
    }, [props.familymembers])
*/
    async function sendRelationRequest(addMore: boolean) {

        if (relation !== "") {

            setClicked(true);

            let suggestions: string[] = [];

            let relationData : IPendingRelation = {
                fromEmail: "",
                from: "",
                toEmail: props.user.email,
                relationType: relation,
                suggestions: suggestions,
            }

            // via registration process: fromEmail, anywhere else: from (Id)
            userData.email.length ? relationData.fromEmail = userData.email : relationData.from = localStorage.userid;

//            if (connectToFamily) {
                relationData.suggestions = selectedFamily.map(member => member._id);
//            }

/*
            //Add suggestions by group
            if (connectToGroups.length) {
                connectToGroups.forEach((group: IGroup) => {
                    relationData.suggestions = [...relationData.suggestions, ...group.members.map(x => x._id)]
                })
            }

            if (connectToIndividuals.length) {
                relationData.suggestions = [...relationData.suggestions, ...connectToIndividuals.map(member => member._id)];
            }
*/
            //Remove duplicates
            const uniques = new Set(relationData.suggestions)
            relationData.suggestions = Array.from(uniques.values())

            try {
                await instance.post('/relation/pending', relationData);
                setNotification({
                    icon: notificationImages.happiness, title: t("Success"), text: t("Contact request sent"), success: NotificationType.success
                })

                // update Context if available (it is not via registration process)
                if(userData._id){
                    let responseUserUpdated = await instance.get('/user/' + userData._id );
                    updateUserData(responseUserUpdated.data);
                } else {
                    props.handleUpdateConnectionsFromProfile();
                }

                setTimeout(() => {
                    //setClicked(false); // todo: low: clearTimeout in useEffect unmount
                    props.handleClose(addMore);
                }, config.modalCloseTimeout);
            }
            catch (error:any) {

                if(error.response.status === 409) {
                    let localAlreadyConnectedNotificationData = {...alreadyConnectedNotificationData, closeBtnOff: true};
                    setNotification(localAlreadyConnectedNotificationData);
                } else {
                    setNotification({
                        icon: notificationImages.crying, title: t("Failed"), text: t("Contact request was not sent"), success: NotificationType.problem, closeBtnOff: true
                    });
                    console.log('Failed to create new Invitation, Error ' + error, props)                    
                }
            }
        } else {
            setRelationError(true);
            document.getElementById("relation-subtitle")?.scrollIntoView({behavior: "smooth"});
        }
    }
/*
    function updateIndividuals(selectedData: IOption) {
        if (connectToIndividuals.filter((data: IOption) => data._id === selectedData._id).length) {
            const temp = connectToIndividuals.filter((data: IOption) => data._id !== selectedData._id);
            setConnectToIndividuals(temp);
        }
        else {
            setConnectToIndividuals((prev: IOption[]) => { return [...prev, selectedData] })
        }
    }
/*
    function groupChange(id: string) {
        const selectedGroup = props.groups.find(x => x._id === id);
        if (selectedGroup) {
            setConnectToGroups((prev: IGroup[]) => [...prev, selectedGroup]);
        }
    }

    function addToGroup(id: string) {
        setAddToGroupIds(addToGroupIds.includes(id) ? addToGroupIds.filter(x => x !== id) : [...addToGroupIds, id])
    }
*/
    function pickRelation(relation:string) {
//        relation === "friend" ? setConnectToFamily(false) : setConnectToFamily(true);
        setRelation(relation);
        setRelationError(false);
    }

    function updateSelectedFamily(selectedData:IOption) {
        if(selectedFamily.filter((data:IOption) => data._id === selectedData._id).length) {
            const temp = selectedFamily.filter((data:IOption) => data._id !== selectedData._id);
            setSelectedFamily(temp);
        }
        else {
            setSelectedFamily((prev:IOption[]) => { return [...prev, selectedData]})
        }
    }

    return (
        <div className="existing-profile" >
            <div className="profile-details">
                <img src={props.user.avatar ? props.user.avatar : default_avatar} alt="avatar" className="user-picture" />
                <p className="name title">{props.user.firstname} {props.user.lastname}</p>
            </div>
            {/*}
            <p className="subtitle" id='relation-subtitle'>{props.familymembers.length ? "1. " : " "}{t("Relationship")}</p>
            <p className="top-text">{t("They are your...")}</p>
            <RelationshipPicker handleAction={pickRelation} relation={relation} relationError={relationError}/>
            */}
            {props.familymembers.length ?
            <div>            
                <p className="subtitle">{t("connect to family")}</p>
                <p className="top-text">{t("Who do you think they'd like to connect with?")}</p>
                {/*<div className={`weekly-update switch-container ${connectToFamily ? "active-gold-switch" : ""}`}>
                    <span>{t("Connect to all my family")}</span>
                    <Switch className="switch"
                        offColor="#17243d"
                        offHandleColor="#8B919E"
                        onColor="#006238"
                        onHandleColor="#006238"
                        disabled={!props.familymembers.length}
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={32}
                        onChange={(checked: boolean) => setConnectToFamily(checked)}
                        checked={connectToFamily} />
                    <p className="switch-status">{connectToFamily ? "On" : "Off"}</p>
                </div>*/}
            </div>                
            : null}            
            {//connectToFamily && 
            props.familymembers.length ?
                <div className="family-member-list-to-connect">
                    <div className="group">                    
                        <UniqueListInput iconRef={''} icon={false} placeholder={"Suggest people"} handleAction={updateSelectedFamily} data={selectedFamily} options={props.familymembers}/>
                    </div>
                    <p className="tooltip-text" onClick={() => setShowTooltip(true) }>{t("What's this?")}</p>
                    <TooltipHelp contextKey={'profile_family_suggest_family'} show={showTooltip} clear={() => setShowTooltip(false)} />
                    {/*<p className="connect-info">{t("Users will be notified about their new family member")}</p>*/}
                </div>
                // <div className="group-and-individual-connections">
                //     <div className="connect-item">
                //         <div className="icon-wrapper"><Icon size={1} path={mdiAccountGroup} /></div>
                //         <p className="subtitle">Optionally connect using groups</p>
                //         <p>Connect them to group members</p>
                //         <Form.Select aria-label="" value="" onChange={(evt:any) => groupChange(evt.currentTarget.value)}>
                //             <option value="">Select group to add</option>
                //             {props.groups.filter((group) => !connectToGroups.map(x => x._id).includes(group._id)).map((group: IGroup) => {
                //                 return <option key={group._id} value={group._id}>{group.name}</option>
                //             })}
                //         </Form.Select>
                //         <div className="selectedGroups">
                //             {connectToGroups.map((group: IGroup) => {
                //                 return (
                //                     <div key="group" className="group">
                //                         <div className="group-item">
                //                             <div className="icon">
                //                                 <Icon size={0.7} path={mdiAccountGroup} />
                //                             </div>
                //                             <p>{group.name} </p>
                //                             <div className="remove-icon">
                //                                 <span onClick={() => setConnectToGroups([])} ><Icon size={1} path={mdiCloseCircle} /></span>
                //                             </div>
                //                         </div>
                //                         <p className="name-list">{group.members.map(member => member.firstname + ' ' + member.lastname).join(", ")}</p>
                //                         <div className="checkbox-container">
                //                             <input id="checkbox" type="checkbox" checked={addToGroupIds.includes(group._id)} onChange={() => addToGroup(group._id)} />
                //                             <label htmlFor="checkbox">Add invitee to group</label>
                //                         </div>
                //                     </div>
                //                 );
                //             })}
                //         </div>
                //     </div>
                //     <div className="connect-item">
                //         <div className="icon-wrapper"><Icon size={1} path={mdiHumanHandsup} /></div>
                //         <p className="subtitle">Optionally connect individuals</p>
                //         <p className="top-text">Connect them to specific people</p>
                //         <UniqueListInput iconRef={''} icon={false} placeholder={"Select indivisual(s)"} handleAction={updateIndividuals} data={connectToIndividuals} options={props.familymembers} />
                //     </div>
                // </div>
                // : connectToFamily && props.familymembers.length ?

                : <></> }           
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()} />
                : null}
            <div className="actions">
                <button className="default-button finish" disabled={clicked} onClick={() => sendRelationRequest(false)}>{t("Finish")}</button>
                <button className="link-button add" disabled={clicked} onClick={() => sendRelationRequest(true)}>{t("Finish & Add more")}</button>
            </div>
        </div>
    );
};

export default ExistingProfile;