import React, { useContext, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiAccountGroup, mdiChevronDown, mdiChevronUp, mdiCloseCircle } from "@mdi/js"
import { Context } from "../../pages/home.page"
import { IGroup, IUserDetails } from "../../interfaces/authentication"
import { profileTypes } from "../../_helper/enum/enum"
import UniqueUserListInput from "../_helper/unique-selects/unique-user-list-input.component"
import { IOption } from "../../interfaces/family"
import CreateGroup from "../profile/groups/create-group.component"
import UniqueSimpleSelect from "../_helper/unique-simple-select.component"
import UpdateGroup from "../profile/groups/update-group.component"
import { IStory } from "../../interfaces/story"

enum action {
    CREATE_NEW_GROUP = 0,
    ADD_EXISTING_GROUP = 1,
    ADD_EXTRA_INDIVIDUALS = 2,
}

interface IProps {
    automaticAccesUsers: IOption[]
    setShowIndividualTT: Function
    updateStory: Function
    story: IStory
}

function PublishStoryGroup(props:IProps) {

    const [activeAction, setActiveAction] = useState<action | null>(null);
    const [avalibleGroups, setAvalibleGroups] = useState<IGroup[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);

    const [ avalibleIndividuals, setAvalibleIndividuals ] = useState<IUserDetails[]>([]);
    const [ individuals, setIndividuals ] = useState<IUserDetails[]>([]);
    const [ individualGroup, setIndividualGroup ] = useState<boolean>(false);
    const [ individualsAdded, setIndividualsAdded ] = useState<IUserDetails[]>([]);

    //automatic access
    const [ automaticAccess, setAutomaticAccess ] = useState<boolean>(false);
    const [ automaticAccessUsers, setAutomaticAccessUsers ] = useState<IOption[]>([]);

    //Group edit
    const [ selectedGroup, setSelectedGroup ] = useState<IGroup | boolean>(false);

    const userData = useContext(Context).user;

    useEffect(() => {
        if(userData._id.length) {
            setIndividualOptions();
            setAvalibleGroups(userData.groups)
        }

        if(props.story._id.length) {
            if(props.story.accessGroups) {
                const groupIds = props.story.accessGroups.map(x => (x as IGroup)._id)
                const groups = userData.groups.filter(x => groupIds.includes((x as IGroup)._id));
                setSelectedGroups(groups);
            }   
            if(props.story.accessIndividuals.length) {
                const individuals = userData.relations.filter(relation => (props.story.accessIndividuals as string[]).includes((relation.userid as IUserDetails)._id)).map(x => x.userid);
                setIndividualsAdded(individuals);
                setIndividualGroup(true);
            }
        }
    }, [])

    useEffect(() => {
        let modifiedGroups:boolean = true;
        console.log('hello')
        if(props.story.accessGroups.length) {
            const storyGroupIds = (props.story.accessGroups as IGroup[]).map(x => x._id);
            const selectedGroupsIds = selectedGroups.map((group) => group._id)

            //check remvoed groups 
            if(storyGroupIds.length && !selectedGroups.length) {
                modifiedGroups = true;
            }
            else {
                let removedGroupsFromStory = selectedGroupsIds.filter((group_id) => !storyGroupIds.includes(group_id)) 
                if(!removedGroupsFromStory.length) {
                    modifiedGroups = false;
                }
            }

            //check added groups
            if(selectedGroups.length) {
                let newGroupsInSelected = storyGroupIds.filter((group) => !selectedGroupsIds.includes(group));
                if(!newGroupsInSelected.length) {
                    modifiedGroups = false;
                }
            }
        }

        if(modifiedGroups) {
            const access:IOption[] = [];

            let allMemberIds = [...selectedGroups.map(x => x.members).flat().map(x => x._id)];

            if(individualsAdded) {
                allMemberIds = [...allMemberIds, ...individualsAdded.map(x => x._id)];
            }
            
            props.automaticAccesUsers.forEach((user:IOption) => {
                if(!allMemberIds.includes(user._id)) {
                    access.push(user);
                }
            })

            if(access.length) {
                setAutomaticAccess(true);
                setAutomaticAccessUsers(access);
            }
            else {
                setAutomaticAccess(false);
                setAutomaticAccessUsers([]);
            }

            props.updateStory("accessGroups", selectedGroups);
        }
        else {
            setAutomaticAccess(false);
            setAutomaticAccessUsers([]);
        }

    }, [ selectedGroups, props.automaticAccesUsers, individualGroup,  individualsAdded]);

    function setIndividualOptions() {
        const userRelationUsers = userData.relations.map(relation => relation.userid);
        const alive = userRelationUsers.filter((user) => user.profileType !== profileTypes.DECEASED);
        setAvalibleIndividuals(alive);
    }

    function removeGroup(id:string) {
        setSelectedGroups(selectedGroups.filter(x => x._id !== id));
    }

    function groupChange(id:string) {
        const selectedGroup = avalibleGroups.find(x => x._id === id);
        if(selectedGroup) {
            setSelectedGroups((prev:IGroup[]) => [...prev, selectedGroup]);
        }
    }

    function updateIndividuals(selectedData:IUserDetails) {
        if (individuals.map(x => x._id).includes(selectedData._id)) {
            const temp = individuals.filter((data: IUserDetails) => selectedData._id !== data._id)
            setIndividuals(temp)
        } else {
            setIndividuals([...individuals, selectedData])
        }
    }

    function addNewGroup(group: IGroup) {
        setSelectedGroups([...selectedGroups, group]);
        props.updateStory("accessGroups", selectedGroups);
        setActiveAction(null);
    }

    function updateGroup(group: IGroup) {
        setTimeout(() => {
            setSelectedGroup(false);
            if(!selectedGroups.find(x => x._id === group._id)) {
                setSelectedGroups(prev => [...prev, group])
            }
            else {
                const groupsWithout = selectedGroups.filter(x => x._id !== group._id)
                setSelectedGroups([...groupsWithout, group])
            }
            setActiveAction(null);
        }, 1000)
    }

    function handleEditSelect(groupId:string) {
        const groupToEdit = userData.groups.find(x => x._id === groupId);

        if(!groupToEdit) {
            return
        }

        setSelectedGroup(groupToEdit);
    }

    function updateIndividualGroup(value:boolean) {
        if(value) {
            props.updateStory("accessIndividuals", individuals);
            setIndividualGroup(true);
        }
        else {
            props.updateStory("accessIndividuals", []);
            setIndividuals([]);
            setIndividualGroup(false);
        }
    }

    function updateIndividualsAndAutomaticAccess() {
        updateIndividualGroup(true);
        setIndividualsAdded(individuals);
        setActiveAction(null);
    }

    function filterAvalibleIndividuals() {
        if(selectedGroups.length) {
            const usersInGroups = selectedGroups.map(x => x.members.map(x => x._id)).flat();
            return avalibleIndividuals.filter((individual) => !usersInGroups.includes(individual._id))
        }
        else {
            return avalibleIndividuals
        }
    }

    return (
        <div className="group-selection">
            <UniqueSimpleSelect handleAction={(id:any) => groupChange(id)} placeholder="Select group to add" selected="" options={avalibleGroups.filter((group) => !selectedGroups.map(x => x._id).includes(group._id)).map(x => { return {key:x._id, value:x.name}})}/>
                {selectedGroups.map((group:IGroup) => {
                    return(
                        <div className="access-to-friends-container">
                            <div key="group" className="group">
                                <div className="group-item">
                                    <div className="icon">
                                        <Icon size={0.7} path={ mdiAccountGroup } />
                                    </div>
                                    <p>{group.name} </p>
                                    <div className="remove-icon">
                                        <span onClick={() => removeGroup(group._id)} ><Icon size={0.9} path={ mdiCloseCircle } /></span>
                                    </div>
                                </div>
                                <p className="name-list">{group.members.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(member => member.firstname + ' ' + member.lastname).join(", ")}</p>
                            </div>
                        </div>
                    );
                })}
                {individualGroup ?
                    <div className="access-to-friends-container">
                        <div key="group" className="group">
                            <div className="group-item">
                                <div className="icon">
                                    <Icon size={0.7} path={ mdiAccountGroup } />
                                </div>
                                <p>Extra Individual(s)</p>
                                <div className="remove-icon">
                                    <span onClick={() => {setIndividuals([]); updateIndividualGroup(false); setIndividualsAdded([]); setIndividualOptions();}} ><Icon size={0.9} path={ mdiCloseCircle } /></span>
                                </div>
                            </div>
                            <p className="name-list">{individualsAdded.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(member => member.firstname + ' ' + member.lastname).join(", ")}</p>
                        </div>
                    </div>
                : null}
                {automaticAccess ? 
                    <div>
                        <div className="access-to-friends-container automatic">
                            <div key="group" className="group">
                                <div className="group-item">
                                    <div className="icon">
                                        <Icon size={0.7} path={ mdiAccountGroup } />
                                    </div>
                                    <p>Access due to Story Role</p>
                                </div>
                                <p className="name-list">{automaticAccessUsers.sort((a, b) => a.text.localeCompare(b.text)).map(member => member.text).join(", ")}</p>
                            </div>
                        </div>
                        <p className="tooltip-text" onClick={() => props.setShowIndividualTT(true)}>What's this?</p>
                    </div>
                :null}
            <div className="missing-people">
                <p className="title">Missing People?</p>
                <div className={`box-container ${activeAction === action.CREATE_NEW_GROUP ? "active" : ""}`}>
                    <div className="title-wrapper" onClick={() => setActiveAction(activeAction === action.CREATE_NEW_GROUP ? null : action.CREATE_NEW_GROUP)}>
                        <p className="link-button action-title">Create new group</p>
                        <Icon size={1} path={activeAction === action.CREATE_NEW_GROUP ? mdiChevronUp : mdiChevronDown} />
                    </div>
                    {activeAction === action.CREATE_NEW_GROUP ? (
                        <CreateGroup close={() => null} ctaText={"Create & Give Access"} updateGroups={addNewGroup}/>
                    ) : null}
                    <div className="line"></div>
                </div>

                <div className={`box-container ${activeAction === action.ADD_EXISTING_GROUP ? "active" : ""}`} onClick={() => console.log("edit existing group")}>
                    <div className="title-wrapper" onClick={() => setActiveAction(activeAction === action.ADD_EXISTING_GROUP ? null : action.ADD_EXISTING_GROUP)}>
                        <p className="link-button action-title">Edit existing group</p>
                        <Icon size={1} path={activeAction === action.ADD_EXISTING_GROUP ? mdiChevronUp : mdiChevronDown} />
                    </div>

                    {activeAction === action.ADD_EXISTING_GROUP ? (
                        <div className="group-edit-container">
                            {selectedGroup ?
                            <div>
                                <UpdateGroup modalClose={() => false} updateGroups={updateGroup} ctaText='Update Group' group={(selectedGroup as IGroup)}/>
                                <button className="light-button" onClick={() => {setSelectedGroup(false)}}>Cancel</button>
                            </div>
                            :<div>
                                <p className="description">You can edit groups you manage</p>
                                <UniqueSimpleSelect handleAction={handleEditSelect} placeholder="Select Group to Edit" selected={""} options={userData.groups.filter(x => x.administrator._id === userData._id).map(group => {return {key:group._id, value:group.name}})}/>
                            </div>}
                        </div>
                    ) : null}
                    <div className="line"></div>
                </div>
                <div className={`box-container ${activeAction === action.ADD_EXTRA_INDIVIDUALS ? "active" : ""}`} onClick={() => console.log("add extra individuals")}>
                    <div className="title-wrapper" onClick={() => setActiveAction(activeAction === action.ADD_EXTRA_INDIVIDUALS ? null : action.ADD_EXTRA_INDIVIDUALS)}>
                        <p className="link-button action-title">Add extra individual(s)</p>
                        <Icon size={1} path={activeAction === action.ADD_EXTRA_INDIVIDUALS ? mdiChevronUp : mdiChevronDown} />
                    </div>
                    {activeAction === action.ADD_EXTRA_INDIVIDUALS ? (
                        <div className="extra-individuals-container">
                            <p className="description">Give access to individuals, in addition to those listed in the group(s) above.</p>
                            <UniqueUserListInput
                                handleAction={(selectedData: IUserDetails) => updateIndividuals(selectedData)}
                                data={individuals}
                                options={filterAvalibleIndividuals()}
                                placeholder="Select individual(s)"
                            />
                            <button disabled={!(individuals.length && selectedGroups.length)} onClick={() => updateIndividualsAndAutomaticAccess()} className="default-button">Add individual(s)</button>
                            <button onClick={() => setActiveAction(null)} className="cancel-button">Cancel</button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default PublishStoryGroup
