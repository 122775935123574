import { useCallback, useState, Dispatch, useContext, useEffect } from "react";
import { INotification, NotificationType } from "../../interfaces/notification";
import Notification, { notificationImages } from "../../components/notifications/notification.component";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/actionCreators";
import { Form } from 'react-bootstrap';
import { Context } from '../../pages/home.page';
import * as EmailValidator from 'email-validator';
import { useHistory } from "react-router"
import instance from "../../api/api";

interface IProps {
}

export default function ContactUs(props: IProps) {

    const history = useHistory();    
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);
    setPageName('Contact Us');    

    const loggedInUserData = useContext(Context).user;    

    const [ data, setData ] = useState<string>('');
    const [ name, setName ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');

    useEffect(() => {
        setName(`${loggedInUserData.firstname} ${loggedInUserData.lastname}`);
        setEmail(loggedInUserData.email);
    }, [])    

    //notifications
    const [ notification, setNotification ] = useState<INotification>({icon:"", title:"", text:"", success:NotificationType.problem});    

    async function send(){
        
        //Email validation
        if(!EmailValidator.validate(email)) {
            setNotification({...notification, icon:notificationImages.crying, title:"Error", text:"Invalid e-mail format."});
            return
        }
        
        try {

            await instance.post('/email', {data: data, type: "Contact Us", param1: email, param2: name,});

            setNotification({
                icon:notificationImages.happiness, title:"Success", text:"Message sent successfully", success:NotificationType.success
            })
            setData("");
            setName("");
            setEmail("");
    
            setTimeout(() => {
                history.push(`/dashboard/stories/1`)
            }, 3000)

        }
        catch(error) {
            setNotification({
                icon:notificationImages.crying, title:"Failed", text:"Message not sent", success:NotificationType.problem
            })

            console.log('Failed to send message, Error ' + error)
        }

    }

    function resetNotification() {
        setNotification({icon:"", title:"", text:"", success:NotificationType.problem});
    }    

    return(
        <div className="s-form-container">
            <p className="subtitle">Add Message </p>           
            <div className="inputs">
                <Form.Group className="group">
                    <input placeholder="Name" value={name} onChange={(evt) => evt.target.value.length <= 300 ? setName(evt.target.value) : false} type="text"></input>
                    <label className="top-label">Name</label>
                </Form.Group>
                <Form.Group className="group">
                    <input placeholder="Email" value={email} onChange={(evt) => evt.target.value.length <= 300 ? setEmail(evt.target.value) : false} type="text"></input>
                    <label className="top-label">Email</label>
                </Form.Group>                              
                <Form.Group className="group">
                    <textarea placeholder="Add Message" rows={5} value={data} onChange={(evt) => evt.target.value.length <= 3000 ? setData(evt.target.value) : false} />
                    <label className="top-label">Add Message</label>
                    <p className="sub-subtitle right">{data.length} / 3000</p>
                </Form.Group>
            </div>                  
          
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()}/>
            : null}
            <button className="default-button" disabled={((data.length > 0 && name.length > 0 && email.length > 0) ? false : true)} onClick={() => send()}>Send</button>        
        </div>
        )
}