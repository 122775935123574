import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface IProps {
    show:boolean
    onHide:Function
}

function AccessModal(props:IProps) {

    const history = useHistory();

    return (
        <Modal show={props.show} onHide={props.onHide} className="access-modal">
            <Modal.Header closeButton>
                Locked
            </Modal.Header>
            <Modal.Body>
                <p>Subscribe to access</p>
                <p className="info-text">This page is available to Subscription users only</p>
                <Button className="default-button" onClick={() => history.push("/dashboard/account/subscription/buy")}>View Subscriptions</Button>
            </Modal.Body>
        </Modal>
    )
}

export default AccessModal;