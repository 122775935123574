import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import TabSlider from "../menu-items/tab-slider";

export enum menu {
    INFO = "info",
    BUY = "buy",
}

interface RouteParams {
    mainMenu: string
    subMenu:string
}


/**
 * Subscription page
 *
 */
function MediaBoost() {

    // params for the tab menu
    const params = useParams<RouteParams>();

    //tabs
    const [key, setKey] = useState<menu>(menu.INFO);

    // for checking window width to toggle mobile tabs navigation
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const MAX_WIDTH = 500;
    const checkWindowWidth = (condition: string = "lte", threshold: number = MAX_WIDTH) => (condition === "lte" ? windowWidth <= threshold : windowWidth > threshold);
    const detectSize = () => { setWindowWidth(window.innerWidth); };
    
    const [activeTab, setActiveTab] = useState<menu>(menu.INFO);
    const isTabActive = (tabName: menu) => (activeTab === tabName ? "active" : "");

    const history = useHistory();

    //Translation
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => { window.removeEventListener("resize", detectSize); };
    }, [windowWidth]);

    useEffect(() =>  {
        if(params.subMenu && params.subMenu !== key) {
            setKey(params.subMenu as menu);
            setActiveTab(params.subMenu as menu)
        }      
    }, [ params.subMenu ])
    
    function handleSetKey(tabItem: menu) {
        history.push("/dashboard/account/" + params.mainMenu + "/" + tabItem);
    }


    return (
        <div className="profile-container">
            <TabSlider checkWindowWidth={checkWindowWidth} maxWidth={MAX_WIDTH}>
                <li className={isTabActive(menu.INFO)} onClick={() => { handleSetKey(menu.INFO); }}>{t("Info")}</li>
                <li className={isTabActive(menu.BUY)} onClick={() => { handleSetKey(menu.BUY); }}>{t("Buy")}</li>
            </TabSlider>
            {(checkWindowWidth() && isTabActive(menu.INFO)) && 
                <div className="coming-soon-text">
                    <p>Coming soon...</p> 
                </div>
            }
            
            {(checkWindowWidth() && isTabActive(menu.BUY)) && 
                <div className="coming-soon-text">
                    <p>Coming soon...</p> 
                </div>
            }

            {checkWindowWidth("gt") &&
            <Tabs className="profile-tabs menu-tabs submenu-tabs" activeKey={key} onSelect={(k) => { handleSetKey(k as menu); }}>
                <Tab eventKey={menu.INFO} title={t("Info")}>
                    <p className="coming-soon-text">Coming soon...</p>
                </Tab>
                <Tab eventKey={menu.BUY} title={t("Buy")}>
                    <p className="coming-soon-text">Coming soon...</p>              
                </Tab>
            </Tabs>
            }
        </div>
    );
}

export default MediaBoost;
