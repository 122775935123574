/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import instance from "../../api/api";
import { fileTypes, IMedia } from "../../interfaces/story";
import Icon from "@mdi/react";
import { mdiAccount, mdiCalendar, mdiCheck, mdiCheckCircleOutline, mdiClose, mdiDotsHorizontal, mdiEarth, mdiPen, mdiArrowExpand, mdiPound, mdiStar, mdiVideo, mdiStarOff } from "@mdi/js";
import StoryVisualsFiltersBar, { sortOptions } from "./story-visuals-filters-bar.component";
import { Modal, Spinner } from "react-bootstrap";
import VisualDetailsModal from "./story-visual-details.component";
import StoryVisualFilters from "./story-visual-filters.component";
import { IUserDetails } from "../../interfaces/authentication";
import { IDateInputs } from "./story-info.component";
import { IOption } from "../../interfaces/family";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { countries } from "countries-list";
import { Context, defaultVisualFilters } from "../../pages/home.page";
import { ISort } from "./story-published-all.component";
import ImageFullscreenViewer from "../_helper/image-fullscreen-viewer";
import IFrameDisplay from "../_helper/iframe-display.component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

interface IProps {

}

export interface IVisualFilters {
    [key: string]: any
    featured:IUserDetails[]
    participants:IUserDetails[]
    date:IDateInputs
    favorites:boolean
    storyTags:IOption[]
    country:string
}

export const visualsSortOptions = ['PUBLISHED_DATE', 'OLDEST', 'NEWEST', 'VIDEOS_FIRST', 'IMAGES_FIRST'];

const filterIcons : any = {
    featured:mdiPen,
    participants:mdiAccount,
    date: mdiCalendar,
    storyTags:mdiPound,
    country:mdiEarth,
    favorites:mdiCheck,
}

const booleanReadableValues:any = {
    favorites:"Favourites",
}

export function getDuration(media:IMedia) {

    let duration: number = 0;

    if(!media.duration) return duration;

    duration = Math.ceil(media.duration);
    let hours = Math.floor(duration / 3600);
    let mins = Math.floor(duration / 60) - (hours * 60);
    let secs = Math.floor(duration % 60);
    return mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
}

export default function StoryVisuals(props:IProps) {

    const [ visualsData, setVisualsData ] = useState<IMedia[]>([]);
    const [ selected, setSelected ] = useState<string[]>([]);
    const [ selectScreen, setSelectScreen ] = useState<boolean>(false);
    const [ showSelectedDetails, setShowSelectedDetails ] = useState<boolean>(false);

    //details
    const [ showVisualDetails, setShowVisualsDetails ] = useState<boolean>(false);
    const [ visualDetails, setVisualDetails ] = useState<IMedia>();

    //filters
    const [ filtersActive, setFiltersActive ] = useState<boolean>(false);
    const [ stateLoaded, setStateLoaded ] = useState<boolean>(false);
    const [ showFilters, _setShowFilters ] = useState<boolean>(false);
    const [ queryString, setQueryString ] = useState<string>("");

    const [ sort, setSort ] = useState<sortOptions>(sortOptions.PUBLISHED_DATE);
    const [ search, setSearch ] = useState<string>('');

    const [ pageNum, setPageNum ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ hasMore, setHasMore ] = useState<boolean>(false);
    const [ totalCount, setTotalCount ] = useState<Number>(0);
    const [ sortOption, setSortOption ] = useState<ISort>({field:'createdAt', type:'desc'});
    const [ fullScreen, setFullScreen ] = useState<boolean>(false);
    const [ imageLoaded, setImageLoaded ] = useState<string[]>([]);

    const location: any = useLocation();

    //context
    const userData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const filters = useContext(Context).visualFilters;
    const setFilters = useContext(Context).setVisualFilters;

    useEffect(() => {
        if(stateLoaded && userData.subscription) {
            fetchPublishedStories();
        }
    }, [ queryString, pageNum, userData.subscription]);

    useEffect(() => {
        //Only run automatic filtering if the state is already loaded.
        if((location.state && location.state.hasOwnProperty('filters')) || stateLoaded) {
            setupQueryString();
        }
    }, [filters, sortOption])

    useEffect(() => {
        if(location.state && location.state.hasOwnProperty('filters') && location.state.filters.hasOwnProperty('visuals')) {
            //have to get some information from rerouting to be able to fill out query
            setFiltersActive(true);
            const newFilters = {...defaultVisualFilters, ...location.state.filters.visuals};
            setFilters(newFilters);
            setStateLoaded(true);
        }else {
            setStateLoaded(true);
        }
    }, [])

    async function fetchPublishedStories() {
        try {
            //Get newly published stories
            const response = await instance.get(`/media?${queryString}&pageNum=${pageNum}`, {
                params: {
                    fileType: [fileTypes.VIDEO, fileTypes.IMAGE]
                }
            });

            // we don't add the ones from the response that already exist when visuals refreshed (i.e. when clicking an image as Favourite when multiple pages of images are out already)
            // TODO: low: performance
            const newMedia = visualsData.length ? (response.data.result as IMedia[]).filter(x=>!visualsData.map(x=>x._id).includes(x._id)) : response.data.result;

            setVisualsData([...visualsData, ...newMedia]);
            setHasMore(response.data.hasNextPage);
            setTotalCount(response.data.totalRecords)
            setLoading(false);

        } catch (error) {
            console.log("Failed to get published stories", error)
        }
    }

    function imageclick(visual:IMedia) {
        if(selectScreen) {
            if(selected.includes(visual._id)) {
                setSelected(selected.filter(x => visual._id !== x))
            }
            else {
                setSelected(prev => [...prev, visual._id])

            }
        }
        else {
            setVisualDetails(visual);
            setShowVisualsDetails(true);
        }
    }

    function removeAllfilters() {
        setFilters(defaultVisualFilters);
    }

    function addToFavorites() {
        const ids = selected.map(async (_id) => {
            if(!userData.favorites.media.includes(_id)) {
                await instance.put(`/user/${userData._id}`, { push: { "favorites.media": _id }});
                return _id
            }
        });

        Promise.all(ids).then(results => {
            updateLoggedInUserData({...userData, favorites:{...userData.favorites, media:[...userData.favorites.media, ...(results as string[])]}})
        });

         setShowSelectedDetails(false);
    }

    function removeFromFavorites() {
        const ids = selected.map(async (_id) => {
            if(userData.favorites.media.includes(_id)) {
                await instance.put(`/user/${userData._id}`, { pull: { "favorites.media": _id }});
                return _id
            }
        });

        Promise.all(ids).then(results => {
            updateLoggedInUserData({...userData, favorites:{...userData.favorites, media:userData.favorites.media.filter(x => !(results as string[]).includes(x))}})
        });

         setShowSelectedDetails(false);
    }

    function selectAll() {
        setSelected(visualsData.map(x => x._id));
    }

    function setShowFilters() {
        _setShowFilters(prev => !prev)
    }

    async function setupQueryString() {
        const refactoredFilters:any = {};

        Object.entries(filters).forEach(function([key, value]) {

            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            if(value) {
                if(key === "favorites" || key === "participants") {
                    if(key === "favorites") {
                        refactoredFilters[key] = value;
                    }
                    else if(key === "participants") {
                        refactoredFilters[key] = value.map((x:IUserDetails) => x._id);
                    }
                }
                else {
                    if(key === "storyTags") {
                        refactoredFilters[key] = value.map((x:IOption) => x._id);
                    }
                    else if(key === "featured") {
                        if(!value.length) {
                            return
                        }
                        refactoredFilters[key] = value.map((x:IUserDetails) => x._id);
                    }
                    else if(key === "date") {
                        let dates:any = []

                        if(!value.year && !value.month && !value.day) {
                            return
                        }

                        if(value.year !== null) {
                            dates.push(value.year)
                        }
                        if(value.month !== null) {
                            dates.push(value.month)
                        }
                        if(value.day !== null) {
                            dates.push(value.day)
                        }
                        refactoredFilters[key] = dates
                    }
                    else {
                        refactoredFilters[key] = value
                    }
                }
            }
        })

        let sortString = "";

        if(sortOption.field.length) {
            sortString = "sort=" + sortOption.field + "," + sortOption.type;
        }

        const filterString =   Object.keys(refactoredFilters).map(function(key) {
            return key + '=' + refactoredFilters[key]
        }).join('&');

        const queryString = sortString + (filterString.length ? "&" + filterString : '');

        if(queryString.length) {
            setPageNum(0);
            setVisualsData([])
            setQueryString(queryString);
            setFiltersActive(true);

        }
        else {
            setPageNum(0);
            setVisualsData([]);
            setQueryString('');
            setFiltersActive(false);
        }
    }

    useEffect(() => {
        setVisualsData([]); //Clear options;

        switch (sort){
            case sortOptions.PUBLISHED_DATE:
                setSortOption({field:'relatedStory.createdAt', type:'desc'})
                break
            case sortOptions.OLDEST:
                setSortOption({field:'relatedStory.date.startDate.date', type:'asc'})
                break
            case sortOptions.NEWEST:
                setSortOption({field:'relatedStory.date.startDate.date', type:'desc'})
                break
            case sortOptions.VIDEOS_FIRST:
                setSortOption({field:'fileType', type:'desc'})
                break
            case sortOptions.IMAGES_FIRST:
                setSortOption({field:'fileType', type:'asc'})
                break
            // case sortOptions.COMMENT:
            //     setSortOption({field:'', type:''})
            //     break
            // case sortOptions.RECENTLY:
            //     setSortOption({field:'', type:''})
            //     break
            default:
                setSortOption({field:'', type:''})
        }

    }, [ sort ])

    /**
     * Delete a filter by specific key.
     * @param key - The key for the filter we want to delete
     */
    function removeFilterByKey(key:string) {
        let tempFilterOject = { ...filters }
        if(tempFilterOject.hasOwnProperty(key)) {
            tempFilterOject[key] = defaultVisualFilters[key];
            setFilters({...tempFilterOject})
            setVisualsData([])
        }
    }

    function previousVisual() {
        const currentIndex = visualsData.findIndex((data) => data._id === visualDetails?._id);
        if(visualsData[currentIndex-1]) {
            setVisualDetails(visualsData[currentIndex-1])
        }
    }

    function nextVisual() {
        const currentIndex = visualsData.findIndex((data) => data._id === visualDetails?._id);
        if(visualsData[currentIndex+1]) {
            setVisualDetails(visualsData[currentIndex+1])
        }
    }

    function filterRow() {
        let emptyFilters = true;
        const filterRow = Object.entries(filters).map(([key, value]) => {
            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            if(key === "date") {
                if((value.year === null && value.month === null && value.day === null)) {
                    return
                }
            }
            if(value) {
                emptyFilters = false;
                return(
                    <div className="active-filter-box">
                        <div>
                            <Icon className="info-icon" size={0.7} path={filterIcons[key]} />
                        </div>
                        {Array.isArray(value) ?
                            (key === "featured" || key === "participants") ? <p>{value.map(x =>`${x.firstname} ${x.lastname}`).join(", ")}</p> : <p>{value.map(x => x.text).join(",")}</p>
                            :
                            (value === true || value === false) ? <p>{booleanReadableValues[key]}</p> :
                            (key === 'date') ? <p>{moment(value.date).format(`${value.day !== null ? "D" : ""} ${value.month !== null ? "MMM," : ""} ${value.year !== null ? "YYYY" : ""}`)}</p> :
                            (key === 'country') ?  <p>{(countries as any)[value] ? (countries as any)[value].name : value}</p> :
                            <p>{value}</p>
                        }
                        {/* <p>{key}</p> */}
                        <div className="remove" onClick={() => removeFilterByKey(key)}>
                            <Icon className="close-icon" size={0.7} path={mdiClose} />
                        </div>
                    </div>
                )
            }
        })

        if(emptyFilters) {
            setFiltersActive(false);
            setQueryString("sort=" + sortOption.field + "," + sortOption.type);
        }

        return filterRow;
    }

    return(
        <div className="story-visuals-container">
          {userData.subscription ?
            <>
            <div className="filters">
                <StoryVisualsFiltersBar removeAllfilters={removeAllfilters} filtersActive={filtersActive} showFilters={showFilters} setShowFilters={setShowFilters} setSort={setSort} sort={sort} search={search} setSearch={setSearch} sortOptions={visualsSortOptions}/>
            </div>
            {!showFilters ? <div>
                {filtersActive && <div className='active-filter-container'>{filterRow()}</div>}
                <div className="info-row">
                    <div className="left">
                        {selectScreen ? <p className="result">{selected.length} selected</p> : <p className="result">{totalCount} results</p>}
                        {selectScreen ? <p className="select-all" onClick={() => selectAll()}>Select all</p> : null}
                    </div>
                    <div className="right">
                        {selectScreen ?
                            <div className="cancel">
                                <p onClick={() => {setSelectScreen(false); setSelected([])}}>Cancel</p>
                                <div  onClick={() =>setShowSelectedDetails(true)}>
                                    <Icon size={1} path={mdiDotsHorizontal}/>
                                </div>
                            </div>
                            :
                            <p onClick={() =>setSelectScreen(true)}>Select</p>
                        }
                    </div>
                </div>
                <div className="visuals">
                    {visualsData.map((visual, index) => {
                        return(
                            <div key={index} className="visual">
                                {userData.favorites.media.includes(visual._id) ? <div className="fav-star"><Icon size={1} className={""} path={mdiStar}/></div> : null}
                                { visual.fileType === "image" ?
                                    <LazyLoadImage src={visual.dataS ? visual.dataS : visual.data}
                                        placeholderSrc={visual.dataXs ? visual.dataXs : visual.data}
                                        alt="Image Alt"
                                        effect="blur"
                                        onClick={() => imageclick(visual)}
                                    />
                                    :
                                <div className="video-visual">
                                    <div>
                                        <video onLoadedData={() => setImageLoaded((prev:string[]) => { return [...prev, index.toString()]})} id={`vid-${visual._id}`} controls={false} preload="metadata" onClick={() => imageclick(visual)}>
                                            <source src={`${visual.data}#t=0.5`} type="video/mp4"/>
                                        </video>
                                        <p className="duration"><Icon size={1} path={mdiVideo}/>  <span id={`vid-dur-${visual._id}`}>{getDuration(visual)}</span></p>
                                    </div>
                                    {!imageLoaded.includes(index.toString()) ? <div className="image-loading"><div className="spinner"><Spinner animation="border" variant="#fff" /></div></div> : null}
                                </div>}
                                {selectScreen ? <div onClick={() => imageclick(visual)}><Icon size={1.3} className={selected.includes(visual._id) ? "select-active" : "select"} path={mdiCheckCircleOutline}/></div> : null}
                            </div>
                        )
                    })}
                    {!visualsData.length && !loading && <IFrameDisplay pageName={"stories-visuals"} />}
                    {!hasMore && visualsData.length ? <p className="has-more">No more visuals to display</p> : null}
                    {hasMore ? <button className="bordered-button" onClick={() => setPageNum(prev => prev+1)}> {loading ? <Spinner animation="border" variant="#fff" /> : "Load More"}</button> : null}

                    </div>
                    {fullScreen && <ImageFullscreenViewer close={() => setFullScreen(false)} images={visualsData.filter(x => selected.includes(x._id))} />}
                    <Modal dialogClassName={"story-action-modal"} show={ showSelectedDetails} onHide={() => setShowSelectedDetails(false)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body className="edit-card-content">
                            <div className="link-row" onClick={() => {setFullScreen(true);  setShowSelectedDetails(false);}}>
                                <Icon size={1} path={ mdiArrowExpand } /><p className="main-text-single">View Fullscreen</p>
                            </div>
                            {selected.length===1 && !userData.favorites.media.includes(selected[0]) ? 
                                <div className="link-row" onClick={addToFavorites}>
                                    <Icon size={1} path={ mdiStar } /><p className="main-text-info">Add to Favourite Media</p>
                                    <p className="info-text">For easy future access</p>
                                </div>
                            : selected.length===1 && userData.favorites.media.includes(selected[0]) ? 
                                <div className="link-row" onClick={removeFromFavorites}>
                                    <Icon size={1} path={ mdiStarOff } /><p className="main-text-info">Remove from Favourite Media</p>
                                </div>                        
                            : 
                            <div>
                                <div className="link-row" onClick={addToFavorites}>
                                    <Icon size={1} path={ mdiStar } /><p className="main-text-info">Add all to Favourite Media</p>
                                    <p className="info-text">For easy future access</p>
                                </div>
                                <div className="link-row" onClick={removeFromFavorites}>
                                    <Icon size={1} path={ mdiStarOff } /><p className="main-text-info">Remove all from Favourite Media</p>
                                </div>
                            </div>                                
                            }
                        </Modal.Body>
                    </Modal>
                    {visualDetails && <VisualDetailsModal show={showVisualDetails} setShow={setShowVisualsDetails} visual={visualDetails} nextVisual={nextVisual} previousVisual={previousVisual}/>}
                </div> :
                <StoryVisualFilters count={totalCount} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} queryString={queryString}/>
                }
            </>
            : !loading ? <p className="sub-access-message">You have to be a subscriber to access this page.</p> : null}
        </div>
    )
}
