import React, { Dispatch, useCallback, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTraceUpdate } from '../../components/_helper/utilities';
import IPage from '../../interfaces/page';
import { setCurrentPage } from '../../store/actionCreators';
import { Carousel } from 'react-bootstrap';
import { Icon } from '@mdi/react';
import { mdiClose, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import TooltipHelp from '../../components/notifications/tooltiphelp.component';
import config from '../../config/config';
import axios from 'axios';
import { IOption } from '../../interfaces/family';
import Help from '../../components/_helper/help.component';
import { navigatedToRequest } from '../../components/story/requests/story-requests-create.component';
import moment from 'moment';
import instance from '../../api/api';
import { Context } from "../../pages/home.page"


/**
 * Basic enum for navigation
 */
 enum menu {
    QUESTIONS="1",
    PROPOSALS="2",
}

/* header image */
interface IInspirationQuestionImageData {
    data: IInspirationQuestionImage
}

/* header image */
interface IInspirationQuestionImage {
    id: number
    attributes: IInspirationQuestionImageAttributes
}

/* header image */
interface IInspirationQuestionImageAttributes {
    url: string
}

interface IInspirationQuestionData {
    data: IInspirationQuestion[]
}

interface IInspirationQuestion {
    id: number
    attributes: IInspirationQuestionAttributes
}

interface IInspirationQuestionAttributes {
    question: string
    optional: string
    inspiration_image: IInspirationQuestionImagesData
}

interface IInspirationQuestionImagesData {
    data: IInspirationQuestionImages
}

interface IInspirationQuestionImages {
    id: number
    attributes: IInspirationQuestionImagesAttributes
}

interface IInspirationQuestionImagesAttributes {
    url: string
}

interface IInspirationCategory {
    id: number
    attributes: IInspirationCategoryAttributes
}

interface IInspirationCategoryAttributes {
    category: string
    header_description?: string 
    header_image?: IInspirationQuestionImageData 
    inspiration_questions: IInspirationQuestionData  
    created_at?: Date
    help_questions?: any[]
    published_at?: Date
    updated_at?: Date
}


export function contentImage(url:string){

    const src = url.slice(0, -15).replaceAll("_", "-");
    return `${config.contentServerURL}${src}.jpg`;
}

/**
 * Inspirations page
 * 
 * @param props : props
 * @returns void
 */
 const Inspirations: React.FunctionComponent<IPage> = props => {

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const [showQuestionsByStoryTagTT, setShowQuestionsByStoryTagTT] = useState<boolean>(false);    

    const [ inspirationCategories, setInspirationCategories ] = useState<IInspirationCategory[]>([]); // TODO: useStateIfMounted

    //const [ inspirationHeroSlider, setInspirationHeroSlider ] = useState<IInspirationCategory[]>([]); // TODO: useStateIfMounted

    const { user } = useContext(Context)

    const history = useHistory()    

    interface IInspirationCategories {
        inspirationCategories: IInspirationCategory[]
    }    

    interface IInspiration {
        inspiration: IInspirationCategory
        updateInspirations: Function 
        openInspiration: boolean
    }

    interface IDisplayHeader {
        inspiration: IInspirationCategory
        updateInspiration:Function
        open: boolean
        category?: string
    }     

    interface IDisplayCarousel {
        inspiration_questions:IInspirationQuestionData
        updateInspiration:Function
        open: boolean
        category?: string
    }    

    useTraceUpdate(props);

    useEffect(() => {
        setPageName('Inspiration');
        
        const controller = new AbortController();

        axios.get(`${config.contentServerURL}/api/inspiration-categories?populate[0]=header_image&populate[1]=inspiration_questions.inspiration_image&filters[category][$ne]=hero_slider&sort[0]=category:ASC`, {
            signal: controller.signal
        }).then(resp => { // Strapi v4 query format
            setInspirationCategories(resp.data.data);
        }, error => {
            console.log("Failed to get inspiration categories", error)
        });
/*
        axios.get(`${config.contentServerURL}/inspiration-categories?category_eq=hero_slider`, {
            signal: controller.signal
        }).then(resp => { // Strapi v3 query format
            setInspirationHeroSlider(resp.data);
        }, error => {
            console.log("Failed to get inspiration hero slider", error)
        });
*/        

        return () => {
            console.log("Cleaning up");
            controller.abort();  
        }
    }, [])

    /**
     * create unpublished story by the selected inspiration and navigate to the story create page
     * @param inspirationQuestion 
     */
    async function handleCreateByInspiration(inspirationQuestion: any) {        
        try {
            let storyData: any = {
                // the story title is the title of the selected inspiration
                title: " ",
                // the author and the featured profile are the current user
                author: user._id,
                featured: user._id,
                date: {
                    startDate: {
                        date: moment().toDate(),
                        year: new Date().getFullYear(),
                        month: new Date().getMonth(),
                        day: new Date().getDate(),
                    },
                },
                blocks: [],
                // save the id of the inspiration into the story
                insprirationId: inspirationQuestion.id || ""
            }

            // send post request to the api to create new story
            const response = await instance.post("/stories", storyData)
            // navigate to the story create page
            history.push("/dashboard/create", { data: response.data })
        } catch (error) {
            console.log("Error", error)
        }
    }

    // All inspirations
    function DisplayInspirations(props:IInspirationCategories) {

        const [ category, setCategory ] = useState<string>("");     
        const [ requestedState, setRequestedState ] = useState<boolean>(false);

        function updateInspirations(category: string, requestedState: boolean) {
            // send down to components below: what was clicked and was it an open or a close request
            setCategory(category);
            setRequestedState(requestedState);
        }

        useEffect(() => {
            updateInspirations("authentic_everyday", true)
        },[])

        useEffect(() => {
            if(category==="authentic_everyday") return;
            const element = document.getElementById(category);
            if (element) {
                element.scrollIntoView();
            }
        },[category])            

        return(
            <div>
            <p className="information">Ideas organised by #Story-tag</p>
            {props.inspirationCategories.length ?
                props.inspirationCategories.map((inspiration) => {
                    return (
                        <div className="inspiration-item-container no-shadow" key={inspiration.id}>
                            <div>
                                {<DisplayInspiration inspiration={inspiration} updateInspirations={updateInspirations} openInspiration={(inspiration.attributes.category===category && requestedState)}/>}
                            </div>
                        </div>
                    );
                }) 
            : null}
            </div>
        );
    }
    
    // A single inspiration
    function DisplayInspiration(props:IInspiration) {

        //const [ openInspiration, setOpenInspiration ] = useState<boolean>(false);

        function updateInspiration(open: boolean) {
            // to know which inspiration was clicked, send category one component level up
            props.updateInspirations(props.inspiration.attributes.category, open);
            //setOpenInspiration(open);
        }        

        return(
            <div className={"display-block"} id={props.inspiration.attributes.category}>
                {<DisplayInspirationHeader inspiration={props.inspiration} updateInspiration={updateInspiration} open={props.openInspiration} category={props.inspiration.attributes.category} />}
                {<DisplayInspirationCarousel inspiration_questions={props.inspiration.attributes.inspiration_questions} updateInspiration={updateInspiration} open={props.openInspiration} category={props.inspiration.attributes.category} />}
            </div>
        );
    }    

    // Header of a single inspiration
    function DisplayInspirationHeader(props:IDisplayHeader) {

        //const [ open, setOpen ] = useState<boolean>(false);

        function handleClick(event:any) {
            props.updateInspiration(!props.open);
        }        

        return(
            <div className="display-block-inspiration">
                    {props.inspiration.attributes.category ==='hero_slider' && props.inspiration.attributes.header_description && <p className="description">{props.inspiration.attributes.header_description}</p>} {/* !props.open && */}                
                    <div className={`content ${props.open ? "show" : ""}`} onClick={(e) => { handleClick(e) }}>
                            {props.inspiration.attributes.header_image?.data && <img className="inspiration-block-image" alt="Block" src={contentImage(props.inspiration.attributes.header_image?.data.attributes.url)} />}
                            <div>
                            {props.inspiration.attributes.category!=='hero_slider' &&
                                <p className="story-tag">{(config.storyTags.find(data => data._id === props.inspiration.attributes.category.toUpperCase()) as IOption).text}</p>
                            }
                            {!props.open && props.inspiration.attributes.header_description && <p className="description">{props.inspiration.attributes.header_description}</p>} {/* !props.open && */}
                            {props.inspiration.attributes.category!=='hero_slider' && <p className="icon">{props.open && <Icon size={1} path={mdiClose} />}</p>}
                            </div>
                    
                    </div>
            </div>
        )
    }   
 
    // Carousel of a single inspiration
    function DisplayInspirationCarousel(props:IDisplayCarousel) {
        const [index, setIndex] = useState<number>(0);
        //const [ open, setOpen ] = useState<boolean>(props.open);  
    
        const handleSelect = (selectedIndex:number, e:any) => {
            setIndex(selectedIndex);
        };

        return(
            <div className={"display-block-carousel display-block-media "  + (props.open ? "open-item-background": "")}>             
                {props.open ?
                <div className="content">
                    <Carousel activeIndex={index} slide={true} touch={true} interval={null} indicators={false}
                        onSelect={handleSelect} onSlide={handleSelect} 
                        prevIcon={<Icon size={1.3} path={mdiChevronLeft} aria-hidden="true" className="with-shadow" />}
                        nextIcon={<Icon size={1.3} path={mdiChevronRight} aria-hidden="true" />}
                        >
                        {props.inspiration_questions.data && props.inspiration_questions?.data.map((item:IInspirationQuestion, index:number) => (
                            <Carousel.Item key={index} >
                                <img loading="lazy" src={contentImage(item.attributes.inspiration_image?.data?.attributes.url)} alt="uploaded" className="story-block-image" />
                                <div style={{ textAlign: 'right', marginRight: "12px", marginTop: "6px", color: "rgba(0,0,0,0.5)"}}>{index + 1} of {props.inspiration_questions.data.length}</div>                                                                                                
                                {item.attributes.question && <p className="question">{item.attributes.question}</p>}
                                {item.attributes.optional ?
                                    <p className="follow-up">{item.attributes.optional}</p>
                                :
                                    <p className="follow-up">&nbsp;</p>                           
                                }
                                <button className="default-button button-center" onClick={() => handleCreateByInspiration(item)}>
                                        Create Story
                                </button>
                                <button className='link-button' onClick={() => history.push({pathname: '/dashboard/requests', state: { from:navigatedToRequest.INSPIRATION, id: item.id, category: forwardTag(props.category, item.id), data:item.attributes.question }})} >
                                        Create Story Request
                                </button> 
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <div className="interactions">
                    </div>
                </div>
                : null }                    
            </div>

        )
    }

    function updateInspirations() {
        // nop for hero slider
    }
    
    function forwardTag(category:any, itemId:any){

        let storyTag = "";

        if(category==='hero_slider') {

            let found = "";
            for (let category of inspirationCategories) {
                if(category.attributes.inspiration_questions.data.filter(question => question.id === itemId).length) {
                    found = category.attributes.category;
                    break;
                }
            }
            storyTag = found;
        } else {
            storyTag = category;
        }

        return storyTag;

    }

    return(
        <div className="stories-container">
                    <div className='inspiration-container'>

                        {/*<div className="today-container-heading hero-slider">

                            { inspirationHeroSlider.length ?
                            <div>
                                <div className="inspiration-item-container">
                                    {<DisplayInspiration inspiration={inspirationHeroSlider[0]} updateInspirations={updateInspirations} openInspiration={true} />}
                                </div>
                                <div className="new-block">
                                    <div className="add-story-svg">
                                        <img src={feather} alt="" style={{zIndex: 100, position: "relative", top: "-8px"}} />
                                        <div className="line"></div>
                                    </div>
                                </div>    
                            </div>                        
    : null }
                            </div>*/}

                        {/*<div className='title title-margin'>Ideas organised by #Story-tag
                            <span onClick={() => setShowQuestionsByStoryTagTT(true)}>
                                <Icon size={1} path={mdiHelpCircle} />
                            </span>
                        </div>*/}
            
                        <TooltipHelp contextKey={'inspiration_questions_by_story_tag'}
                            show={showQuestionsByStoryTagTT}
                            clear={() => setShowQuestionsByStoryTagTT(false)}
                        />                        
                    
                        <div className="today-container-heading">

                            {inspirationCategories.length ?
                                null
                            :   
                                <p className="today-heading">No inspirations available.</p>}
                        </div>

                        <DisplayInspirations inspirationCategories={inspirationCategories} />

                        <div className="usertips-container">
                            <div className="usertips-content">
                                <p className='title'>Something missing?</p>
                                <p>Help others by publically sharing your inspiration questions</p>
                                <button className='link-button' onClick={() => history.push(`/dashboard/shareyourinspiration`)}>Share your question</button>
                            </div>
                        </div>
                    </div>
                    <Help category={"INSPIRATION"} pageName={"Questions"}></Help>                      
        </div>
    );
};

export default Inspirations;