import React, { useEffect, useState} from "react";
import { Button, Row, Col, Spinner, Form } from "react-bootstrap";
import { ESubscriptionStatus } from "../../../interfaces/subscription";
import { IUserDetails } from "../../../interfaces/authentication"
import instance from "../../../api/api";
import TooltipHelp from "../../notifications/tooltiphelp.component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PaymentSummaryModal from "../payment_summary_modal.component";
import ConfirmModal from "../../_helper/confirm-modal.component";
import SubscriptionUpgrade from "./subscription_upgrade.component";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Notification, { notificationImages } from "../../notifications/notification.component";
import { INotification, NotificationType } from "../../../interfaces/notification";

interface IProductData {
    display:string
    items: IItemData[]
}

interface IItemData {
    description: any
    display:string
    path:string
    price:string
    priceTotal:string
    totalValue: number
    priceValueWithoutTax: number
}

interface IBuyProps {
    user:IUserDetails
    updateUserData: Function
}

enum EPaymentType {
    CREDIT_TRANSACTION_PAYMENT="Credit",
    SUBSCRIPTION_PAYMENT="Family Subscription",
    MEDIA_BOOST_PAYMENT="Media Boost",
    MENTORSHIP="Mentorship",
}

const default_item:IItemData = {
    description: "Free",
    display:"Free",
    path:"free",
    price:"$0",
    priceTotal:"$0",
    totalValue: 0,
    priceValueWithoutTax: 0
}

const default_product:IProductData = {
    display: "Subscription",
    items:[]
}


export default function SubscriptionBuy(props:IBuyProps) {

    const [ loading, setLoading ] = useState<boolean>(true);
    const [ productData, setProductData ] = useState<IProductData>(default_product);

    const [ selectedProduct, setSelectedProduct ] = useState<IItemData>(default_item);
    const [ selectedSubscriptionType, setSelectedSubscriptionType ] = useState<any>()
    const [ subscriptionTypes, setSubscriptionTypes ] = useState<any[]>([]);

    const [ buyDisabled, setBuyDisabled ] = useState<boolean>(false);

    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const [ tooltipData, setTooltipData ] = useState<string>("");
    const [ confirmDowngradeModal, setConfirmDowngradeModal ] = useState<boolean>(false);

    const [ paymentSummaryModal, setPaymentSummaryModal ] = useState<boolean>(false);

    const [notification, setNotification] = useState<INotification>({ icon: "", title: "", text: "", success: NotificationType.problem });

    //Translation
    const { t } = useTranslation();

    //history
    const history = useHistory();

    useEffect(() => {
        getSubscriptionTypes();

        return () => {
            //remove fastspring script
            if(window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID) {
                const script = document.getElementById(window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID);

                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            }
        }
    }, [])

    useEffect(() => {
        if(props.user._id){
            const item = productData.items.find(x => x.path === props.user.subscription?.subscription_type.fastspring_key);
            const subType = subscriptionTypes.find(x => x.fastspring_key === props.user.subscription?.subscription_type.fastspring_key);

            if(item && subType) {
                setSelectedProduct(item);
                setSelectedSubscriptionType(subType)
            }
            else {
                setSelectedProduct(default_item);
            }
        }
    }, [props.user._id, productData, subscriptionTypes])

    useEffect(() => {
        if(props.user._id.length && subscriptionTypes.length) {
            addFastSpringScript();
        }
    }, [props.user._id, subscriptionTypes])

    async function getSubscriptionTypes() {
        try {
            let response = await instance.get("/subscriptions/types")
            setSubscriptionTypes(response.data)
        }
        catch(err) {
            setBuyDisabled(true);
        }
    }


    async function subscribe() {
        if(window.fastspring && selectedProduct) {
            const payload = {
                contact: {
                    email:props.user.email,
                    firstName:props.user.firstname,
                    lastName:props.user.lastname
                },
                items: 
                    [
                        {
                            product: selectedProduct.path,
                            quantity: 1,
                        }
                    ]
            }

            // get encrypted data from the api
            const response = await instance.post("/fastspring/encrypt", { payload })
            const { securePayload, secureKey } = response.data            

            window.fastspring.builder.secure(securePayload, secureKey)
            window.fastspring.builder.checkout();
        }
    }
    
    function fastSpringCallBack(data:any) {    
        if (data && data.groups) {
            setProductData(data.groups[0]);

            if(props.user.subscription && props.user.subscription.status !== ESubscriptionStatus.INACTIVE) {
                const subType = subscriptionTypes.find(x => x.fastspring_key === data.groups[0].items[0].path);
                const userSubTypeItem = data.groups[0].items.find((item:any) => item.path === subType.fastspring_key);
                if(subType && userSubTypeItem) {
                    setSelectedProduct(userSubTypeItem);
                    setSelectedSubscriptionType(subType)
                }
            }

            setLoading(false)
        }
    };

    async function webhookcallback(data:any) {

        //console.log("webhookcallback")        

        const paymnentResponseData = data;

        const isSubscription = paymnentResponseData.items.find((orderItem:any) => orderItem.subscription.length);

        /*
        let payment_type = EPaymentType.SUBSCRIPTION_PAYMENT

        try {
            let payment_data = {
                payment_id:paymnentResponseData.id,
                payment_type:payment_type,
                user:props.user._id,
                currency: paymnentResponseData.currency,
                payout_currency: paymnentResponseData.payoutCurrency,
                reference: paymnentResponseData.reference,
                subtotal: paymnentResponseData.subtotal,
                subtotal_display: paymnentResponseData.subtotalDisplay,
                tax: paymnentResponseData.tax,
                tax_display: paymnentResponseData.taxDisplay,
                total: paymnentResponseData.total,
                total_display: paymnentResponseData.totalDisplay,
                subtotal_in_payout_currency:paymnentResponseData.subtotalInPayoutCurrency,

            }

            const paymentResponse = await instance.post("/payment", payment_data);

            if(!paymentResponse.data) {
                return
            }

            if(isSubscription) {    
                const subType = subscriptionTypes.find(x => x.fastspring_key === isSubscription.product);

                if(!subType) {
                    //TODO: never should happen
                    console.log('failed to get subtype')
                    return
                }

                let subscriptionData = {
                    owner:props.user._id,
                    fastspring_id: isSubscription.subscription,
                    expiration: moment().add(1, 'y'),
                    members: [],
                    payments: [paymentResponse.data._id],
                    subscription_type:subType._id,
                }

                const subResponse = await instance.post("/subscriptions", subscriptionData);

                if(!subResponse.data) {
                    return
                }

                const userResponse = await instance.put('/user/' + props.user._id, { details: { subscription: subResponse.data._id, fastspring_account_id:data.account }});
                */
        
        try{
/*
            console.log("webhookcallback-isSubscription", isSubscription)

            example response:
            {
                "product": "family",
                "quantity": 1,
                "coupon": null,
                "sku": null,
                "subtotal": 120,
                "subtotalDisplay": "$120.00",
                "subtotalInPayoutCurrency": 120,
                "subtotalInPayoutCurrencyDisplay": "$120.00",
                "discount": 0,
                "discountDisplay": "$0.00",
                "discountInPayoutCurrency": 0,
                "discountInPayoutCurrencyDisplay": "$0.00",
                "subscription": "IppGqmeNQxGJn07wSh9eCQ",
                "fulfillments": {
                    "instructions": "<p>Enjoy full access, share with up to 10 family members and together upload up to 50GB of media files.</p>"
                },
                "driver": {
                    "type": "cross-sell",
                    "path": "simirity-popup-simirity"
                }
            }            
*/
            if(isSubscription) {
                setPaymentSummaryModal(false);
                /*
                const userResponse = await instance.get('/user/' + props.user._id);
                // if account exists payment was successful
                if(userResponse.data.fastspring_account_id){
                    props.updateUserData(userResponse.data)
                    history.push("/dashboard/account/purchases/info", { success: true });                    
                } else {
                    // TODO: medium: sentry
                    setPaymentSummaryModal(false);                    
                    setNotification({icon:notificationImages.crying, title:'Failed to refresh page', text:"Log out, log in to see changes", success: NotificationType.problem})                        
                }  
                */           
            }

        }
        catch(error) {
            setPaymentSummaryModal(false);
            // TODO: medium: sentry
            setNotification({icon:notificationImages.crying, title:'Error while processing subscription', text:"Try again later", success: NotificationType.problem})
        }
    }

    function addFastSpringScript() {

        if( !(window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID && window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_POPUP_STOREFRONT  &&  window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SRC && window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_DATA_ACCESS_KEY) ) {
            return;
        }

        const scriptId = window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SCRIPT_ID;
        const existingScript = document.getElementById(scriptId);

        if (!existingScript) {
            const storeFrontToUse = window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_POPUP_STOREFRONT;
            const script = document.createElement("script");
    
            script.type = "text/javascript";
            script.id = scriptId;
            script.src = window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_SRC;

            script.dataset.storefront = storeFrontToUse;
            // Make sure to add callback function to window so that the DOM is aware of it
            window.fastSpringCallBack = fastSpringCallBack;
            window.popupWebhookCallback = webhookcallback;
            window.fasSpringPopupClosedCb = fasSpringPopupClosedCb;
            
            script.setAttribute("data-popup-webhook-received", "popupWebhookCallback");
            script.setAttribute("data-data-callback", "fastSpringCallBack");
            script.setAttribute("data-popup-closed", "fasSpringPopupClosedCb");
            script.setAttribute("data-access-key", window.__RUNTIME_CONFIG__.REACT_APP_FASTSPRING_DATA_ACCESS_KEY);

            document.body.appendChild(script);
        }
    }

    function fasSpringPopupClosedCb(data:any) {

/*
        console.log("fasSpringPopupClosedCb", data)

        example response: 
        {
            "id": "rwcu7GWoSkirPniBPS1ztA",
            "reference": "SIMIRITY230324-5685-18124"
        }
*/

        // null: the FS window was closed before finishing buying
        if(data!==null){
            history.push("/dashboard/account/purchases/info", { success: true });
        } else {
            setPaymentSummaryModal(false);            
        }
    }

    function selectChange(id:string) {
        const item = productData.items.find(x => x.path === id);
        const subType = subscriptionTypes.find(x => x.fastspring_key === id);

        if(item && subType) {
            setSelectedProduct(item);
            setSelectedSubscriptionType(subType)
        }
        else {
            setSelectedProduct(default_item);
        }
    }

    function buttonDisabled() {
        let disabled = false;

        if (props.user.subscription?.status === ESubscriptionStatus.INACTIVE) {
            return false
        }

        if(selectedProduct.path === props.user.subscription?.subscription_type.fastspring_key 
            // && props.user.subscription.status !== ESubscriptionStatus.INACTIVE
            && props.user._id === props.user.subscription.owner._id) {
            disabled = true
        }

        if(props.user.subscription?.subscription_type.fastspring_key === selectedProduct.path
            && props.user._id === props.user.subscription.owner._id) {
            disabled = true
        }

        if(selectedProduct.path === "family"
            && props.user._id === props.user.subscription?.owner._id) {
            disabled = true
        }        

        if(buyDisabled) {
            disabled = true
        }

        if(selectedProduct.path === "free") {
            disabled = true
        }

        return disabled
    }

    function buttonIsDisplayed() {
        if (selectedProduct.path === "free") {
            return false
        }
        if (props.user.subscription?.status === ESubscriptionStatus.INACTIVE ) {
            return true
        }
        if (
            props.user.subscription?.subscription_type.fastspring_key === "familytrial" ||
            (props.user._id === props.user.subscription?.owner._id && selectedProduct.path === "familyplus")
        ) {
            return false
        }
        return true
    }

    async function downgradeSubscription() {
        try {
            if(!props.user.subscription) {
                return;
            }

            await instance.put("/subscriptions/" + props.user.subscription._id, { $pull: { members: {$in :[props.user._id]} } })
            props.updateUserData((prev:any) => { return {...prev, subscription:undefined } })
            setConfirmDowngradeModal(false)
            history.push("/dashboard/account/subscription/info")
        }
        catch(error) {
            console.log('error', Error)
        }
    }

    function resetNotification() {
        setNotification({ icon: "", title: "", text: "", success: NotificationType.problem });
    }       

    return (
        <div className="subscription-buy-container">
            {notification.title.length ?
                <Notification data={notification} close={() => resetNotification()} />
            : null}            
            {!loading ?
            <div>
                <p className="info-title">{t("Select a subscription to view details")}</p>
                <FloatingLabel controlId="floatingSelect" label="Subscription">
                    <Form.Select aria-label="Default select example" value={selectedProduct?.path} onChange={(evt:any) => selectChange(evt.target.value)}>
                        <option key={"free"} value={"free"}>Free {((!props.user.subscription) || props.user.subscription.status === ESubscriptionStatus.INACTIVE) ? "(" + t("current subscription") +")" : ""}</option>

                        {productData.items.map((item:IItemData, index) => {
                            return(<option key={index} className="relations" value={item.path}>{item.display}, 1 {t("year")} {(item.path === props.user.subscription?.subscription_type.fastspring_key && props.user.subscription.status !== ESubscriptionStatus.INACTIVE) ? "(" + t("current subscription") +")" : ""}</option>)
                        })}
                    </Form.Select>
                </FloatingLabel>

                <div className="subscription-details">
                    <div className="price">
                        <p><span className="price-main">{selectedProduct.price[0]}{Math.round(selectedProduct.totalValue / 12 * 100) / 100}</span> /{t("month")}</p>
                        <p className="info">{t("Paid annually")}, {selectedProduct.priceValueWithoutTax===selectedProduct.totalValue ? t("excludes VAT") : t("includes VAT")}</p>
                    </div>
                    <div className="subscription-details-info">
                        <Row>
                            <Col>
                                <p>{t("Members")}</p>
                                <p className="tooltip-text" onClick={() => { setShowTooltip(true); setTooltipData("myaccount_subscription_members")}}>{t("What's this?")}</p>
                            </Col>
                            <Col>
                                <p>{selectedProduct.path === "free" ? 1 : "Up to " + selectedSubscriptionType.seats}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>{t("Stories with photos, audio, video")}</p>
                                <p className="tooltip-text" onClick={() => { setShowTooltip(true); setTooltipData("myaccount_subscription_storieswith")}}>{t("What's this?")}</p>
                            </Col>
                            <Col>
                            <p>{selectedProduct.path === "free" ? t("Limited") : t("Unlimited")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>{t("Media Uploads (shared between family)")}</p>
                                <p className="tooltip-text" onClick={() => { setShowTooltip(true); setTooltipData("myaccount_subscription_media_uploads")}}>{t("What's this?")}</p>
                            </Col>
                            <Col>
                                <p>{selectedProduct.path === "free" ? "n/a" : selectedSubscriptionType.upload_limit + " GB"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>{t("Premium Access")}</p>
                                <p className="tooltip-text" onClick={() => { setShowTooltip(true); setTooltipData("myaccount_subscription_premium_access")}}>{t("What's this?")}</p>
                            </Col>
                            <Col>
                                <p>{selectedProduct.path === "free" ? t("None") :  t("Full")}</p>
                            </Col>
                        </Row>
                    </div>

                    {props.user.subscription?.subscription_type.fastspring_key === "familyplus" && props.user.subscription?.owner._id === props.user._id ?
                        <>
                            <p className="info-title">{t("To increase your media uploads, use Media Boosts")}</p>
                            <Button className="default-button" onClick={() => history.push("/dashboard/account/subscription/info")}>{loading ? <Spinner animation="border" variant="#fff" /> : "Learn more"}</Button>
                        </>
                        :
                        <></>
                    }

                    {
                    buttonIsDisplayed()
                        ? <div className="button-container">
                        <Button disabled={buttonDisabled()} className="default-button" onClick={() => setPaymentSummaryModal(true)}>{loading ? <Spinner animation="border" variant="#fff" /> : "Select and proceed"}</Button>
                        {props.user.subscription ?
                            props.user._id === props.user.subscription.owner._id ? <p>{t("Subscriptions auto-renew annually")}</p> 
                            : <p>{t("By purchasing a Subscription, you will lose access to your current Subscription.")}</p>
                            : <p>{t("Subscriptions auto-renew annually")}</p>
                        }
                        {props.user.subscription ? <Button disabled={buyDisabled} className="light-button" onClick={() => history.push("/dashboard/referandsave/refer")}>{t("Save on subscriptions")}</Button> : null}
                    </div> : null}
                </div>
            </div> : <Spinner animation="border" variant="#fff" />}
            {props.user.subscription?.subscription_type.fastspring_key !== selectedProduct.path 
                && selectedProduct.path !== "free" 
                && props.user.subscription?.subscription_type.fastspring_key === "family"
                && props.user._id === props.user.subscription.owner._id ?
                <SubscriptionUpgrade user={props.user} />
            : null}
            <TooltipHelp contextKey={tooltipData} show={showTooltip} clear={() => setShowTooltip(false)} />
            <PaymentSummaryModal show={paymentSummaryModal} onHide={() => setPaymentSummaryModal(false)} exectuePayment={subscribe} product={selectedProduct}/>
            <ConfirmModal 
                show={confirmDowngradeModal} 
                action={"delete"} 
                handleClose={() => setConfirmDowngradeModal(false) } 
                modalTitle="Confirm" 
                modalBody={() => (
                    <div className="confirm-cancel-modal-body">
                        <p className="subtitle">{t("Downgrade SUBSCRIPTION to Free")}</p>
                        <p>{t("By proceeding, your family connections and stories will remain, but you will lose the benefits of the subscription you currently access.")}</p>
                    </div>
                )}
                reject={() => setConfirmDowngradeModal(false) } 
                confirm={downgradeSubscription}
                />
        </div>
    );

}