import React, { useEffect, useRef, useState } from "react";
import { mdiCheckCircleOutline, mdiCloudUpload, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { blockTypes, fileTypes, IBlock, IMedia, IStory } from "../../../../interfaces/story";
import { Col, Row } from "react-bootstrap";
import instance from "../../../../api/api"
import instanceUpload from "../../../../api/upload"
import { INotification, NotificationType } from "../../../../interfaces/notification";
import Notification, { notificationImages } from "../../../notifications/notification.component";
import moment from "moment";
import ImageMetaDataEdit from "../../../_helper/image-metadata-edit.component";
import { ILocation } from "../../../_helper/google-map.component";
// @ts-ignore
import exifr from "exifr/dist/lite.esm.mjs";
import Help from "../../../_helper/help.component";
import { ProgressBar } from "react-bootstrap";
import axios, { AxiosError } from "axios"
import UploadLimitReachedModal from "../../upload-limit-reached.modal";
import * as Sentry from "@sentry/react";

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    updateStory: Function
    story: IStory
}

interface IBlobFile {
    blob:string
    file:File
}

function renameFile(originalFile:any, newName:string) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

let errorNotificationData : INotification = {
    icon:notificationImages.crying,
    title:"Failed",
    text:"",
    success: NotificationType.problem
}

interface IMeta {
    date: Date | boolean
    location : ILocation | boolean
}

export default function MediaBlockInput(props:IProps) {

    const [ data, setData ] = useState<IBlobFile[]>([]);

    const [ addedImages, setAddedImages ] = useState<IBlobFile[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const [progress, setProgress] = useState<number[]>([])
    const [noOfProperFiles, setNoOfProperFiles] = useState<number>(0)

    //MetaData
    const [ metaData, setMetaData ] = useState<IMeta[]>([]);
    const [ metaAvalible, setMetaAvalible ] = useState<boolean>(false);
    const [ showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

    //notifications
    const [ errorNotification, setErrorNotification ] = useState(false);
    const [uploadLimitReachedModal, setUploadLimitReachedModal] = useState(false)

    const inputFile = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        
        if(props.data) {
            const block = props.data as IBlock;
            const mediaFiles = [];

            if(block.multipleMedia) {
                if(block.multipleMedia.length) {
                    block.multipleMedia.forEach((media:IMedia) => {
                        const type = "image/png";
                        mediaFiles.push({file: new File([media.data], media.name, {type:type}), blob:media.data})
                    })
                }
            }

            if(block.media) {
                const type = "image/png";
                mediaFiles.push({file: new File([block.media.data], block.media.name, {type:type}), blob:block.media.data})
            }

            setData(mediaFiles);
            setAddedImages(mediaFiles);
        }
    }, [])

    // set isLoading to false if the uploadPercentage is 100%
    useEffect(() => {        
        if (uploadPercentage === 100) {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000);
        }
    }, [uploadPercentage])

    useEffect(() => {        
        if (uploadPercentage === 100) {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000);
        }
        setUploadPercentage(progress.reduce((a, b) => a + b, 0) / noOfProperFiles)
    }, [progress])    
 
    function ConvertDMSToDD(degrees:number, minutes:number, seconds:number, direction:string) {
        var dd = degrees + (minutes/60) + (seconds/360000);
        if (direction == "S" || direction == "W") {
            dd = dd * -1;
        }
        return dd;
    }

    function selectImages(evt:any) {
        const mediaFiles:IBlobFile[] = [...addedImages];
        setMetaData([]);

        Array.from(evt.target.files).forEach((file:any) => {
            mediaFiles.push({file:file, blob:URL.createObjectURL(file)})
        });
        setAddedImages(mediaFiles);

        // propose metdata only once only if it hasn't been set earlier
        // we suppose if there was already an image uploaded we don't show the modal
        if (!props.story.blocks.find(x => x.type == blockTypes.IMAGE)) {
            mediaFiles.forEach(async (x:IBlobFile) => {
                exifr.parse(x.file).then((output:any) => {
                    let data:IMeta = {date:false, location:false};
                    if(!output) {
                        return;
                    }
                    if(Object.keys(output).length) {
                        if(output.hasOwnProperty("DateTimeOriginal") || output.hasOwnProperty("GPSLatitude")) {
                            setMetaAvalible(true);
                            data = {
                                date:output.DateTimeOriginal ? moment(output.DateTimeOriginal, "YYYY:MM:DD HH:mm:ss").toDate() : false,
                                location:output.GPSLatitude ? {
                                    lat: ConvertDMSToDD(output.GPSLatitude[0], output.GPSLatitude[1], output.GPSLatitude[2], output.GPSLatitudeRef),
                                    lng: ConvertDMSToDD(output.GPSLongitude[0], output.GPSLongitude[1], output.GPSLongitude[2], output.GPSLongitudeRef)
                                } : false
                            }
                        }
                    }
                    setMetaData(prev => { return [...prev, data]})
                })
            });

        }

        const selected = [...mediaFiles]
        setData(selected);
    }

    async function addToStory(meta?: IMeta) {
        try {
            //Show error if no images selected
            if (!data.length) {
                errorNotificationData.text = "Add at least 1 image"
                setErrorNotification(true)
                return
            }
            if (data.length>30) {
                setShowAdditionalInfo(false)
                errorNotificationData.text = "Carousels contain up to 30 photos. Use multiple carousels to upload more."
                setErrorNotification(true)
                return
            }            
            setIsLoading(true)
            //Init formdata and cookies
            const formdata = new FormData()

            //Get temp first file, and add id before name

            let type = blockTypes.MEDIA

            //Check for new files only
            let files: any[] = []

            const mediaToKeep: IMedia[] = []
            const mediaToDelete: IMedia[] = []

            const newFiles: File[] = []

            if (props.data) {
                const block = props.data as IBlock
                let allExistingMedia: IMedia[] = []

                if (block.media) {
                    allExistingMedia.push(block.media)
                }

                if (block.multipleMedia) {
                    allExistingMedia = [...allExistingMedia, ...block.multipleMedia]
                }

                const newBlobs = data.map((x) => x.blob)
                const existingBlobs = allExistingMedia.map((x) => x.data)

                //Find new to upload files
                data.forEach((blob: IBlobFile) => {
                    if (!existingBlobs.includes(blob.blob)) {
                        newFiles.push(blob.file)
                        files.push(blob.file)
                    }
                })

                //Find deleted files
                //Find existing not changed files
                allExistingMedia.forEach((media: IMedia) => {
                    if (newBlobs.includes(media.data)) {
                        mediaToKeep.push(media)
                    } else {
                        mediaToDelete.push(media)
                    }
                })
            } else {
                files = data.map((x) => x.file)
            }

            if (data.length > 1) {
                type = blockTypes.CAROUSEL

                const properFiles = files.filter(file => {
                    if (!(file.type as string).includes("image")) {
                        errorNotificationData.text = "The file type is incorrect"
                    } else {
                        return true
                    }
                })

                setNoOfProperFiles(properFiles.length)

                await Promise.all(properFiles.map((file: any, idx) => multipleUpload(file, idx))).then((resolvedValues) => {
                    const medias: string[] = []
                    const fileNames: string[] = []
                    resolvedValues.forEach((value) => {
                        if (value) {
                            medias.push(value.res.data)
                            fileNames.push(value.name)
                        }
                    })
                    setIsLoading(false)
                    props.addBlockToStory({
                        data: [...fileNames, ...mediaToKeep.map((x) => x.name)].join(","),
                        media: null,
                        multipleMedia: [...medias, ...mediaToKeep.map((x) => x._id)],
                        type: type,
                    })
                })
            } else {
                let file
                //Only check if there were files so we are editing;
                if (props.data && mediaToKeep.length) {
                    const media = mediaToKeep[0]

                    if (media.fileType === fileTypes.IMAGE) {
                        type = blockTypes.IMAGE
                    }
                    setIsLoading(false)
                    props.addBlockToStory({ data: media.name, multipleMedia: [], media: media, type: type })
                } else {
                    file = files[0]
                    const fileName = file.name
                    const newFile = renameFile(file, fileName)
                    formdata.append("file", file)
                    formdata.append("story", props.story._id)
                    formdata.append("type", blockTypes.IMAGE)
                    formdata.append("isBlock", "true")

                    if (newFile.type.includes("image")) {
                        type = blockTypes.IMAGE
                    }

                    const options = {
                        // get the status of the file upload
                        onUploadProgress: (progressEvent: any) => {
                            const { loaded, total } = progressEvent
                            let percent = Math.floor((loaded * 100) / total)
                            setUploadPercentage(percent * 0.8) // the file is uploaded, waiting for the response
                        },
                    }
                    //console.log("cs-i", window.__RUNTIME_CONFIG__.REACT_APP_API_URL, instance.getUri())
                    //console.log("cs-iu", window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_URL, instanceUpload.getUri())
                    const response = await instanceUpload.post("/upload", formdata, options)
                    setUploadPercentage(100) // the process is done
                    props.addBlockToStory({ data: fileName, multipleMedia: [], media: response.data, type: type })
                }
            }

            // media is deleted from API, mediaToDelete is not used in here anymore

            if (meta) {
                //Have to update story with location and date
            }
        } catch (error) {
            setIsLoading(false)
            const isAxiosError = axios.isAxiosError(error)

            if (isAxiosError && (error as AxiosError).response?.data === "Upload Limit Reached") {
                setUploadLimitReachedModal(true)
            } else if (isAxiosError && (error as AxiosError).response?.data === "The file type is incorrect") {
                errorNotificationData.text = "The file type is incorrect"
                setErrorNotification(true)
                console.log("Error while uploading photo: ", error)
            } else {
                errorNotificationData.text = "Media server is not responding, try again a few minutes later"
                setErrorNotification(true)
                console.log("Error while uploading photo: ", error)
                Sentry.captureException(error, {user: {id: localStorage.userid}, tags: { 'mediaErrMsg': 'Error while uploading photo'}});
            }
        }
    }

    async function multipleDelete(_id:string) {
        const deletedRes = await instance.delete('/upload/'+ _id );
        return deletedRes
    }

    async function multipleUpload(file:any, idx:number) {
        try {
            const formdata = new FormData();
            const fileName = file.name;
            const newFile = renameFile(file, fileName)
            
            formdata.append("file", newFile);
            formdata.append("story", props.story._id)
            formdata.append("type", blockTypes.MEDIA)
            formdata.append("isBlock", "true")

            const options = {
                // get the status of the file upload
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent
                    let percent = Math.floor((loaded * 100) / total)                   

                    setProgress((prevProgress) => {
                        const newProgress:number[] = [...prevProgress]
                        newProgress[idx] = percent //.toFixed(2)
                        console.log("cs-n",newProgress)
                        return newProgress
                    })

                },
            }
            const res = await instanceUpload.post('/upload', formdata, options);
            return {res:res, name:res.data.data};
        } catch (error) {
            throw error
        }
    }

    function modifyImage(image:IBlobFile) {
        const active = data.filter((x) => x.blob === image.blob);

        if(active.length) {
            const filtered = data.filter((x) => x.blob !== image.blob);
            setData(filtered);
        }
        else {
            setData([...data, image]);
        }
    }

    return(
        <div className="img-upload">
            <div className="inner">
            {!showAdditionalInfo ? 
                <div className="">
                    {addedImages.length ? 
                        <div className="selected-container">
                            <p className="selected">{data.length} item selected</p>
                            <p className="sub-selected">from {addedImages.length} photo</p>
                            {addedImages.length > 20 && <div className="add-more-cont">
                                <label htmlFor="single" className="button-label"><Icon size={1} className="" path={mdiPlus}/></label>
                            </div>}
                        </div>
                        :
                        <div className="selected-container">
                            <p className="selected">Select photo(s)</p>
                            <p className="sub-selected">from your media library</p>
                        </div>}
                    <Row className="addedImages">
                        {addedImages.map((image:IBlobFile, index) => {
                            return(
                                <Col key={index} lg={4} onClick={() => modifyImage(image)} className="image-container">
                                    {image.file.type.includes("image") ? <img id="img1" src={image.blob} alt="uploaded" className="" /> : null }
                                    <Icon size={1} className={data.map(x => x.blob).includes(image.blob) ? "active" : ""} path={mdiCheckCircleOutline}/>
                                </Col>
                            )
                        })}
                    </Row>
                    {addedImages.length ?
                    <div className="upload-container">
                        <label htmlFor="single" className="link-button btn"><Icon className="wrapper-icon" size={1} path={ mdiPlus }/>  Add more Items </label>
                        <input accept="image/*, .heic" className="upload-button" type="file" id="single" onChange={selectImages} hidden multiple/>
                    </div>
                    :
                    <div>
                        <div onClick={() => inputFile.current ? inputFile.current.click() : null} className="upload-drag-and-drop">
                            <Icon size={2} path={mdiCloudUpload}/>
                            <p>Choose file(s)</p>
                        </div>
                        <input accept="image/*, .heic" className="upload-button" type="file" id="drag-and-drop" ref={inputFile} onChange={selectImages} hidden multiple/>
                    </div>}

                    <p className="carousel-info">If you select multiple photos, they will automatically be turned into a carousel.</p>
                    {/*                    
                    <div className="switch-container">
                            <div className="infos">
                                <span>High Quality Photos</span>
                            </div>
                            <div className="status">
                                <Switch
                                    disabled
                                    className="switch"
                                    offColor="#17243d"
                                    offHandleColor="#8B919E"
                                    onColor="#006238"
                                    onHandleColor="#006238"
                                    handleDiameter={20}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={14}
                                    width={32}
                                    onChange={(checked) => console.log("checked")}
                                    checked={false}
                                />
                                <span className="switch-status">{false ? "On" : "Off"}</span>
                            </div>
                    </div>
                    */}
                    <div className="sub-text-info"></div>
                    {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}

                    <button className="default-button add-button" disabled={(data.length > 0 && !isLoading ? false : true)} onClick={() => { metaAvalible ? setShowAdditionalInfo(true) : addToStory() }}>
                        {metaAvalible ? "Next" : "Add to story"}
                        {isLoading ? <ProgressBar className="progressbar" now={uploadPercentage} /> : <></>}
                    </button>
                    {/*<button className="light-button" onClick={() => console.log('this.close')}>Cancel</button>*/}
                    </div> : 
                    <div>
                        <ImageMetaDataEdit uploadPercentage={uploadPercentage} updateStory={props.updateStory} metaData={metaData} addToStory={addToStory} isLoading={isLoading}/>
                    </div>}
            </div>                    
            <Help category={"PUBLISHING"} pageName={"Adding Photos"}></Help>
            <UploadLimitReachedModal show={uploadLimitReachedModal} setUploadLimitReachedModal={setUploadLimitReachedModal} />    
        </div>
    );
}