import Icon from '@mdi/react';
import moment from "moment";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import instance from "../../../api/api";
import { Context } from "../../../pages/home.page";
import default_avatar from "../../../assets/images/default-avatar.jpg";
import { IRequest } from "./story-recieved-requests.component";
import { mdiCheckCircle, mdiBlockHelper, mdiClock } from '@mdi/js';
import { dateFormat } from '../../../_helper/enum/enum';
import { defaultUserData } from '../../../interfaces/family';
import Carousel from "react-multi-carousel";
import { CustomLeftArrow, CustomRightArrow } from '../../../pages/dashboard/story/story-details.page';
import Notification, { notificationImages } from "../../../components/notifications/notification.component"
import { NotificationType } from "../../../interfaces/notification"
import config from '../../../config/config';
import AttachedStory from './attached-story-component';
import AttachedInspiration from './attached-inspiration-component';
import { getStatus } from './story-sent-requests.component';
import DOMPurify from 'dompurify';
import CommentsContainer, { ECommentType } from '../../comments/comments-container.component';
import feather from "../../../assets/images/feather.svg";

interface IProps {
    type: "sent" | "received" | "storyTab"
    setList:Function
    updateList:Function
    request:IRequest | boolean
    scroll?:boolean    
}

export const StoryRequestNotifications = {
    hidden: "",
    default: { icon: notificationImages.crying, title: "", text: "", success: NotificationType.problem },
    requestDeclined: { icon: notificationImages.happiness, title: "Success", text: "The request has been declined.", success: NotificationType.success },
    requestDeleted: { icon: notificationImages.happiness, title: "Success", text: "The request has been deleted.", success: NotificationType.success },
    requestFailedToDelete: { icon: notificationImages.crying, title: "Warning", text: "Failed to delete request.", success: NotificationType.problem },
    teamInformed: { icon: notificationImages.happiness, title: "Success", text: "Your team has been informed.", success: NotificationType.success },
    missingYearError: { icon: notificationImages.crying, title: "Warning", text: "The year of the story is a required field.", success: NotificationType.problem },
}

export enum RequestStatus {
    PENDING="PENDING",
    ACCEPTED="ACCEPTED",
    DECLINED="DECLINED"
}

export default function StoryRequest(props:IProps) {

    const [ request, setRequest ] = useState<IRequest>({_id:"", title:"", to:[], notes:"", from:defaultUserData, createdAt:new Date()});
    const [ noOfSliderItemsToShow, setNoOfSliderItemsToShow ] = useState<number>(1);
    const [ showNotification, setShowNotification] = useState<any>(StoryRequestNotifications.hidden)

    const userData = useContext(Context).user;
    const history = useHistory();

    const location: any = useLocation();
    const commentTopRef = useRef<any>(null);

    useEffect(() => {
        if(props.type!=='storyTab') {
            (props.request as IRequest).to.length<=1 ? setNoOfSliderItemsToShow(1) : (props.request as IRequest).to.length === 2 ? setNoOfSliderItemsToShow(2) : setNoOfSliderItemsToShow(3);
        }

        // set received request to read when component first clicked
        if(props.type==='received' && !(props.request as IRequest).to.filter(x=>x.user._id === userData._id)[0].read) {
            setRequestRead(props.request as IRequest);
        }

        setRequest(props.request as IRequest);        
    }, []);

    useEffect(() => {
            if(props.scroll && commentTopRef.current!==null) {         
                setTimeout(() => {
                    commentTopRef.current.scrollIntoView();
                }, 800);
            }
    }, [commentTopRef.current])        

    async function setRequestRead(request:IRequest) {

        try {
            let meUser = request.to.filter(x=>x.user._id === userData._id)
            meUser[0].read = true;

            const others = request.to.filter(x=>x.user._id !== userData._id)

            const allToUsers = [...others, ...meUser]

            const data = {
                data: {
                    to: allToUsers
                }
            }

            await instance.put('/requests/' + request._id, data);

            //TODO: low: have to update "received list"
        } catch(err) {
            console.log('Error', err)
        }
    }

    async function acceptRequest(request:IRequest) {

        try {
            let meUser = request.to.filter(x=>x.user._id === userData._id)
            meUser[0].status = RequestStatus.ACCEPTED;

            const others = request.to.filter(x=>x.user._id !== userData._id)

            const allToUsers = [...others, ...meUser]

            const data = {
                data: {
                    to: allToUsers
                }
            }

            const response = await instance.put('/requests/' + request._id, data);

            history.push('/dashboard/create', {data: response.data})
        }
        catch(err) {
            console.log('Error', err)
        }
    }

    async function declineRequest(request:IRequest) {
        try {
            let meUser = request.to.filter(x=>x.user._id === userData._id)
            meUser[0].status = RequestStatus.DECLINED;

            const others = request.to.filter(x=>x.user._id !== userData._id)

            const allToUsers = [...others, ...meUser]

            const data = {
                data: {
                    to: allToUsers
                }
            }

            await instance.put('/requests/' + request._id, data);

            setShowNotification(StoryRequestNotifications.requestDeclined);

            setTimeout(() => {
                setShowNotification(StoryRequestNotifications.hidden);
                props.setList(true); //TODO: medium: refresh list view as deleted is still there
                props.updateList(true);
            }, config.modalCloseTimeout);
        }
        catch(err) {
            console.log('Error', err)
        }

    }
/*
    async function declineRequest(request:any) {
        try {
            await instance.delete('/requests/' + request._id);

            setShowNotification(StoryRequestNotifications.requestDeleted);

            setTimeout(() => {
                setShowNotification(StoryRequestNotifications.hidden);
                props.setList(true); //TODO: medium: refresh list view as deleted is still there
                props.updateList(true);
            }, config.modalCloseTimeout);
        }
        catch(err) {
            console.log('Error', err)
        }

    }
*/
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: noOfSliderItemsToShow
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: noOfSliderItemsToShow
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: noOfSliderItemsToShow
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: noOfSliderItemsToShow
        }
    };

    return(
        <div className="story-request story-details">
            <div className="story-container main-info whats-next">
                {props.type!=='storyTab' ? <p className='title'>{request.title}</p> : <p className='title'>Story requested by</p>}
                {props.type==='sent' &&
                    <div className={`status-background ${getStatus(request)}`}>
                        {getStatus(request)}
                    </div>
                }
                {props.type!=='sent' ?
                    <div className="from-user user-avatar">
                        {props.type!=='storyTab' && <p className='date'>Requested by:</p>}
                        <img className="user-picture" src={request.from.avatar ? request.from.avatar : default_avatar} alt="user" />
                        <p className="from">{request.from.nickname ? request.from.nickname  : request.from.firstname}</p>
                    </div>
                : null }
                {props.type!=='storyTab' ?
                    <div className="to-users whats-next-body">
                        <p className="date">Shared with:</p>
                        <div className="carousel-container">
                            <Carousel responsive={responsive} customLeftArrow={<CustomLeftArrow />} customRightArrow={<CustomRightArrow />}>
                                {[...request.to.map( x => {return {'user': x.user, 'status': x.status}} )].map((to) => {
                                    return(
                                        <div key={to.user._id} className={userData.relations.filter(x => x.userid._id === to.user._id).length ? "featured" : "featured no-click"}
                                            onClick={() => userData.relations.filter(x => x.userid._id === to.user._id).length ? history.push('/dashboard/profile/' + to.user._id) : null}>
                                            <img className="user-picture" src={to.user.avatar ? to.user.avatar : default_avatar} alt="user" />
                                            <p className="name">
                                                {to.user.nickname ? to.user.nickname : `${to.user.firstname}`}
                                            </p>
                                            {props.type==='sent' &&
                                                <div className={`status ${to.status} name`}>
                                                    <Icon className="action" size={ to.status === RequestStatus.ACCEPTED ? 0.8 : to.status === RequestStatus.DECLINED ? 0.6 : 0.8 } path={ to.status === RequestStatus.ACCEPTED ? mdiCheckCircle : to.status === RequestStatus.DECLINED ? mdiBlockHelper : mdiClock } />
                                                    <span>{to.status}</span>
                                                </div>}
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                : null }
                {request.createdAt && (
                    <p className="date">
                       {moment(request.createdAt).format(dateFormat.SHORT)}
                    </p>
                )}
            </div>
            {props.type==='storyTab' && <div className="separator"></div>}
            <div className="story-body">
                <div className="display-block-text">
                    <div className="text-data">
                        <p>{DOMPurify.sanitize(request.notes)}</p>
                    </div>
                </div>
            </div>
            {props.type==='received' ?
                <div className='buttons'>
                    <button className="default-button" disabled={showNotification} onClick={() => acceptRequest(request)}>Create Story</button>
                    <button className="link-button cancel-button"  disabled={showNotification} onClick={() => declineRequest(request)}>Politely Decline</button>

                    {showNotification ? <Notification data={showNotification} close={() => setShowNotification(StoryRequestNotifications.hidden)} /> : null}
                </div>
            : null }
            {((props.request as IRequest).attachedStory as string) ? <AttachedStory storyId={((props.request as IRequest).attachedStory as string)} /> : null}
            {((props.request as IRequest).attachedInspirationQ as string) ?
                <AttachedInspiration
                    inspirationQuestionId={((props.request as IRequest).attachedInspirationQ as string)}
                /> : null}
            <div className="new-block">
                <div className="add-story-svg">
                    <img src={feather} alt="" style={{zIndex: 100, position: "relative", top: "-8px"}} />
                    <div className="line"></div>
                </div>
            </div>
            {request._id.length ? <div ref={commentTopRef} className="comment-section">
                <CommentsContainer accessToken={(props.request as IRequest).accessCommentToken || ""} commentsEnabled={getStatus(props.request as IRequest) === RequestStatus.PENDING} title={"Comments"} related_entity={request._id} access_entity={request._id} comment_type={ECommentType.REQUEST} />
            </div> : null}
            {/*<button className="cancel-button" disabled={showNotification} onClick={() => props.setList(true)}>Back To List</button>*/}
            <button className="cancel-button" onClick={() => window.scrollTo(0, 0)}>Back To Top</button>
        </div>
    );
}
