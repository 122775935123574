import { mdiCamera, mdiCheckCircle, mdiMicrophoneOutline, mdiViewHeadline, mdiHelpCircle, mdiLock, mdiCodeTags, mdiLinkPlus, mdiPaperclip, mdiScriptTextOutline, mdiVideo } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import instance from '../../api/api';
import { IUserDetails } from '../../interfaces/authentication';
import { IOption } from '../../interfaces/family';
import { blockTypes } from '../../interfaces/story';
import { Context } from '../../pages/home.page';
import TooltipHelp from '../notifications/tooltiphelp.component';
import { getAccesForBlock } from '../_helper/limit-checkers';
import UniqueUserListInput from '../_helper/unique-selects/unique-user-list-input.component';
import UniqueSingleSelect from '../_helper/unique-single-select.component';
import { useTranslation } from "react-i18next";
import { IdefBlock } from './blocks/block-picker.component';

interface IProps {

}

export default function StoryLaunchPad(props:IProps) {

    const [ featured, setFeatured ] = useState<IOption>({_id:'', text:'', img:''});
    const [ title, setTitle ] = useState<string>("")
    const [ coAuthors, setCoAuthors ] = useState<IUserDetails[]>([])
    const [ starterBlock, setStarterBlock ] = useState<blockTypes>(blockTypes.TEXT);

    const [ canFeature, setCanFeature ] = useState<IOption[]>([]);

    //Tooltips
    const [featuredProfileTTShow, setFeaturedProfileTTShow ] = useState<boolean>(false);
    const [coAuthorTTShow, setCoAuthorTTShow ] = useState<boolean>(false);
    const [autosaveTTShow, setAutosaveTTShow] = useState<boolean>(false);

    //limit helper
    const [ disabledBlocks, setDisabledBlocks ] = useState<string[]>([]);

    const [isMobile, setIsMobile] = useState(false);

    const history = useHistory();
    const loggedInUserData = useContext(Context).user;

    const location: any = useLocation();

    const { t } = useTranslation();

    let defaultBlocks:IdefBlock[] = [
        {type:blockTypes.TEXT, icon:mdiViewHeadline, titleText:t("Add Text"), text:"Text", active:true},
        {type:blockTypes.IMAGE, icon:mdiCamera, titleText:t("Add Photos"), text:"Photos", active:true},
        {type:blockTypes.VIDEO, icon:mdiVideo, titleText:t("Add Video"),  text:"Video", active:true},
        {type:blockTypes.AUDIO, icon:mdiMicrophoneOutline, titleText:t("Add Audio"), text:"Audio", active:true},
        {type:blockTypes.EMBED, icon:mdiCodeTags, titleText:t("Embed"), text:"Embed", active:true},
        {type:blockTypes.LINK_ALBUM, icon:mdiLinkPlus, titleText:t("Link Album"), text:"Link Album", active:true},
        {type:blockTypes.ATTACHMENT, icon:mdiPaperclip, titleText:t("Add Attachment"), text:"Attachment", active:true},
        {type:blockTypes.LIFE_LESSON, icon:mdiScriptTextOutline, titleText:t("Add Life Lesson"), text:"Life Lesson", active:true}
    ]    

    //Check if the user can feature someone else
    useEffect(() => {
        const self:IOption = {_id:loggedInUserData._id, text:`${loggedInUserData.firstname} ${loggedInUserData.lastname}`, img:loggedInUserData.avatar ? loggedInUserData.avatar : ''}
        //TODO: this is not working right now properly.
        if (loggedInUserData._id.length) {
            getUserFeaturedBy(self);
            setFeatured(self);
            checkCanUpload()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserData])

    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    }, [window])

    async function checkCanUpload() {
        const disabled_blocks = await getAccesForBlock(loggedInUserData, defaultBlocks)
        setDisabledBlocks(disabled_blocks)
    }

    async function getUserFeaturedBy(self:IOption) {

        try {
            const response = await instance.get(`/user/${loggedInUserData._id}/featuredby`);
            const resOption = response.data.map( (x:IUserDetails) => {return {_id:x._id, text:`${x.firstname} ${x.lastname}`, img:x.avatar ? x.avatar : ''}})
            setCanFeature([...resOption, self])

        }
        catch(error) {
            console.log('Error in launchpad', error)
        }
    }

    async function startCreate() {
        try {

            let storyData : any = {
                title:title,
                author: loggedInUserData._id,
                featured:featured._id,
                coAuthors:coAuthors.map(x => x._id),
                date: {
                    startDate: {
                        date: moment().toDate(),
                        year: new Date().getFullYear(),
                        month: new Date().getMonth(),
                        day: new Date().getDate()

                    }
                },
                blocks:[]
            }

            if(location.state && location.state.storyTag.length) {
                storyData = {...storyData, storyTags: [location.state.storyTag.toUpperCase()]}
            }

            const response = await instance.post('/stories', storyData);
            history.push('/dashboard/create', {data: response.data, starterBlock:starterBlock} );
        }
        catch(error) {
            console.log('Error', error)
        }
    }

    function updateCoAuthors(selectedData:IUserDetails) {
        if(coAuthors.filter((data:IUserDetails) => data._id === selectedData._id).length) {
            const temp = coAuthors.filter((data:IUserDetails) => data._id !== selectedData._id);
            setCoAuthors(temp);
        }
        else {
            setCoAuthors((prev:IUserDetails[]) => { return [...prev, selectedData]})
        }
    }

    return(
        <div className="story-launchpad">
            <div className="group title-input">
                <input autoFocus={!isMobile} placeholder={t("Story title")} value={title} onChange={(evt) => evt.target.value.length <= 50 ?  setTitle(evt.target.value) : null}></input>
                <label className="top-label">{t("Story title")}<span>*</span></label>
                <p className="sub-subtitle right">{title.length} / 50</p>
            </div>
            {canFeature.length > 1 && <div className="unique-select-container">
                <UniqueSingleSelect placeholder={t("Featured profile")} handleAction={(data:IOption) => setFeatured(data)} data={featured} options={canFeature}/>
                <p onClick={() => setFeaturedProfileTTShow(true)} className="tooltip-text">{t("What's this?")}</p>
            </div>}
            <div className="group">
                <p className="gold-title">{t("OPTIONALLY ADD CO-AUTHORS TO HELP")}</p>
                <UniqueUserListInput placeholder={t("Add co-author")} handleAction={updateCoAuthors} data={coAuthors} options={loggedInUserData.relations.map(x => x.userid)}/>
                <p onClick={() => setCoAuthorTTShow(true)} className="tooltip-text">{t("What's this?")}</p>
            </div>
            {/* MLP2-1102 <div className="group">
                <p className="gold-title">{t("STARTING STORY BLOCK")}</p>
                <Row>
                    <Col className={`block ${starterBlock === blockTypes.TEXT ? "active" : ""}`} onClick={() => setStarterBlock(blockTypes.TEXT)}>
                        <Icon className="text" size={1} path={mdiViewHeadline} />
                        <p>{t("Text")}</p>
                        {starterBlock === blockTypes.TEXT && <Icon className="selected-icon" size={0.7} path={mdiCheckCircle}/>}

                    </Col>
                    <Col className={`block ${(starterBlock === blockTypes.IMAGE && !disabledBlocks.includes(blockTypes.IMAGE)) ? "active" : disabledBlocks.includes(blockTypes.IMAGE) ? "disabled" : ""} `} onClick={() => disabledBlocks.includes(blockTypes.IMAGE) ? null : setStarterBlock(blockTypes.IMAGE)}>
                        <Icon className="image-icon" size={1} path={mdiCamera} />
                        <p>{t("Image")}</p>
                        {starterBlock === blockTypes.IMAGE && <Icon className="selected-icon" size={0.7} path={mdiCheckCircle}/>}
                        {disabledBlocks.includes(blockTypes.IMAGE) && <Icon className="disabled-icon" size={1.1} path={mdiLock}/>}
                    </Col>
                    <Col className={`block ${(starterBlock === blockTypes.AUDIO && !disabledBlocks.includes(blockTypes.AUDIO)) ? "active" : disabledBlocks.includes(blockTypes.AUDIO) ? "disabled" : ""} `} onClick={() => disabledBlocks.includes(blockTypes.AUDIO) ? null : setStarterBlock(blockTypes.AUDIO)}>
                        <Icon className="image-icon" size={1} path={mdiMicrophoneOutline} />
                        <p>{t("Audio")}</p>
                        {starterBlock === blockTypes.AUDIO && <Icon className="selected-icon" size={0.7} path={mdiCheckCircle}/>}
                        {disabledBlocks.includes(blockTypes.AUDIO) && <Icon className="disabled-icon" size={1.1} path={mdiLock}/>}
                    </Col>
                </Row>
            </div>*/}
            <div className="button-container">
                <button className="default-button" disabled={!title.length} onClick={() => startCreate() }>{t("Start")}</button>
                {title.length ?
                <div className="autosave-container"> 
                    <p className="autosave-text">{t("Story autosaves")}</p>
                    <button onClick={() => setAutosaveTTShow(true)}><Icon className='image-icon' size={0.8} path={mdiHelpCircle} /></button>
                </div>
                 : null}
            </div>
            <TooltipHelp contextKey={'story_info_featured'} show={featuredProfileTTShow} clear={() => setFeaturedProfileTTShow(false)} />
            <TooltipHelp contextKey={'story_info_co_authors'} show={coAuthorTTShow} clear={() => setCoAuthorTTShow(false)} />
            <TooltipHelp contextKey={'story_info_stories_autosave'} show={autosaveTTShow} clear={() => setAutosaveTTShow(false)} />
        </div>
    );
}