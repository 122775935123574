import React, { useEffect } from "react"

const HubspotContactForm = () => {
    const region = window.__RUNTIME_CONFIG__.REACT_APP_HUBSPOT_NEWSLETTER_REGION
    const portalId = window.__RUNTIME_CONFIG__.REACT_APP_HUBSPOT_NEWSLETTER_PORTALID
    const formId = window.__RUNTIME_CONFIG__.REACT_APP_HUBSPOT_NEWSLETTER_FORMID

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "//js-eu1.hsforms.net/forms/embed/v2.js"
        script.type = "text/javascript"
        document.body.appendChild(script)

        script.addEventListener("load", () => {
            if (window.hbspt && region && portalId && formId) {
                window.hbspt.forms.create({
                    region: region,
                    portalId: portalId,
                    formId: formId,
                    target: "#hubspotForm",
                })
            }
        })

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    )
}

export default HubspotContactForm
