import React, { useContext, useState } from "react"
import { Modal, Button } from "react-bootstrap"
import Icon from '@mdi/react'
import { mdiFileTree, mdiDoorClosed, mdiAccountMinus, mdiEmailOutline } from '@mdi/js';
import instance from "../../api/api"
import Notification, { notificationImages } from '../notifications/notification.component'
import { NotificationType, INotification } from "../../interfaces/notification"
import ChangeRelationship from "./profile-actions/change-relationship.component"
import { IInvitation, IPendingRelationDB, IRelation } from "../../interfaces/family"
import { useHistory } from "react-router"
import { Context } from "../../pages/home.page";

export enum UserRelationStatus {
    PENDING = 1,
    ACTIVE = 2,
    DECEASED = 3,
    ADMIN = 4,
    SENT = 5,
    INVITATION = 6,
    SELF = 7,
    SUGGESTION = 8
}

enum ActionStates {
    DEFAULT = "",
    CHAT = "Chat",
    CHANGE_RELATIONSHIP = "Relationship",
    USER_DECEASED = "User deceased",
    REMOVE_FROM_FAMILY = "Remove from Family"
}

interface IModalProps {
    userid: string
    show: boolean
    handleClose: () => void
    relation: IRelation | IPendingRelationDB | IInvitation
    userRelationType: UserRelationStatus
    myProfile: boolean
    declineRelation: Function
    updateRelationship: Function
    administrator: boolean
}

function ProfileAction(props: IModalProps) {

    const [ notification, setNotification ] = useState<INotification>({icon:"", title:"", text:"", success:NotificationType.problem});
    const [actionState, setActionState] = useState(ActionStates.DEFAULT);

    const [isRelTypesBoxActive, setIsRelTypesBoxActive] = useState(false);

    const history = useHistory();

    const updateLoggedInUserData = useContext(Context).setUserDetails;    

    async function handleRemovePendingRelation() {
        const relation = (props.relation as IPendingRelationDB);
        try {

            await instance.delete("/relation/pending", { data: {id: relation._id} });

            props.declineRelation(props.relation._id)
            props.handleClose()

        } catch (error) {

            setNotification({
                icon:notificationImages.crying, title:"Warning", text:"Failed to remove pending relation", success:NotificationType.problem
            })
        }
    }

    async function handleRemoveFromFamily() {
        try {
            const response = await instance.delete(`/relation/${props.relation._id}`, {data: { userId: props.myProfile ? localStorage.userid : props.userid}})
            // update Context only if this is Me
            if(props.myProfile) {
                updateLoggedInUserData(response.data);
            }
            props.declineRelation(props.relation._id)
            props.handleClose()
        } catch (error) {
            setNotification({
                icon:notificationImages.crying, title:"Warning", text:"Failed to remove relation", success:NotificationType.problem
            })
        }
    }

    async function handleRemoveInvitation() {
        try {
            await instance.delete(`/invitation/${props.relation._id}`)
            props.declineRelation(props.relation._id)
            props.handleClose()
        } catch (error) {
            setNotification({
                icon:notificationImages.crying, title:"Warning", text:"Failed to remove invitation", success:NotificationType.problem
            })
        }
    }

    async function handleUpdateRelationship(relation:IRelation) {
        try {
            // update Context only if this is Me
            if(props.myProfile) {                
                let responseUserUpdated = await instance.get('/user/' + props.userid );
                updateLoggedInUserData(responseUserUpdated.data);
            }
            props.updateRelationship(relation);
        } catch (error) {
            setNotification({
                icon:notificationImages.crying, title:"Warning", text:"Failed to update relationship", success:NotificationType.problem
            })
        }
    }    

    async function resendInvitation() {
        try {

            let invitationData:IInvitation = {
                inviter:localStorage.userid,
                email: (props.relation as IInvitation).email,
                explanation: (props.relation as IInvitation).explanation,
                relationType: (props.relation as IInvitation).relationType,
                suggestions:[],
            }

            await instance.post('/invitation', invitationData);
            setNotification({
                icon:notificationImages.happiness, title:"Success", text:"Invitation sent.", success:NotificationType.success
            })
            
        } catch (error) {
            setNotification({
                icon:notificationImages.crying, title:"Warning", text:"Failed to resend invitation.", success:NotificationType.problem
            })
        }
    }

    function resetNotification() {
        setNotification({icon:"", title:"", text:"", success:NotificationType.problem});
    }

    function getActions() {
        switch (props.userRelationType) {
            case UserRelationStatus.ACTIVE:
                return (
                    <div>
                        {/*!props.administrator ? 
                        <div className="link-row">
                            <Icon size={1.5} path={ mdiForumOutline }/><p>Chat</p>
                        </div> : null*/}
                        {(props.myProfile || props.administrator) ?
                            <div>
                                <div className="link-row" onClick={() => setActionState(ActionStates.CHANGE_RELATIONSHIP)}>
                                    <Icon className="icon" size={1.5} path={ mdiFileTree }/><p>Relationship</p> 
                                </div>
                                <div className="link-row" onClick={() => history.push({pathname: '/dashboard/deceased/', state: { userid: (props.relation as IRelation).userid._id } })} >
                                    <Icon className="icon black" size={1.5} path={ mdiDoorClosed }/><p>User deceased</p>
                                </div>
                                <div className="link-row" onClick={() => setActionState(ActionStates.REMOVE_FROM_FAMILY)}>
                                    <Icon className="icon red" size={1.5} path={ mdiAccountMinus }/><p>Remove from Family</p>
                                </div>
                            </div> : null}
                    </div>
                )
            case UserRelationStatus.DECEASED:
                return (
                    <div>
                        <div className="link-row" onClick={() => setActionState(ActionStates.CHANGE_RELATIONSHIP)}>
                        <Icon className="icon" size={1.5} path={ mdiFileTree }/><p>Relationship</p>
                        </div>
                        <div className="link-row" onClick={() => setActionState(ActionStates.REMOVE_FROM_FAMILY)}>
                            <Icon className="icon red" size={1.5} path={ mdiAccountMinus }/><p>Remove from Family</p>
                        </div>
                    </div>
                )
            case UserRelationStatus.PENDING:
                return (
                    <div className="link-row">
                        <Icon className="icon red" size={1.5} path={ mdiAccountMinus }/><p onClick={handleRemovePendingRelation}>Remove from Family</p>
                    </div>
                )
            case UserRelationStatus.ADMIN:
                return (
                    <div>
                        <p><Icon className="icon" size={1.5} path={ mdiAccountMinus }/>ADMIN</p>
                    </div>
                )
            case UserRelationStatus.INVITATION:
                return (
                    <div>
                        <div className="link-row">
                            <Icon className="icon" size={1.5} path={ mdiEmailOutline }/><p onClick={resendInvitation}>Resend invitation</p>
                        </div>
                        <div className="link-row">
                            <Icon className="icon red" size={1.5} path={ mdiAccountMinus }/><p onClick={handleRemoveInvitation}>Remove from Family</p>
                        </div>
                    </div>
                )
            default:
                break;
        }
    }

    function handleClose() {
        props.handleClose()
        setIsRelTypesBoxActive(false)
        setActionState(ActionStates.DEFAULT)
        resetNotification()
    }

    return (
        <div>
            <Modal show={props.show} onHide={handleClose} className={isRelTypesBoxActive ? "profile-action options-active" : "profile-action"}>
                <Modal.Header closeButton>
                    <Modal.Title>{actionState}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="body">
                    {actionState === ActionStates.DEFAULT && getActions()}
                    {actionState === ActionStates.CHANGE_RELATIONSHIP && <ChangeRelationship handleIsRelTypesBoxActive={setIsRelTypesBoxActive} handleClose={handleClose} relation={(props.relation as IRelation)} updateRelationship={handleUpdateRelationship} userid={props.myProfile ? localStorage.userid : props.userid}/>}
                    {actionState === ActionStates.REMOVE_FROM_FAMILY &&
                        <div className="remove-from-family">
                            <p>This person will be removed from your family list and you will be removed from theirs. You will no longer have access to each others profiles.</p>
                            <p><b>WARNING</b> - If they are your Admin or Successor update your choice post deletion.</p>
                            <div className="button-group">
                                <Button className="default-button proceed" onClick={handleRemoveFromFamily} >Proceed</Button>
                                <Button className="cancel-button" onClick={handleClose}>Cancel</Button>
                            </div>
                        </div>
                    }
                    {notification.title.length ? <Notification data={notification} close={() => resetNotification()}/>  : null}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProfileAction
