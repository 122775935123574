import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import instance from '../../api/api';
import { setCurrentPage } from '../../store/actionCreators';
import logo from "../../assets/images/logo2_with_tagline_vertical.svg"
import { Row, Col } from 'react-bootstrap';
import { mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";

interface RouteParams {
    id: string
    lang?: string
}

const Referal: React.FunctionComponent<{}> = props => {

    const [ valid, setValid ] = useState<boolean>(false);
    const [ owner, setOwner ] = useState<string>('')

    const { t } = useTranslation();
    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);
    setPageName(t("Your Gift"));

    const params = useParams<RouteParams>();
    const history = useHistory();

    useEffect(() => {
        if(params.lang) {
            i18n.changeLanguage(params.lang);
        }        
        getRefererSubscription();
    }, [])

    async function getRefererSubscription() {
        try {
            const response = await instance.get("/subscriptions/public/" + params.id);
            setValid(response.data.valid);
            setOwner(response.data.owner_name)
        }
        catch(err:any) {
            console.log('error', err)
        }
    }

    return (
        <div className="referral-page-container">
            <Row className="header">
                <Col className="back-btn">
                </Col>
                <Col className="page-title">
                    <p>{t("Your Gift")}</p>
                </Col>
                <Col className="menu-container">
                </Col>
            </Row>
            <div className='content'>
                {valid ? 
                    <>
                        <div className="text-data-container">
                            <div className="teszt">
                                <div className='logo-container'>
                                    <img alt='simirity' src={logo} />
                                </div>
                                <div className="text-data-content">
                                    <p className="title-gold">{t("Free Family Subscription")}</p>
                                    <div className="info-container">
                                        <p className="owner-text">{owner} {t("and family")}</p>
                                        <p className='owner-text'>{t("have gifted you a 90 day")}</p>
                                        <p className='owner-text'>{t("Family Subscription worth $30")}*</p>
                                    </div>
                                    <p className='join'>{t("Join today. No credit card needed.")}</p>
                                    <iframe src={`https://player.vimeo.com/video/849109482`} width="460" height="238" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                                    {/*<div className="video-row" onClick={() => window.open('https://www.youtube.com/watch?v=gK4JuIdxg2I&ab_channel=Simirity', '_blank', 'noopener,noreferrer')}>
                                        <div className="play-icon">
                                            <Icon size={1.6} path={mdiPlay}/>
                                        </div>
                                    </div> */}
                                    {/*<p className="link-video" onClick={() => window.open('https://www.youtube.com/watch?v=gK4JuIdxg2I&ab_channel=Simirity', '_blank', 'noopener,noreferrer')}>{t("Intro video")}</p>*/}
                                    <p className="link-text" onClick={() => window.open('https://www.simirity.com/', '_blank', 'noopener,noreferrer')}>{t("LEARN ABOUT SIMIRITY")}</p>
                                    <div className="button-container">
                                        <button className='default-button' onClick={() => history.push({ pathname: `/register${params.lang ? "/"+params.lang : ''}`, state: { referral: params.id } })}>{t("Join with free subscription")}</button>
                                        <p className="up-to-family-grey-text">{t("Up to 10 family members can use your Subscription and together upload up to 10GB in media files")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> 
                    : 
                    <>
                        <div className="offer-expired-page">
                            <div className='logo-container'>
                                <img alt='simirity' src={logo} />
                            </div>
                            <div className="text-data-container">
                                <p className="">{t("Offer expired")}</p>
                                <div className="info-container">
                                    <p className='related-sub-text'>{t("Please contact the person who invited you for a valid link")}</p>
                                </div>
                            </div>
                            <div>
                                <p className="link-text" onClick={() => window.open('https://www.simirity.com/', '_blank', 'noopener,noreferrer')}>{t("LEARN ABOUT SIMIRITY")}</p>
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    );
}

export default Referal;
