import React, { useState, useEffect, useCallback, useContext } from "react"
import { Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { setCurrentPage } from "../../../store/actionCreators"
import { Context } from "../../home.page"
import { IMediaQuality } from "../../../interfaces/authentication"
import instance from "../../../api/api"
import Help from "../../../components/_helper/help.component"
import { IOption } from "../../../interfaces/family"
import config from "../../../config/config"
import UniqueSingleSelect from "../../../components/_helper/unique-single-select.component"
import Notification, { notificationImages } from "../../../components/notifications/notification.component"
import { NotificationType } from "../../../interfaces/notification"
import ChangePasswordModal from "../../../components/settings/change-password.modal"
import ChangeEmailModal from "../../../components/settings/change-email.modal"

// icons
import Icon from "@mdi/react"
import { mdiGreasePencil } from "@mdi/js"

function AccountDataSettings() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // set page name
    useEffect(() => {
        setPageName("Account Data")
    }, [])
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch])

    const user = useContext(Context).user

    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [showEmailModal, setShowEmailModal] = useState(false)

    return (
        <div className="settings final account-data">
            <div className="account-data-container">
                <Row>
                    <Col xs={4}>
                        <p className="">{t("Password")}</p>
                    </Col>
                    <Col xs={6}>
                        <p className="current">********</p>
                    </Col>
                    <Col xs={2}>
                        <span onClick={() => setShowPasswordModal(true)}>
                            <Icon className="edit-icon" path={mdiGreasePencil} />
                        </span>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={4}>
                        <p className="">{t("Email")}</p>
                    </Col>
                    <Col xs={6}>
                        <p className="current">{user.email}</p>
                    </Col>
                    <Col xs={2}>
                        <span onClick={() => setShowEmailModal(true)}>
                            <Icon className="edit-icon" path={mdiGreasePencil} />
                        </span>
                    </Col>
                </Row> */}
            </div>
            <ChangePasswordModal show={showPasswordModal} close={() => setShowPasswordModal(false)} />
            {/* <ChangeEmailModal show={showEmailModal} close={() => setShowEmailModal(false)} /> */}
        </div>
    )
}

export default AccountDataSettings
