import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { mdiDeleteForever } from '@mdi/js';
import Icon from '@mdi/react';
import instance from '../../../api/api';
import { IRequest } from './story-recieved-requests.component';
import { StoryRequestNotifications } from './story-request-component';
import config from '../../../config/config';
import Notification from "../../../components/notifications/notification.component"
import ConfirmModal from '../../_helper/confirm-modal.component';

interface IProps {
    show:boolean
    onHide:Function
    updateList: Function
    request:IRequest
}

export default function StoryRequestActionModal(props:IProps){

    const [ showNotification, setShowNotification] = useState<any>(StoryRequestNotifications.hidden);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    async function declineRequest() {
        try {
            await instance.delete('/requests/' + props.request._id);

            setShowConfirmModal(false);            
            setShowNotification(StoryRequestNotifications.requestDeleted);

            setTimeout(() => {              
                setShowNotification(StoryRequestNotifications.hidden);
                props.onHide();
                props.updateList(true);          
            }, config.modalCloseTimeout);
        }
        catch(err) {
            setShowNotification(StoryRequestNotifications.requestFailedToDelete);            
            console.log('Error', err)
        }        

    }

    return(
        <Modal className="story-action-modal" show={props.show} onHide={props.onHide}>
            <Modal.Header className="" closeButton>
            </Modal.Header>
            <Modal.Body className="edit-card-content">
                <div className="link-row" onClick={() => setShowConfirmModal(true)}>
                    <Icon size={1} path={ mdiDeleteForever } /><p className="main-text-info">Delete Request</p>
                    <p className="info-text">Your request is cancelled.</p>
                </div>
                {showNotification ? <Notification data={showNotification} close={() => setShowNotification(StoryRequestNotifications.hidden)} /> : null}                
            </Modal.Body>
            <ConfirmModal
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                modalTitle="Unsaved Information"
                modalBody={() => (
                    <div>
                        <p>This story request will be deleted fom everyone.</p>
                    </div>
                )}
                reject={() => {
                    props.onHide();
                    setShowConfirmModal(false);
                }}
                confirm={declineRequest}
            />            
        </Modal>
    );
}