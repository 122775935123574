import React from "react"
import { Modal } from "react-bootstrap"

interface IProps {
    show: boolean
    setUploadLimitReachedModal: Function
}

function UploadLimitReachedModal(props: IProps) {
    return (
        <Modal className="upload-limit-reached-modal request-access-story-modal" backdrop="static" show={props.show} onHide={() => props.setUploadLimitReachedModal(false)}>
            <Modal.Header>
                <Modal.Title>Upload Limit Reached</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                        props.setUploadLimitReachedModal(false)
                    }}></button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p className="would-like">You cannot upload additional photos, audio, video or attachments. Contact your Subscription owner.</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadLimitReachedModal
