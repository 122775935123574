import React, { useContext, useEffect, useState } from 'react';
import { IUserDetails } from '../../interfaces/authentication';
import UniqueUserListInput from '../_helper/unique-selects/unique-user-list-input.component';
import { Context } from '../../pages/home.page';
import { IOption, IRelation } from '../../interfaces/family';
import TooltipHelp from '../notifications/tooltiphelp.component';
import UniqueListInput from '../_helper/unique-list-input.component';
import config from '../../config/config';
import { ILifelessonFilters } from './story-lifelessons.component';

interface IProps {
    filters:ILifelessonFilters
    setFilters:Function
    setShowFilters:Function
    queryString:string
    count: Number
}

export default function StoryLifelessonsFilters(props:IProps) {

    const [ showFeaturedTooltip, setShowFeaturedTooltip ] = useState<boolean>(false);

    const [ userOptions, setUserOptions ] = useState<IUserDetails[]>([]);

    const userData = useContext(Context).user;

    //Setup options
    useEffect(() => {
        if(userData._id.length) {
            setUserOptions([...userData.relations.map((relation:IRelation) => relation.userid), userData])
        }
    }, [])

    function updateUserFilterField(key:string, value:IUserDetails) {
        const listType = (props.filters[key] as IUserDetails[]).map(x => x._id);
        if(listType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function updateIOptionField(key:string, value:IOption) {
        const optionType = (props.filters[key] as IOption[]).map(x => x._id);
        if(optionType.includes(value._id)) {
            props.setFilters({...props.filters, [key]: props.filters[key].filter((x:IUserDetails) => x._id !== value._id)})
        }
        else {
            props.setFilters({...props.filters, [key]:[...props.filters[key], value]})
        }
    }

    function showStories() {
        props.setShowFilters(false);
    }

    return(
        <div className='story-filters'>
            <p className='filter-group-title'>Filters</p>
            <div className="people">
                    <UniqueUserListInput placeholder='Life Lesson Author' data={props.filters.author} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("author", value)}/>
                    <UniqueListInput placeholder='Life Lesson Category' icon={true} data={props.filters.lifelessonTags} options={config.lifelessonTags} handleAction={(value:IOption) => updateIOptionField("lifelessonTags", value)}/>
                <div className="with-tooltip">
                    <UniqueUserListInput placeholder='In Stories belonging to' data={props.filters.featured} options={userOptions} handleAction={(value:IUserDetails) => updateUserFilterField("featured", value)}/>
                    {/*<p onClick={() => setShowFeaturedTooltip(true)} className="tooltip-text">
                        What's this?
                    </p>*/}
                </div>
            </div>
            <button className="default-button" onClick={() => showStories()}>Show {props.count} Life Lesson(s)</button>
            <TooltipHelp contextKey={'story_filters_lifelessons_in_stories_of'} show={showFeaturedTooltip} clear={() => setShowFeaturedTooltip(false)} />
        </div>
    )
}