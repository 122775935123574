import React, { useState } from "react"
import Icon from '@mdi/react'
import { mdiFilter, mdiClose, mdiMagnify } from '@mdi/js';
import { Form } from "react-bootstrap"

interface IProps {
    filters: IFilters
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>
    filteredRelationNumber: number
}

// profile types for the filtering
export enum ProfileTypes {
    DEFAULT = "",
    ACTIVE = "Active profiles",
    INACTIVE = "Inactive profiles",
}

export interface IFilters {
    nameFilter: string
    profileTypeFilter: string
}

function FamilyFilters(props: IProps) {
    const avalibleFilters: ProfileTypes[] = [ProfileTypes.ACTIVE, ProfileTypes.INACTIVE] // choosable filters showed in the select option
    const [ showFilters, setShowFilters ] = useState<boolean>(false); // show or hide the filter selector
    const [ showSearch, setShowSearch] = useState(false)

    function handleFilterChange(event: React.ChangeEvent<HTMLSelectElement>) {
        props.setFilters({ ...props.filters, profileTypeFilter: event.target.value })
        setShowFilters(false);
    }

    // function to dsiplay the number of active filters next to the filter button
    function countActiveFilters():string {
        return props.filters.profileTypeFilter.length ? "(1)" : ""
    }

    return (
        <div className="family-filters">
            <div className="filters">
                <div className={"filter " + (props.filters.profileTypeFilter.length ? "active" : "")}>
                    <div className="icon-container" onClick={() => setShowFilters(!showFilters)}>
                        <Icon size={0.8} path={ mdiFilter } />
                        <p>{"Filters " + countActiveFilters()}</p>
                    </div>
                    {showFilters ? (
                    <div className="group">
                        <Icon className="filter-icon" size={1} path={ mdiFilter } />
                        <Form.Select aria-label="" onChange={handleFilterChange} value={props.filters.profileTypeFilter}>
                            <option value="">----</option>
                            {avalibleFilters.map((type: ProfileTypes) => {
                                return <option key={type}>{type}</option>
                            })}
                        </Form.Select>
                        <div className="close-icon">                            
                            <span onClick={() => setShowFilters(!showFilters)}><Icon size={1} path={ mdiClose } /></span>
                        </div>
                    </div>
                    ) : null}
                </div>
                <div className={"search" + (props.filters.nameFilter.length ? " active" : "")} >
                    <span onClick={() => setShowSearch(!showSearch)}><Icon size={1} path={ mdiMagnify } /></span>
                    {showSearch && (
                    <div className="group">
                        <Icon className="search-icon" size={1.1} path={ mdiMagnify } />
                        <Form.Control
                            value={props.filters.nameFilter}
                            autoFocus
                            type="text"
                            onChange={(evt) => props.setFilters({ ...props.filters, nameFilter: evt.target.value })}
                            placeholder="Type to add keyword"
                            />
                        <div className="close-icon">
                            <span onClick={() => setShowSearch(!showSearch)}><Icon size={1} path={ mdiClose } /></span>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
    )
}

export default FamilyFilters
