import React, { useEffect, useState, useContext, useRef } from "react"
import { Form, Modal, Tab, Tabs } from "react-bootstrap"
import { useHistory } from "react-router"
import { Spinner } from "react-bootstrap"
import instance from "../../../api/api"
import BlockBuilder from "../../../components/story/blocks/block-builder.component"
import StoryInfo, { DateTypes, IDateInputs } from "../../../components/story/story-info.component"
import PublishStory from "../../../components/story/story-publish.component"
import IPage from "../../../interfaces/page"
import { accessTypes, blockTypes, IBlock, IMedia, IStory } from "../../../interfaces/story"
import Icon from "@mdi/react"
import { mdiCheck, mdiChevronLeft, mdiChevronRight, mdiClose, mdiPencil } from "@mdi/js"
import Notification, { notificationImages } from "../../../components/notifications/notification.component"
import { INotification, NotificationType } from "../../../interfaces/notification"
import moment from "moment"
import { IUserDetails } from "../../../interfaces/authentication"
import { Context, IPageData } from "../../home.page"
import StoryImagesCarousel from "../../../components/story/story-images-carousel.component"
import Help from "../../../components/_helper/help.component"
import StoryPreview from "../../../components/story/story-preview.component"
import default_avatar from "../../../assets/images/default-avatar.jpg";
import { notificationTypes } from "../family-updates.page"
import { eFeedbackTypes } from "./story-details.page"
import config from "../../../config/config"
import StoryRequest from "../../../components/story/requests/story-request-component"
import { IRequest } from "../../../components/story/requests/story-recieved-requests.component"
import CommentsContainer, { ECommentType } from "../../../components/comments/comments-container.component"
import UserCarousel, { ECarouselAction } from "../../../components/_helper/user-carousel.component"
import commentAPIInstance from "../../../api/comment_api"
import UploadLimitReachedModal from "../../../components/story/upload-limit-reached.modal"
import axios, { AxiosError } from "axios"
import { useTranslation } from "react-i18next";
import AttachedInspiration from "../../../components/story/requests/attached-inspiration-component"

enum tabs {
    STORY = "1",
    INFO = "2",
    PUBLISH = "3",
}

enum buildMenu {
    CREATE = "1",
    REQUEST = "2",
    CHAT = "3",
}

const Notifications = {
    default: { icon: notificationImages.crying, title: "", text: "", success: NotificationType.problem },
    requestSent: { icon: notificationImages.happiness, title: "Request Sent", text: "Please wait for their response. Close this window to cancel the request.", success: NotificationType.success, closeBtnOff: true },
    teamInformed: { icon: notificationImages.happiness, title: "Success", text: "Your team has been informed.", success: NotificationType.success },
    missingYearError: { icon: notificationImages.crying, title: "Warning", text: "The year of the story is a required field.", success: NotificationType.problem },
    invalidRangeError: { icon: notificationImages.crying, title: "Warning", text: "The end date of the story has to be after the start date.", success: NotificationType.problem },
    featuredProfileError: { icon: notificationImages.crying, title: "Warning", text: "At least one featured profile is required.", success: NotificationType.problem },
    accuracyRangeError: {
        icon: notificationImages.crying,
        title: "Warning",
        text: "If you specify a time period for the story, the start date and end must be entered with the same accuracy.",
        success: NotificationType.problem,
    },
}

enum AccessTypesLocal {
    ALL_FAMILY = "All Family",
    ALL_FAMILY_AND_FRIENDS = "All Family & Friends",
    GROUP = "Assign Group(s)",
    INDIVIDUAL = "Individual People",
    PRIVATE = "Private (my eyes only)",
}

export const default_story_data: IStory = {
    _id:"",
    published: false,
    title: "",
    author: "",
    coAuthors: [],
    featured: "",
    blocks: [],
    participants: [],
    access: [],
    updatedAt: "",
    accessGroups: [],
    accessIndividuals: [],
    accessPrivate: false,
    date: {startDate:{date:moment().toDate(), year:parseInt(moment().format('YYYY'))}},
    storyTags: [],
    accessFamily:accessTypes.ALL_FAMILY_AND_FRIENDS,
    sLocation: {lat:0, lng:0},
    createdAt: new Date(),
    readTime: 0,
    related: [],
    lock: {
        locked: false,
        lockedBy: null
    },
    edited:false,
    public:false,
    readBy:[],
    commentsEnabled:false
}

enum coauthoringMessageTypes {
    REQUEST_ACCESS="REQUEST_ACCESS",
    GRANT_ACCESS="GRANT_ACCESS",
    REJECT_ACCESS="REJECT_ACCESS"
}

/**
 * Create story page
 *
 * @param props : props
 * @returns void
 */
const CreateStory: React.FunctionComponent<IPage> = (props) => {
    const [story, setStory] = useState<IStory>(default_story_data)
    const [key, setKey] = useState(tabs.STORY)
    const [submenu, setSubmenu] = useState(buildMenu.CREATE)
    const [infoVisited, setInfoVisited] = useState(false)
    const [access, setAccess] = useState<AccessTypesLocal>(AccessTypesLocal.ALL_FAMILY_AND_FRIENDS);
    const [storyUpdated, setStoryUpdated] = useState<boolean>(false);
    const [isAuthor, setIsAuthor] = useState<boolean>(false);

    //blacklisted partipicants
    const [removedParticipants, setRemovedParticipants] = useState<string[]>([]);

    //Image carousel with all story images
    const [showImageCarousel, setShowImageCarousel] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    // notification, validation
    const [showInfoFormNotification, setShowInfoFormNotification] = useState<boolean>(false)
    const [InfoFormNotification, setNotification] = useState<INotification>(Notifications.default)
    const [dateRangeIsValid, setdateRangeIsValid] = useState<boolean>(true)

    const [showTeamInformedNotification, SetShowTeamInformedNotification] = useState<boolean>(false)
    const [teamInformNotification, ] = useState<INotification>(Notifications.teamInformed);

    //Confirmation modals
    const [ deleteShow, setDeleteShow ] = useState<boolean>(false);
    const [ unpublishShow, setUnpublishShow ] = useState<boolean>(false);

    // loader
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ lockedModalShow, setLockedModalShow ] = useState<boolean>(false);
    const [ lockedByElse, setLockedByElse ] = useState<boolean>(false);

    // Edit Title
    const [title, setTitle] = useState<string>('');
    const [editTitle, setEditTitle] = useState<boolean>(false);

    //preview story
    const [ showPreview, setShowPreview ] = useState<boolean>(false);

    //Access request
    const [ showAccessRequest, setShowAccessRequest ] = useState<boolean>(false);
    const [ showAccessGranted, setShowAccessGranted ] = useState<boolean>(false);
    const [ showAccessRejected, setShowAccessRejected ] = useState<boolean>(false);
    const [ showLockerOffline, setShowLockerOffline ] = useState<boolean>(false);
    const [ requestUser, setRequestUser ] = useState<IUserDetails>();
    const [ showRequestSentNotify, setShowRequestSentNotify ] = useState<boolean>(false);
    const [ accessRequestProblemModal, setAccessRequestProblemModal ] = useState<boolean>(false);

    const [ uploadLimitReachedModal, setUploadLimitReachedModal ] = useState<boolean>(false);    

    //Notification emails if public story
    const [ notificationEmails, setNotificationEmails ] = useState<string[]>([])

    //new comments notification length
    const [ newComments, setNewComments ] = useState<number>(0);
    const topRef = useRef<any>(null);

    const history = useHistory()

    const userData: IUserDetails = useContext(Context).user;
    const pageData: IPageData = useContext(Context).pageData;

    const { t } = useTranslation();
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, [key]);    

    //ref for story cleanup
    const storyRef = useRef<IStory>();
    useEffect(() => {
        storyRef.current = story;
    }, [story]);

    const titleRef = useRef<string>();
    useEffect(() => {
        titleRef.current = title;
    }, [title]);

    const storyUpdatedRef = useRef<boolean>();
    useEffect(() => {
        storyUpdatedRef.current = storyUpdated;
    }, [storyUpdated]);

    const lockedByElseRef = useRef<boolean>();
    useEffect(() => {
        lockedByElseRef.current = lockedByElse;
    }, [lockedByElse]);

    //cleanup for lock
    useEffect(() => {
        return () => {
            //unmount socket
            if(pageData.socket !== null && pageData.socket.off) {
                pageData.socket.off("COAUTHORING_MESSAGE");
            }

            updateStoryData();
        }
    }, [])

    useEffect(() => {
        if (props.location.state) {
            if (props.location.state.data && userData._id.length) {
                getStory()
                if (props.location.state?.data?.insprirationId) {
                    setEditTitle(true)
                }
            } else {
                history.push("/dashboard/launchpad/1")
            }

            if(props.location.state && props.location.state.hasOwnProperty('subTab')){
                setSubmenu(props.location.state.subTab);
            }            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.state, userData._id])

    async function lockStory(lock:boolean) {
        try {
            await instance.put("/stories/" + props.location.state.data._id, {data: { lock: {locked:lock, lockedBy: lock ? userData._id : null} }})
            setStory((prev) => { return {...prev, lock: {locked:true, lockedBy:userData}}});
        }
        catch(err:any) {
            setAccessRequestProblemModal(true);
        }
    }

    async function getStory() {
        const response = await instance.get(`/stories/${props.location.state.data._id}`);
        const storyData = response.data
        const updatedStoryData = {
            ...storyData,
            author: storyData.author,
            participants: storyData.participants,
            featured: storyData.featured,
            coAuthors: storyData.coAuthors,
            date: storyData.date,
            accessGroups: storyData.accessGroups,
            accessIndividuals: storyData.accessIndividuals,
            accessPrivate: storyData.accessPrivate,
            accessFamily: storyData.accessFamily,
            readTime: storyData.readTime,
            sLocation: storyData.sLocation
        }

        if(updatedStoryData.accessFamily === accessTypes.PRIVATE) {
            setAccess(AccessTypesLocal.PRIVATE)
        }
        else if(updatedStoryData.accessFamily === accessTypes.GROUP) {
            setAccess(AccessTypesLocal.GROUP)
        }
        else if(updatedStoryData.accessFamily === accessTypes.INDIVIDUAL) {
            setAccess(AccessTypesLocal.INDIVIDUAL)
        }
        else if(updatedStoryData.accessFamily === accessTypes.ALL_FAMILY) {
            setAccess(AccessTypesLocal.ALL_FAMILY)
        }
        else if(updatedStoryData.accessFamily === accessTypes.ALL_FAMILY_AND_FRIENDS) {
            setAccess(AccessTypesLocal.ALL_FAMILY_AND_FRIENDS)
        }

        if(updatedStoryData.edited) {
            setStoryUpdated(true);
        }

        if(userData._id === updatedStoryData.author._id) {
            setIsAuthor(true);
        }

        setStory(updatedStoryData);
        setTitle(storyData.title);

        if(storyData.lock && storyData.lock.locked && storyData.lock.lockedBy && storyData.lock.lockedBy._id !== userData._id) {
            setLockedByElse(true);
            setLockedModalShow(true);
            return;
        }
        else {
            //lock story if someone editing it
            lockStory(true)
        }
    }

    async function updateStoryData() {
        if(storyRef.current && storyRef.current._id.length && titleRef.current && storyUpdatedRef.current !== undefined && !lockedByElseRef.current) {
            let lockData:any = { locked:false, lockedBy:null }
            const sAccess = setAccessBeforePublish();

            if(storyUpdatedRef.current) {
                const refactoredStoryData = {
                    ...sAccess,
                    ...storyRef.current,
                    title: titleRef.current,
                    lock: lockData,
                    author: (storyRef.current.author as IUserDetails)._id,
                    participants: (storyRef.current.participants as IUserDetails[]).map(x => x._id),
                    featured: (storyRef.current.featured as IUserDetails)._id,
                    coAuthors: (storyRef.current.coAuthors as IUserDetails[]).map(x => x._id),
                    edited: true
                }

                try {
                    const newStoryData = await instance.put(`/stories/${storyRef.current._id}`, { data: refactoredStoryData });
                    setStory(newStoryData.data)
                } catch (error) {
                    console.log("Failed to update story data")
                }
            }
            else {
                try {
                    const newStoryData = await instance.put(`/stories/${storyRef.current._id}`, { data: { lock:lockData } });
                    setStory(newStoryData.data)
                } catch (error) {
                    console.log("Failed to update story lock")
                }
            }
        }
    }

    async function publishStory() {

        const sAccess = setAccessBeforePublish();
        const refactoredStoryData = {
            ...story,
            ...sAccess,
            participants: sAccess.accessPrivate ? [] : (story.participants as IUserDetails[]).map(x => x._id),
            featured: sAccess.accessPrivate ? userData._id : (story.featured as IUserDetails)._id,
            coAuthors: sAccess.accessPrivate ? [] : (story.coAuthors as IUserDetails[]).map(x => x._id),
        }

        try {
            setIsLoading(true);
            const updateResponse = await instance.put(`/stories/${refactoredStoryData._id}`, { data: refactoredStoryData });
            await instance.post(`/stories/publish`, { id: updateResponse.data._id });
            
            if(refactoredStoryData.public && notificationEmails.length) {
                //TODO: medium: send emails about the story.

                try {
                    // TODO: medium: do it properly
                    await instance.post('/email', { type: "Share with Private Link", param1: window.location.origin + "/story/" +  encodeURIComponent(story.privateId ? story.privateId : ""), param2: story.title, param3: notificationEmails});

                    setNotification({
                        icon:notificationImages.happiness, title:"Success", text:"Message(s) sent successfully", success:NotificationType.success
                    })

                }
                catch(error) {
                    // TODO: medium: do it properly

                    setNotification({
                        icon:notificationImages.crying, title:"Failed", text:"Message(s) not sent", success:NotificationType.problem
                    })

                    console.log('Failed to send message, Error ' + error)
                }
            }
            setStoryUpdated(false);
            setStory({...updateResponse.data, published:true})

            setIsLoading(false)
            setTimeout(() => {
                // keep precedence!
                let eFeedbackType = eFeedbackTypes.DEFAULT;
                if(refactoredStoryData.public) {eFeedbackType = eFeedbackTypes.PRIVATE_URL}
                if(isNonSimirityPeopleTagged(refactoredStoryData.blocks)) {eFeedbackType = eFeedbackTypes.NON_SIMIRITY_PEOPLE_TAGGED}
                if(refactoredStoryData.published) {eFeedbackType = eFeedbackTypes.REPUBLISHED}
                if(!userData.relations.length) {eFeedbackType = eFeedbackTypes.NO_CONTACT}

                history.push("/dashboard/story/" + refactoredStoryData._id, {feedback:eFeedbackType})
            }, 1000)

        } catch (error) {
            console.log("Failed to publish story")
        }
    }

    function isNonSimirityPeopleTagged(blocks:IBlock[]) {

        let isNonSimirityPeopleTagged = false;
        const media = blocks.map(x=>x.media);
        const multipleMedia = blocks.map(x=>x.multipleMedia);
        media.forEach(item => {
            if(item && item.tags.others) isNonSimirityPeopleTagged = true;
        })
        multipleMedia.forEach(itemArr => {
            itemArr.map(x => {
                if(x.tags && x.tags.others) isNonSimirityPeopleTagged = true;
                return x});
        })

        return isNonSimirityPeopleTagged;
    }

    function setAccessBeforePublish() {

        type accessObj = {
            accessPrivate: boolean
            accessFamily: accessTypes
            accessGroups: string[]
            accessIndividuals: string[]
        }

        // Set access to private and remove all users from other fields
        const accessObject:accessObj = {
            accessPrivate: false,
            accessFamily: accessTypes.PRIVATE,
            accessGroups: [],
            accessIndividuals: []
        }


        if(access === AccessTypesLocal.PRIVATE) {
           accessObject.accessPrivate = true;
           accessObject.accessFamily = accessTypes.PRIVATE

        }
        
        if(access === AccessTypesLocal.GROUP) {
            accessObject.accessGroups = (story.accessGroups as string[])
            if(story.accessIndividuals.length) {
                accessObject.accessIndividuals = (story.accessIndividuals as string[])
            }
            accessObject.accessFamily = accessTypes.GROUP

        }
        if(access === AccessTypesLocal.INDIVIDUAL) {
            accessObject.accessIndividuals = (story.accessIndividuals as string[])
            accessObject.accessFamily = accessTypes.INDIVIDUAL

        }
        if(access === AccessTypesLocal.ALL_FAMILY) {
            accessObject.accessFamily = accessTypes.ALL_FAMILY

        }
        if(access === AccessTypesLocal.ALL_FAMILY_AND_FRIENDS) {
            accessObject.accessFamily = accessTypes.ALL_FAMILY_AND_FRIENDS

        }
        
        return accessObject
    }


    //UPDATE or ADD new story block
    async function addBlockToStory(block: IBlock, index: number) {
        if (!block._id) {
            const blocks = {
                add: { data: block, index: index },
            }
            try {
                const response = await instance.put("/stories/" + story._id, { data: {}, blocks: blocks });
                updateStory("blocks", [...response.data.blocks])

                return true;
            }
            catch(error) {
                const isAxiosError = axios.isAxiosError(error)
                if ( isAxiosError && (error as AxiosError).response?.data === "Upload Limit Reached" ) {
                    setUploadLimitReachedModal(true)
                } else {
                    console.log('Error', error);
                }
                // TODO: high: show other errors too
                return false;
            }
        }
        //Update an existing block
        else {
            try {
                const response = await instance.put("/stories/block/" + block._id, { data: block, index: index, storyid: story._id });
                updateStory("blocks", response.data)
            }
            catch(error) {
                console.log('Error', error);
                // TODO: high: show errors
                return true;
            }
        }
    }

    function updateStory(field: string, newValue: any) {
        setStory((prev) => { return {...prev, [field]: newValue}});
        setStoryUpdated(true);
    }

    function navigateMainTabs(event: tabs) {
        if (event === tabs.INFO) {
            setInfoVisited(true)
        }
        if (event === tabs.PUBLISH && !infoVisited) {
            return
        }

        setKey(event);
    }

    async function deleteBlock(blockId: string) {

        try {
            await instance.delete("/stories/block/" + story._id + "/" + blockId)
            let temp = story.blocks.filter((block: IBlock) => block._id !== blockId);

            setStory((prev: IStory) => {
                return { ...prev, blocks: temp }
            })
            setStoryUpdated(true);

        } catch (error) {
            console.log("error")
        }
    }

    /**
     * validation of the date input, if it is a range
     */
    function validatePeriod(startDate: IDateInputs, endDate: IDateInputs, dateType: DateTypes) {
        // if the date is fixed, it doesn't need range validation, return true
        if (dateType === DateTypes.FIXED) {
            setdateRangeIsValid(true)
            return
        }
        // if one input has day accuracy, the other must be the same
        if ((startDate.day && !endDate.day) || (!startDate.day && endDate.day)) {
            setdateRangeIsValid(false)
            return
        }
        // if one input has month accuracy, the other must be the same
        if ((startDate.month !== null && endDate.month === null) || (startDate.month === null && endDate.month !== null)) {
            setdateRangeIsValid(false)
            return
        }
        // if one input has year accuracy, the other must be the same
        if ((startDate.year && !endDate.year) || (!startDate.year && endDate.year)) {
            setdateRangeIsValid(false)
            return
        }

        setdateRangeIsValid(true)
        return
    }

    /**
     * validation at the end of the step 2
     */
    function validateInfoForm() {
        // at least the fixed year input is required
        if (!story.date.startDate.date) {
            setNotification(Notifications.missingYearError)
            setShowInfoFormNotification(true)
            return
        }
        // the start date has to be before the end date
        // if (story.date.endDate && moment(story.date.endDate.date).isBefore(story.date.startDate.date)) {
        //     setNotification(Notifications.invalidRangeError)
        //     setShowInfoFormNotification(true)
        //     return
        // }
        // if the date is a range, the start and end dates have to be defined with the same accuracy (year-year, month-month, day-day)
        if (!dateRangeIsValid) {
            setNotification(Notifications.accuracyRangeError)
            setShowInfoFormNotification(true)
            return
        }
        return true
    }

    async function deleteStory() {
        try {
            setIsLoading(true);
            await instance.delete("/stories/" + story._id)
            setStory(default_story_data);

            setTimeout(() => {
                setIsLoading(false);                
                history.push("/dashboard/stories/1")
                setDeleteShow(false)
            }, config.modalCloseTimeout)
        } catch (error) {
            setIsLoading(false);            
            console.log("error")
        }
    }

    async function unpublishStory() {
        try {
            setIsLoading(true);            
            await instance.post("/publishedstories/unpublish", {id:story._id});
            
            setTimeout(() => {
                setIsLoading(false);
                setUnpublishShow(false)
                history.push("/dashboard/launchpad/2")
            }, config.modalCloseTimeout)
        }
        catch(error) {
            setIsLoading(false);
            console.log('error')
        }
    }

    async function updateBlockMedia(blockId:string, data:IMedia) {
        const temp = [...story.blocks]
        temp.forEach(block => {
            if(block._id === blockId) {
                if(block.media) {
                    block.media = data;
                }
                if(block.multipleMedia) {
                    let tempMed = [...block.multipleMedia];
                    let index:number = 0;
                    tempMed.forEach((media:IMedia, i:number) => {
                        if(media._id === data._id) {
                            index = i;
                        }
                    })
                    tempMed[index] = data;
                    block.multipleMedia = tempMed
                }
            }
        });
        const refactoredStoryData = {...story, edited: true};
        setStoryUpdated(true);
        setStory({...refactoredStoryData, blocks:temp});
    }

    function showImageCarouselWithIndex(index:number) {
        setImageIndex(index);
        setShowImageCarousel(true);
    }

    function updateParticipants() {
        let participants:IUserDetails[] = [];

        //Do not update if
        //Get tagged people
        story.blocks.forEach((block:IBlock) => {
            if(block.media) {
                if(block.media.tags.users.length) {
                    participants = [...participants, ...(block.media.tags.users as IUserDetails[])]
                }
            }
            if(block.multipleMedia) {
                block.multipleMedia.forEach(media => {
                    if(media.tags.users.length) {
                        participants = [...participants, ...(media.tags.users as IUserDetails[])]
                    }
                })
            }
        });

        //remove duplicates
        let refactored = [...participants, ...(story.participants as IUserDetails[])].filter((value, index, self) =>
            index === self.findIndex((t) => (
                t._id === value._id
            ))
        ).filter((value) => !removedParticipants.includes(value._id))

        if(refactored.filter(x => !story.participants.map(y => (y as IUserDetails)._id).includes(x._id)).length) {
            updateStory('participants', refactored);

        }
    }

    // Title
    function handleSubmit() {
        updateStory('title', title);
        setEditTitle(false);
    }

    // Title
    function handleClose() {
        setEditTitle(false);
    }

    function navigateAndUpdate(k : tabs) {
        navigateMainTabs(k);
        if(key === tabs.STORY) {
            updateParticipants();
        }
    }

    function getReadTimeLive() {
        const string = story.blocks.filter(x => x.type === blockTypes.TEXT).map((block :IBlock) => (block.data as string).split(" ")).flat();
        const readTime = Math.ceil(string.length/200) > 0 ? Math.ceil(string.length/200) : 1;
        return readTime;
    }

    async function notifyTeam() {
        try {
            const notificationData : any = {
                author: userData._id,
                authorName: `${userData.firstname} ${userData.lastname}`,
                users: [...(story.coAuthors as IUserDetails[]).filter(x => x._id !== userData._id).map(x => x._id), (story.author as IUserDetails)._id],
                type: story.published ? notificationTypes.STORY_COAUTHOR_INFORM_UPDATE : notificationTypes.STORY_COAUTHOR_INFORM_DONE,
                title: story.published ? "Updated Story as Co-author" : "Finished Co-authoring Story",
                description: story.title,
                pObjectId: story._id
            }
            await instance.post(`/notification/send`, { data: notificationData });

            SetShowTeamInformedNotification(true);
            setTimeout(() => {
                SetShowTeamInformedNotification(false);
                history.push("/dashboard/stories/");
            }, config.modalCloseTimeout);

        } catch(error) {
            console.log('Failed to send notification.', error)
        }
    }


    async function requestAccess() {
        try {
            const response = await instance.post('/notification/access', {userid:story.lock.lockedBy?._id, storyid:story._id, type: coauthoringMessageTypes.REQUEST_ACCESS});
            if(response && !response.data.isOnline) {
                const newStoryData = await instance.put(`/stories/${story._id}`, { data: { lock: {locked:true, lockedBy:userData._id} } });
                setStory(newStoryData.data);
            }
            setShowRequestSentNotify(true);
        }
        catch(error) {
            //TODO: figure out proper way to handle this error.
            console.log("create.page.tsx requestAccess", error)

        }
    }

    useEffect(() => {
        if(userData._id.length && pageData.socket && story._id.length) {
            //get new comments
            getCoAuthorComments()

            pageData.socket.on('COAUTHORING_MESSAGE', (data:any) => {

                if(data.type === coauthoringMessageTypes.REQUEST_ACCESS){
                    if(data.users[0] === userData._id) {
                        const coAuthorWhoRequested = [...(story.coAuthors as IUserDetails[]), (story.author as IUserDetails)].find(x => x._id === data.author);
                        if(coAuthorWhoRequested) {
                            setShowAccessRequest(true);
                            setRequestUser(coAuthorWhoRequested);
                        }
                    }
                } else if (data.type === coauthoringMessageTypes.GRANT_ACCESS) {
                    if(data.users[0] === userData._id) {
                        const coAuthorWhoRequested = [...(story.coAuthors as IUserDetails[]), (story.author as IUserDetails)].find(x => x._id === data.author);
                        if(coAuthorWhoRequested) {
                            setLockedModalShow(false);
                            setShowAccessRequest(false);
                            setShowAccessGranted(true);
                            setRequestUser(coAuthorWhoRequested);
                        }
                    }

                } else if (data.type === coauthoringMessageTypes.REJECT_ACCESS) {
                    if(data.users[0] === userData._id) {
                        const coAuthorWhoRequested = [...(story.coAuthors as IUserDetails[]), (story.author as IUserDetails)].find(x => x._id === data.author);
                        if(coAuthorWhoRequested) {
                            setLockedModalShow(false);
                            setShowAccessRequest(false);
                            setShowAccessRejected(true);
                            setRequestUser(coAuthorWhoRequested);
                        }
                    }

                }
            });

        }
    }, [userData, pageData.socket, story])

    /**
     * Every case when the publish is disabled will come here
     */
    function publishButtonDisabled() {
        if(access === AccessTypesLocal.GROUP) {
            if(!story.accessGroups.length) {
                return true;
            }
        }
        if(!storyUpdated) {
            return true;
        }
        if(showTeamInformedNotification) {
            return true;            
        }
        return false;

    }

   async function sendRequestGrantNotification() {
        //console.log('sendRequestGrantNotification')
        if(requestUser) {
            await instance.post('/notification/access', {userid:requestUser._id, storyid:story._id, type: coauthoringMessageTypes.GRANT_ACCESS});
            setStory(default_story_data);
            setShowAccessRequest(false);
            history.push("/dashboard/stories");
        }
    }

    async function sendRequestRejectAccessNotification()  {
        //console.log('sendRequestRejectAccessNotification')
        if(requestUser) {
            await instance.post('/notification/access', {userid:requestUser._id, storyid:story._id, type: coauthoringMessageTypes.REJECT_ACCESS});
            setShowAccessRequest(false);
        }
    }

    async function getCoAuthorComments() {

        const userLastLogin = userData.lastVisited;

        if(!window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL || !story.accessCommentToken) {
            setNewComments(0)
            return;
        }

        const res = await commentAPIInstance.get(window.__RUNTIME_CONFIG__.REACT_APP_COMMENT_API_URL, {
            params: {
                pageNo:1,
                query: {
                    related_entity: story._id,
                    comment_type: ECommentType.COAUTHOR,
                    createdAt: { 
                        $gte: userLastLogin 
                    }
                }
            },
            headers: {"Authorization" : `Bearer ${story?.accessCommentToken}`}
        });

        setNewComments(res.data.result.length)
    }

    function getCommentNotificationDot() {
        // if(newComments > 0) {
        //     return(
        //         <div className="dot-notification-container">
        //             Comments
        //             <div className="dot">
        //                 <p>{newComments < 10 ? newComments : "9+"}</p>
        //             </div> 
        //         </div>
        //     )
        // }
        // else {
        return(
            <div className="dot-notification-container">
                Comments
            </div>
        )
        // }
    }

    return (
        <div className="create-container" ref={topRef}>
            <Tabs activeKey={key} id="create-story" className="menu-tabs" onSelect={(k: any) => {(k as tabs) === tabs.INFO ? navigateAndUpdate(k as tabs) : navigateMainTabs(k as tabs)}}>
                <Tab eventKey={tabs.STORY} title={"1. "+t("STORY")}>
                    {story.coAuthors.length || story.request || story.insprirationId ? (
                        <Tabs activeKey={submenu} id="create-story" className="menu-tabs tabs-launchpad menu-level-2" onSelect={(k: any) => setSubmenu(k as buildMenu)}>
                            <Tab eventKey={buildMenu.CREATE} title={t("CREATE")}>
                                <div className="story-builder">
                                    <p className="story-title" onClick={() => setEditTitle(true)}>{story.title} <span><Icon className="edit-icon" size={0.7} path={mdiPencil} /></span></p>
                                    {editTitle &&
                                        <div className='edit'>
                                            <Form className="group" onSubmit={handleSubmit}>
                                                <Form.Label className="top-label">Title</Form.Label>
                                                <input autoFocus value={title} onChange={(evt) => evt.target.value.length <= 50 ?  setTitle(evt.target.value) : null} type="text" name="" id="" />
                                                <div className="submit-icon-container" onClick={handleSubmit}>
                                                    <Icon size={1} path={mdiCheck} />
                                                </div>
                                                <div className="submit-icon-container" onClick={handleClose}>
                                                    <Icon size={1} path={mdiClose} />
                                                </div>
                                                <p className="sub-subtitle right">{title.length} / 50</p>
                                            </Form>
                                        </div>
                                    }
                                    <BlockBuilder
                                        story={story}
                                        updateStory={updateStory}
                                        showImageCarousel={showImageCarouselWithIndex}
                                        updateBlockMedia={updateBlockMedia}
                                        starterBlock={props.location.state.starterBlock ? props.location.state.starterBlock : false}
                                        deleteBlock={deleteBlock}
                                        addBlockToStory={addBlockToStory}
                                        blocks={story.blocks}
                                        isAuthor={isAuthor}
                                    />
                                    <button className="default-button default-button-story" onClick={() => { updateParticipants(); setKey(tabs.INFO);  }}>
                                        {t("Next step")}
                                        <Icon size={1.1} path={mdiChevronRight} style={{ position: "relative", top: "-0.5px" }}></Icon>
                                    </button>
                                </div>
                                <section className="preview-story-section">
                                    <div className="preview-story-section-content" onClick={() => setShowPreview(true)}>
                                        <h2 className="subtitle">{t("Preview Story")}</h2>
                                        <small>{t("Read time estimate")+": " + story.readTime + " "+t("min")}</small>
                                    </div>
                                </section>
                            </Tab>
                            {story.request ? <Tab eventKey={buildMenu.REQUEST} title={t("ORIGINS")}>
                                <StoryRequest type={"storyTab"} request={story.request as IRequest} setList={() => {}} updateList={() => {}} />
                            </Tab> : null}
                            {story.insprirationId ? <Tab eventKey={buildMenu.REQUEST} title={t("ORIGINS")}>
                                <div className="story-request story-details">
                                    <AttachedInspiration inspirationQuestionId={story.insprirationId} />
                                </div>
                            </Tab> : null}
                            {story.coAuthors.length ? (
                                <Tab eventKey={buildMenu.CHAT} title={getCommentNotificationDot()}>
                                    <p className='title co-author-comment-title'>{t("Discuss story with co-authors")}</p>
                                    <div className="co-author-list">
                                        <UserCarousel users={[story.author as IUserDetails, ...story.coAuthors as IUserDetails[]].filter(x => x._id != userData._id)} action={ECarouselAction.PUSH} noOfSliderItemsToShow={story.coAuthors.length < 3 ? story.coAuthors.length : 3} />
                                    </div>
                                    <hr className="solid" style={{border:"1px solid #bbb", width:"80px", margin:"auto"}}></hr>
                                    <div className="comment-section">
                                        <CommentsContainer accessToken={story.accessCommentToken || "storycreatepage"} commentsEnabled={true} title={""} related_entity={story._id} access_entity={story._id} comment_type={ECommentType.COAUTHOR} />
                                    </div>
                                    <button onClick={() => topRef.current ? topRef.current.scrollIntoView() : null} className='light-button'>{t("Back to top")}</button>

                                </Tab>
                            ) : null}
                        </Tabs>
                    ) : (
                        <>
                            <div className="story-builder">
                                <p className="story-title" onClick={() => setEditTitle(true)}>{story.title}</p>
                                {editTitle &&
                                    <div className='edit'>
                                        <Form className="group" onSubmit={handleSubmit}>
                                            <Form.Label className="top-label">{t("Title")}</Form.Label>
                                            <input autoFocus value={title} onChange={(evt) => evt.target.value.length <= 50 ?  setTitle(evt.target.value) : null} type="text" name="" id="" />
                                            <div className="submit-icon-container" onClick={handleSubmit}>
                                                <Icon size={1} path={mdiCheck} />
                                            </div>
                                            <div className="submit-icon-container" onClick={handleClose}>
                                                <Icon size={1} path={mdiClose} />
                                            </div>
                                            <p className="sub-subtitle right">{title.length} / 50</p>
                                        </Form>
                                    </div>
                                }
                                <BlockBuilder
                                    story={story}
                                    updateStory={updateStory}
                                    showImageCarousel={showImageCarouselWithIndex}
                                    updateBlockMedia={updateBlockMedia}
                                    starterBlock={props.location.state.starterBlock ? props.location.state.starterBlock : false}
                                    deleteBlock={deleteBlock}
                                    addBlockToStory={addBlockToStory}
                                    blocks={story.blocks}
                                    isAuthor={isAuthor}
                                />
                                <button className="default-button default-button-story" onClick={() => { updateParticipants(); navigateMainTabs(tabs.INFO);  window.scrollTo(0, 0); }}>
                                    {t("Next step")}
                                    <Icon size={1.1} path={mdiChevronRight} style={{ position: "relative", top: "-0.5px" }}></Icon>
                                </button>
                            </div>
                            <section className="preview-story-section">
                                <div className="preview-story-section-content" onClick={() => setShowPreview(true)}>
                                    <h2 className="subtitle">{t("Preview Story")}</h2>
                                    <small>{t("Read time estimate")+": " + getReadTimeLive() + " "+t("min")}</small>
                                </div>
                            </section>
                        </>
                    )}
                    <Help category={"PUBLISHING"} pageName={`STEP ${key}`} />
                </Tab>
                <Tab eventKey={tabs.INFO} title={"2. "+t("INFO")}>
                    {story._id.length ?
                    <div className="info-page">
                        <StoryInfo isAuthor={isAuthor} story={story} userData={userData} updateStory={updateStory} validatePeriod={validatePeriod} removedParticipants={removedParticipants} setRemovedParticipants={setRemovedParticipants}/>
                        {showInfoFormNotification ? <Notification data={InfoFormNotification} close={() => setShowInfoFormNotification(false)} /> : null}
                        <div className="button-container">
                            <button className="bordered-button back" onClick={() => { setKey(tabs.STORY); }}>
                                <Icon className="calendar-icon" size={1} path={mdiChevronLeft} />
                                {t("Back")}
                            </button>
                            <button className="default-button next" onClick={() => { if(validateInfoForm()) { setKey(tabs.PUBLISH); }} }>
                                {t("Next step")}
                                <Icon className="calendar-icon" size={1} path={mdiChevronRight} />
                            </button>
                        </div>
                    </div> : <p>{t("Story not found")}</p>}
                    <Help category={"PUBLISHING"} pageName={`STEP ${key}`} />
                </Tab>
                <Tab eventKey={tabs.PUBLISH} title={"3. "+t("PUBLISH")}>
                    <div className="publish-page">
                        {story._id.length ? <PublishStory setNotificationEmails={setNotificationEmails} isAuthor={isAuthor} story={story} updateStory={updateStory} userData={userData} access={access} setAccess={setAccess} returnToInfo={() => setKey(tabs.INFO)}/> : <p>Story not found.</p>}
                        {showTeamInformedNotification ? <Notification data={teamInformNotification} close={() => setShowInfoFormNotification(false)} /> : null}
                        {(story.author as IUserDetails)._id === userData._id ?
                            <div>
                                <div className="button-container">
                                    <button className="bordered-button back" onClick={() => setKey(tabs.INFO)}>
                                        <Icon className="calendar-icon" size={1} path={mdiChevronLeft} />
                                        {t("Back")}
                                    </button>
                                    <button disabled={publishButtonDisabled()} className="default-button next" onClick={publishStory}>
                                        {isLoading ? <Spinner animation="border" variant="#fff" /> : story.published ? t("Publish Updates") : t("Publish")}
                                    </button>
                                </div>
                                <span className="other-options">
                                    {story.published ? <div><button onClick={() => setUnpublishShow(true)}>{t("Unpublish")}</button><span>or</span></div>: null}
                                    <button onClick={() => setDeleteShow(true)}>{t("Delete Story")}</button>
                                </span>
                            </div>
                        :
                        <div>
                            <div className="button-container">
                                <button className="bordered-button back" onClick={() => setKey(tabs.INFO)}>
                                    <Icon className="calendar-icon" size={1} path={mdiChevronLeft} />
                                    {t("Back")}
                                </button>
                                <button disabled={publishButtonDisabled()} className="default-button next" onClick={notifyTeam}>
                                    {isLoading ? <Spinner animation="border" variant="#fff" /> : story.published ? "INFORM TEAM OF UPDATE" : "INFORM TEAM YOU’RE DONE"}
                                </button>
                            </div>
                        </div>}
                        <Modal dialogClassName="story-delete-confirm-modal" show={deleteShow} onHide={() => setDeleteShow(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("Delete Story")}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                    <div className="text">
                                        <p>{t("The story will be permanently deleted.")}</p>
                                        <p>{t("Alternatively you can unpublish this story.")}</p>
                                    </div>
                                    <button className="danger-button" onClick={() => deleteStory()}>
                                        {isLoading ? <Spinner animation="border" variant="#fff" /> : t("Confirm")}                                        
                                    </button>
                                    <button className="light-button" onClick={() => setDeleteShow(false)}>{t("Cancel")}</button>
                            </Modal.Body>
                        </Modal>
                        <Modal dialogClassName="story-delete-confirm-modal" show={unpublishShow} onHide={() => setUnpublishShow(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("Unpublish Story")}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                    <div className="text">
                                        <p>{t("The story will be removed from family viewing and added to your 'In Progress' stories list.")}</p>
                                        <br/>
                                        <p>{t("You can edit and republish it at any time.")}</p>
                                    </div>
                                    <button className="danger-button" onClick={() => unpublishStory()}>
                                        {isLoading ? <Spinner animation="border" variant="#fff" /> : t("Confirm")}
                                    </button>
                                    <button className="light-button" onClick={() => setUnpublishShow(false)}>{t("Cancel")}</button>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <Help category={"PUBLISHING"} pageName={`STEP ${key}`} />
                </Tab>
            </Tabs>
            <StoryImagesCarousel show={showImageCarousel} handleClose={() => setShowImageCarousel(false)} blocks={story.blocks} index={imageIndex}/>
            {story && <StoryPreview story={story} show={showPreview} onHide={() => setShowPreview(false)}/>}
            <Modal className="locked-story-modal" backdrop="static" show={lockedModalShow} onHide={() => {setLockedModalShow(false);history.push("/dashboard/stories")}}>
                <Modal.Header>
                    <Modal.Title>{t("Try again later")}</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => {setLockedModalShow(false);history.push("/dashboard/stories")}}></button>
                </Modal.Header>
                <Modal.Body>
                    {story.lock && story.lock.lockedBy ?
                    <div>
                        <p className="title-text">{t("Only one editor at a time")}</p>
                        <img src={(story.lock.lockedBy as IUserDetails).avatar ? (story.lock.lockedBy as IUserDetails).avatar : default_avatar} className="user-picture" alt="avatar"/>
                        <p className="name">{story.lock.lockedBy?.firstname}</p>
                        <p className="info-text">{t("is currently editing the story")}</p>
                        <button disabled={showRequestSentNotify} className="default-button" onClick={() => requestAccess()}>{t("REQUEST ACCESS")}</button>
                        <button className="light-button" onClick={() => history.goBack()}>{t("Cancel")}</button>
                        {showRequestSentNotify ? <Notification data={Notifications.requestSent} close={() => setShowRequestSentNotify(false)} /> : null}
                    </div> : null}
                </Modal.Body>
            </Modal>
            <Modal className="request-access-story-modal" backdrop="static" show={showAccessRequest} onHide={() => setShowAccessRequest(false)}>
                <Modal.Header>
                    <Modal.Title>{t("Access Request")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="title-text">{t("Only one editor at a time")}</p>
                        {requestUser ? <div className="user-details">
                        <img src={requestUser.avatar ? requestUser.avatar : default_avatar} className="user-picture" alt="avatar"/>
                            <p>{requestUser.firstname}</p>
                        </div> : null}
                        <p className="would-like">{t("would like to access")}</p>
                        <p className="story-title">{story.title}</p>
                        <button className="default-button" onClick={() => { history.push("/dashboard/stories"); sendRequestGrantNotification() }}>{t("grant access (leave story)")}</button>
                        <button className="danger-button" onClick={() => sendRequestRejectAccessNotification()}>{t("Reject (keep editing)")}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="grant-access-story-modal request-access-story-modal" backdrop="static" show={showAccessGranted} onHide={() => setShowAccessGranted(false)}>
                <Modal.Header>
                    <Modal.Title>{t("Access Granted")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {requestUser ? <div className="user-details">
                        <img src={requestUser.avatar ? requestUser.avatar : default_avatar} className="user-picture" alt="avatar"/>
                            <p>{requestUser.firstname}</p>
                        </div> : null}
                        <p className="would-like">{t("granted you access to")} </p>
                        <p className="story-title">{story.title}</p>
                        <button className="default-button" onClick={() => { setShowAccessGranted(false); setLockedByElse(false); lockStory(true) }}>{t("Edit Story")}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="reject-access-story-modal request-access-story-modal" backdrop="static" show={showAccessRejected} onHide={() => setShowAccessGranted(false)}>
                <Modal.Header>
                    <Modal.Title>{t("Access Rejected")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {requestUser ? <div className="user-details">
                        <img src={requestUser.avatar ? requestUser.avatar : default_avatar} className="user-picture" alt="avatar"/>
                            <p>{requestUser.firstname}</p>
                        </div> : null}
                        <p className="would-like">{t("is still editing")}</p>
                        <p className="story-title">{story.title}</p>
                        <button className="default-button" onClick={() => {setShowAccessRejected(false); history.push("/dashboard/stories");}}>{t("Ok")}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="locker-is-offline-story-modal request-access-story-modal" backdrop="static" show={showLockerOffline} onHide={() => setShowAccessGranted(false)}>
                <Modal.Header>
                    <Modal.Title>{t("Access Granted")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {requestUser ? <div className="user-details">
                        <img src={requestUser.avatar ? requestUser.avatar : default_avatar} className="user-picture" alt="avatar"/>
                            <p>{requestUser.firstname}</p>
                        </div> : null}
                        <p className="would-like">{t("The person locking the story is not online anymore, you are free to edit")}</p>
                        <p className="story-title">{story.title}</p>
                        <button className="default-button" onClick={() => setShowLockerOffline(false)}>{t("Dismiss")}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="locker-is-offline-story-modal request-access-story-modal" backdrop="static" show={accessRequestProblemModal} onHide={() => setAccessRequestProblemModal(false)}>
                <Modal.Header>
                    <Modal.Title>{t("Access Request problem")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="would-like">{t("The person locking the story is not online anymore, you are free to edit")}</p>
                        <button className="default-button" onClick={() => setAccessRequestProblemModal(false)}>{t("Dismiss")}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <UploadLimitReachedModal show={uploadLimitReachedModal} setUploadLimitReachedModal={setUploadLimitReachedModal} />
        </div>
    )
}

export default CreateStory
