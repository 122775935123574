import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import instance from "../../../api/api"
import { INotification, NotificationType } from "../../../interfaces/notification"
import Notification, { notificationImages } from "../../notifications/notification.component"

interface IProps {
    show: boolean
    handleClose: () => void
    closeDetailsModal: () => void
    groupid: string
    deleteGroup: Function
}

const deleteNotification: INotification = { icon: notificationImages.like, title: "Success", text: "Group removed successfully.", success: NotificationType.success }
const errorNotification: INotification = { icon: notificationImages.crying, title: "Warning", text: "Failed to remove group.", success: NotificationType.problem }

function DeleteGroupConfirmModal(props: IProps) {
    const [showDeleteNotification, setShowDeleteNotification] = useState<boolean>(false)
    const [showErrorNotification, setShowErrorNotification] = useState<boolean>(false)

    async function handleDeleteGroup() {
        try {
            const response = await instance.delete("/group/" + props.groupid)
            if (response.status === 200) {
                props.deleteGroup(props.groupid)
                setShowDeleteNotification(true)
                setTimeout(() => {
                    props.handleClose()
                    props.closeDetailsModal()
                    setShowDeleteNotification(false)
                    setShowErrorNotification(false)
                }, 3000)
            }
        } catch (error) {
            setShowErrorNotification(true)
        }
    }

    return (
        <Modal className="delete-group-modal" show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="title">Delete Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="subtitle">
                    This will permanently delete the group. <br /> Stories only assigned to this group remain visible to those currently with access, but will not be seen by future generations.
                </p>
                {showDeleteNotification ? <Notification data={deleteNotification} close={() => setShowDeleteNotification(false)} /> : null}
                {showErrorNotification ? <Notification data={errorNotification} close={() => setShowErrorNotification(false)} /> : null}
                <div className="button-group">
                    <Button className="default-button proceed" onClick={handleDeleteGroup}>
                        Confirm
                    </Button>
                    <Button className="link-button cancel" onClick={props.handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteGroupConfirmModal
