import React, { useEffect, useState } from 'react';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import ConnectToFamily from '../../components/family/connect-to-family.component';
import DetailedRegistrationInformation from '../../components/authentication/detailed-registration-information.component';
import WelcomeEngage from '../../components/family/welcome-engage.component'; // do not remove
import { ISettings, IUserOptionalData } from '../../interfaces/authentication';
import IPage from '../../interfaces/page';
import { regDetailTabs, welcomeTypes } from '../../_helper/enum/enum';
import instance from '../../api/api';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiCheck } from '@mdi/js';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
import { defaultUserData } from '../../interfaces/family';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

/**
 * Registration page for user authentication
 * @param props : unused for now
 * @returns void
 */
const RegistrationDetails: React.FunctionComponent<IPage> = props => {

    //Controlled tabs
    const [ key, setKey ] = useState(regDetailTabs.OPTIONAL_INFORMATION);
    const [ welcomeType, setWelcomeType ] = useState<string>(welcomeTypes.EMPTY);
    const [ settings, setSettings ] = useState<ISettings>(defaultUserData.settings);

    const [ userDetails, setUserDetails ] = useState<IUserOptionalData>({
        avatar:false,
        country:'',
        state:'',
        birthdate: "",
        weeklyupdate:false,
        newsletter:false,
    });

    const history = useHistory();    

    const { t } = useTranslation();

    useEffect(() => {
        if(props.match.params.lang) {
            i18n.changeLanguage(props.match.params.lang);
            setSettings((prev: any) => { return {...prev, language: props.match.params.lang ? props.match.params.lang : "en"}}); // saved twice, not nice
        }        
        if(welcomeType!==welcomeTypes.EMPTY) {
            updateUserGame(welcomeType)
        }
    }, [welcomeType])  

    async function updateUserData() {

        const updates = {
            //weekly: userDetails.weeklyupdate,
            monthly: userDetails.newsletter
        }

        setSettings((prev: any) => { return {...prev, updates: updates}})

        const details = {
            country: userDetails.country,
            state: userDetails.state,
            birthdate: moment(userDetails.birthdate.toLocaleString()).parseZone().toISOString(),
            settings: {...settings, updates: updates}
        }             

        try {
            await instance.put('/user/' + localStorage.userid, {details:details});
            setKey(regDetailTabs.CONNECT);
        }
        catch(error) {
            console.log('error', error)
        }
    }

    async function updateUserGame(welcomeType: string) {

        // same starter values in API DB. if you change here, change there, too.
        let games : any = {
            welcome: true,
            engage: true
        }

        welcomeType === welcomeTypes.WELCOME ? games.engage = false : games.welcome = false;        
        
        setSettings((prev: any) => { return {...prev, games: games}})

        const details = {
            settings: {...settings, games: games}
        }      

        try {
            await instance.put('/user/' + localStorage.userid, {details:details});
        }
        catch(error) {
            console.log('error', error)
        }
  
    }    

    function getPageTitle() {
        switch (key) {
            case regDetailTabs.OPTIONAL_INFORMATION:
                return t("Account")
            case regDetailTabs.CONNECT:
                return t("Family")
            case regDetailTabs.ENGAGE:
                if (welcomeType === welcomeTypes.WELCOME) {
                    return t("Welcome")
                } else {
                    return t("Engage")
                }
        
            default:
                return t("Account")
        }
    }

    return(
        <Container className="registration-details-container">
            <p className="title">{getPageTitle()}</p>
            <Tabs activeKey={key}>
                <Tab
                    eventKey={regDetailTabs.OPTIONAL_INFORMATION}
                    title={key === regDetailTabs.OPTIONAL_INFORMATION ? regDetailTabs.OPTIONAL_INFORMATION : <Icon className="done-icon" size={1} path={ mdiCheck } />}
                    tabClassName={key === regDetailTabs.OPTIONAL_INFORMATION ? "" : "completed"}
                    >
                    <DetailedRegistrationInformation setUserDetails={setUserDetails} userDetails={userDetails} nextStep={setKey} updateUserData={updateUserData}/>
                    
                </Tab>
                <Tab
                    eventKey={regDetailTabs.CONNECT}
                    title={key === regDetailTabs.ENGAGE ? <Icon className="done-icon" size={1} path={ mdiCheck } /> : regDetailTabs.CONNECT }
                    tabClassName={key === regDetailTabs.ENGAGE ? "completed" : ""}
                    >
                    <ConnectToFamily setWelcomeType={setWelcomeType} next={() => setKey(regDetailTabs.ENGAGE)}/>

                    <Button className="default-button" onClick={() => setKey(regDetailTabs.ENGAGE)}>{t("Next Step")}<Icon size={1} path={ mdiChevronRight } /></Button>

                </Tab>
                <Tab eventKey={regDetailTabs.ENGAGE} title="3">
                    <div className="content">
                        <p className="subtitle">Take a quick tour of the app</p>
                        <div className="iframe-empty-page">
                            <iframe src={`https://player.vimeo.com/video/850122134`} width="460" height="232" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                        </div>
                        <p className="subtitle">Watch more videos in the 'NEED HELP?' sections<br/>at the bottom of most pages</p>
                        <Button className="default-button" onClick={() => history.push("/")}>Finish</Button>
                    </div>
                    {/*<WelcomeEngage welcomeType={welcomeType}/>*/}
                </Tab>
            </Tabs>
        </Container>
    );
};

export default RegistrationDetails;
