import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import Icon from '@mdi/react';
import moment from "moment";
import { dateFormat } from '../../../_helper/enum/enum';
import instance from "../../../api/api";
import { Context } from "../../../pages/home.page";
import default_avatar from "../../../assets/images/default-avatar.jpg";
import { IRequest } from "./story-recieved-requests.component";
import StoryRequest, { RequestStatus } from "./story-request-component";
import { mdiBlockHelper, mdiCheckCircle, mdiClock, mdiDotsHorizontal } from "@mdi/js";
import StoryRequestActionModal from "./story-request-action-modal.component";
import { useHistory, useLocation, useParams } from "react-router-dom";

interface IProps {
    update: number
    requestId?: string    
}

export function getStatus(request:IRequest) {
    let status = RequestStatus.PENDING;
    let acceptedCount = 0;
    let declinedCount = 0;
    request.to.forEach(toUser => {
        if(toUser.status === RequestStatus.ACCEPTED) {
            acceptedCount++
        };
        if(toUser.status === RequestStatus.DECLINED) {
            declinedCount++
        };
    });

    if(declinedCount === request.to.length) {
        status = RequestStatus.DECLINED;
    }

    if(acceptedCount > 0) {
        status = RequestStatus.ACCEPTED;
    }
    
    return status;
}

export default function StorySentRequests(props:IProps) {

    const [ sentRequests, setSentRequests ] = useState<IRequest[]>([]);
    const [ isSingleRequest, setIsSingleRequest ] = useState<IRequest | boolean>(false);   
    const [ selected, setSelected ] = useState<IRequest | boolean>(false);     
    const [ scroll, setScroll ] = useState<boolean>(false);

    const userData = useContext(Context).user;
    const history = useHistory();    
    const location: any = useLocation();   
    const params : any = useParams();

    useEffect(() => {
        if ( userData._id.length ) { 
            getSentRequests();
        }
    }, [userData, props.update]);

    useEffect(() => {
        if(params.list==='list') { 
            setIsSingleRequest(false);      
        }
    }, [location, params.list]);

    useEffect(() => {
        if(location.state) {         
            if(location.state.hasOwnProperty('scroll')) {
                setScroll(true);
            }
        }
    }, [location.state])
    
    useEffect(() => {
        // manage if requestId comes from a notification
        if(props.requestId && sentRequests.length){
            const temp = sentRequests.filter(request => request._id === props.requestId);
            if(temp) setIsSingleRequest(temp[0]);
            history.replace("/dashboard/requests", {});
        }
    }, [props.requestId, sentRequests]);       

    const getSentRequests = useCallback(async () => {
        try {
            const response = await instance.get("/requests?from=" + userData._id);
            setSentRequests(response.data);
        }
        catch(err) {

        }
    }, [userData._id]);

    function setList(){
        setIsSingleRequest(false);
    }

    function updateList(){
        getSentRequests();
    }

    function getStatusDisplay(status:any) {
        return (
            <div className={`status ${status}`} title={status} onClick={(e) => { e.stopPropagation(); }}>
                <Icon className="action" size={status === RequestStatus.ACCEPTED ? 0.8 : status === RequestStatus.DECLINED ? 0.6 : 0.8} path={ status === RequestStatus.ACCEPTED ? mdiCheckCircle : status === RequestStatus.DECLINED ? mdiBlockHelper : mdiClock} />
            </div> 
        );
    }

    return(
        <div className="unpublished-stories sent-request-story-attached-container">
            {!isSingleRequest ? sentRequests.length ? <p className='information'>Sent Requests</p> : <p className='information'>You haven't sent any request yet.</p> : null}
            {!isSingleRequest && sentRequests?.map(request => {
                return(                                       
                    <div key={request._id} className="story-requests-sent up-story story-container" onClick={() => {setIsSingleRequest(request);history.push("/dashboard/requests/2/request")}}>
                    {/* <div className="story-container"> */}

                        <p className='story-title'>{request.title}</p>
                        
                        {getStatusDisplay(getStatus(request))}

                        <div className="action-container" onClick={(e) => { e.stopPropagation(); setSelected(request); }}>
                            <Icon className="action" size={1} path={ mdiDotsHorizontal } />
                        </div>

                        <span className="to-user-information">Sent to:</span>
                        {request.to.map((toUser, index, allToUsers) => {
                            if(index < 3)
                                return(
                                    <div key={toUser._id} className="img-author">                        
                                        <img className="user-picture" 
                                                src={toUser.user.avatar ? toUser.user.avatar : default_avatar} 
                                                alt={toUser.user.nickname ? toUser.user.nickname : toUser.user.firstname}
                                                title={toUser.user.nickname ? toUser.user.nickname : toUser.user.firstname}
                                                />
                                    </div>
                                )
                            else if(index+1 === allToUsers.length)
                                return(<span className="to-user-information to-user-plus">+{allToUsers.length-3}</span>)
                        })}
                        
                        {/*loggedInUserData._id.length ?
                        <div>
                            {loggedInUserData.lastVisited < story.createdAt ? <div className="flair" onClick={(e) => { e.stopPropagation(); setShowNew(true)}}>
                                <img alt="flag" src={green_flag}/>
                            </div> : null}
                            { (!story.readBy.includes(loggedInUserData._id) && (story.author as IUserDetails)._id !== loggedInUserData._id) ? <div className="flair" onClick={(e) => { e.stopPropagation(); setShowUnRead(true)}}>
                                <img alt="flag" src={gold_flag}/>
                            </div> : null }
                        </div>
                    : null*/ }
                        <p className="last-modified">{moment(request.createdAt).format(dateFormat.SHORT)}</p>                      
                    </div>                          
                );                
            })}
            {selected && <StoryRequestActionModal request={(selected as IRequest)} show={selected ? true : false} onHide={() => setSelected(false)} updateList={updateList} />}            
            {isSingleRequest ? <StoryRequest type={"sent"} request={isSingleRequest} setList={setList} updateList={updateList} scroll={scroll} /> : null}
        </div>        
    );
}