import React, { Dispatch, useCallback, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actionCreators";
import { useHistory } from "react-router-dom";

/**
 * /settings/deleteprofile page
 * 
 */

function DeleteProfile() {

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    useEffect(() => {
        setPageName('Delete Profile');
    }, [])     

    const history = useHistory();

    return (
        <div className="settings final">
            <Row className="black">
                <Col xs={12}>
                    <p>Should you wish, you can delete your profile and all data associated with it. Please consider this carefully, as this action can not be undone.</p>
                    <p><b>Deleting your profile result in:</b></p>
                    <ol>
                        <li>Your profile being deleted</li>
                        <li>You will be removed from all family contact lists</li>
                        <li>All your stories will be deleted</li>
                        <li>You will no longer have an account or be able to login</li>
                        <li>You will no longer be able to access your family online and their stories</li>
                    </ol>
                    <p><b>Alternatively, consider unpublishing or deleting specific stories.</b></p>
                    <p>To proceed, email <a href = "mailto: info@simirity.com">info@simirity.com</a> from your Simirity account email address and our customer support team will contact you.</p>
                </Col>
            </Row>       
        </div>
    )
}

export default DeleteProfile
