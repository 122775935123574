import { useEffect, useState } from "react";
import { blockTypes, IBlock } from "../../../../interfaces/story";
import { INotification, NotificationType } from "../../../../interfaces/notification";
import Notification, { notificationImages } from "../../../notifications/notification.component";
import config from "../../../../config/config";
import Help from "../../../_helper/help.component";

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    description?: string
}

const errorNotificationData : INotification = {
    icon: notificationImages.crying,
    title: "Failed",
    text: "The embed code you entered does not match the format of the providers available.",
    success: NotificationType.problem
}

export default function EmbedBlockInput(props: IProps) {

    const [ data, setData ] = useState<string>('');
    const [ desc, setDesc ] = useState<string>('');

    //notifications
    const [ errorNotification, setErrorNotification ] = useState(false);    

    useEffect(() => {
        if(props.data) {
            setData((props.data as IBlock).data);
        }

        if((props.data as IBlock).description) {
            setDesc(((props.data as IBlock).description as string));
        }


    }, [])

    function stripInput(data: string){

        // strip carriage return
        data = data.replace(/[\n\r]+/g, '');

        // strip stuff before and after iframe
        data = data.replace(/(.*)(<iframe.*<\/iframe>)(.*)/g, '$2');
        
        return data;

    }    

    function getUserInputType(data: string){

        let regex : RegExp;
        let regexURL : RegExp;
        let regexURL2 : RegExp;
        let providerName: string = "";
        let inputType : string = "";

        config.embedProviders.forEach((item: any) => {
            regex = new RegExp(item.regExp, "g");
            regexURL = new RegExp(item.regExpURL, "g");
            regexURL2 = new RegExp(item.regExpURL2, "g");
            if(data.match(regex) && (data.match(regex) as string[])[0] === data) { providerName = item._id; inputType = "EMBED";}
            if((data.match(regexURL) && (data.match(regexURL) as string[])[0] === data) || 
                (data.match(regexURL2) && (data.match(regexURL2) as string[])[0]) === data) { providerName = item._id; inputType = "URL";}
        });
        
        return {providerName, inputType}

    }

    function URLtoEmbed(data: string, providerName: string){
        
        let regex : RegExp;
        let regexStart : RegExp;
        let regex2 : RegExp;
        let refactoredData : string = "";

        switch(providerName){
            case "YOUTUBE_VIDEO_NOCOOKIE":
                regex = new RegExp(/https:\/\/youtu\.be\/(.*)/, "g");
                regexStart = new RegExp(/https:\/\/youtu\.be\/(.*)\\?t=(\d*)/, "g");
                regex2 = new RegExp(/https:\/\/youtube\.com\/live\/(.*)\?(.*)/, "g");

                if(data.match(regexStart))
                    // start time set
                    refactoredData = data.replace(regexStart, '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/$1?start=$2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')
                else if(data.match(regex))
                    // default
                    refactoredData = data.replace(regex, '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/$1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')                

                if(data.match(regex2))
                // default
                    refactoredData = data.replace(regex2, '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/$1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')                

            break;
            case "SPOTIFY":
                regex = new RegExp(/https:\/\/open\.spotify\.com\/(.*)\/(.*)/, "g");
                if(data.match(regex))
                    // default
                    refactoredData = data.replace(regex, '<iframe src="https://open.spotify.com/embed/$1/$2" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>')                
            break;            
        }
        
        return refactoredData;

    }

    function resizeIframe(data: string){

        let refactoredData : string = "";
        
        const originalWidth = data.match(/width=\"(\d+)\"/);
        const originalHeight = data.match(/height=\"(\d+)\"/);

        if(originalWidth && originalHeight) {
            refactoredData = data.replace(/(.*)width=\"(\d+)\"(.*)/,'$1width="500"$3');
            const ratio = 500 / parseInt(originalWidth[1]);
            const newHeight = Math.floor(ratio * parseInt(originalHeight[1]));
            refactoredData = refactoredData.replace(/(.*)height=\"(\d+)\"(.*)/,'$1height="'+newHeight+'"$3');        
        } else {
            // keep original if:
            // - width="100%"
            // - unexpected
            refactoredData = data;
        }

        return refactoredData;

    }    

    function addToStory() {

        if(!data) return;

        let refactoredData : string = "";

        // strip input
        refactoredData = stripInput(data);

        // get user input: url or embed?
        const { providerName, inputType } = getUserInputType(refactoredData);

        if(!inputType) {
            setErrorNotification(true)
            return;
        }        

        // transform URL into Iframe
        refactoredData = inputType === "URL" ? URLtoEmbed(refactoredData, providerName) : refactoredData;     

        // resize Iframe for nicer look
        refactoredData = resizeIframe(refactoredData);

        props.addBlockToStory({data:refactoredData, description:desc, tags:[providerName], type:blockTypes.EMBED})

    }      

    return(
        <div className="linkalbum-container">
            <div className="inner">
                <p className="subtitle">Either add a share link or an embed code </p>           
                <div className="inputs">
                    <div className="group">
                        <textarea placeholder="Share Link / Embed Code" rows={3} value={data} onChange={(evt) => evt.target.value.length <= 900 ? setData(evt.target.value) : false} />
                        <label className="top-label">Share Link / Embed Code</label>
                        <p className="sub-subtitle right">{data.length} / 900</p>
                        <p>Examples include:</p>
                        <ul>
                            <li>Youtube video (share link or embed code)</li>
                            <li>Vimeo video (embed code)</li>
                            <li>Facebook video (embed code)</li>
                            <li>Spotify podcast, song, album, playlist (share link or embed code)</li>
                        </ul>
                    </div>
                    <div className="group">
                        <input placeholder="Description" value={desc} onChange={(evt) => evt.target.value.length <= 50 ? setDesc(evt.target.value) : false} />
                        <label className="top-label">Description</label>
                        <p className="sub-subtitle right">{desc.length} / 50</p>                    
                    </div>
                </div>                  

                {errorNotification ? <Notification data={errorNotificationData} close={() => setErrorNotification(false)}/> : null}            

                <button className="default-button" disabled={(data.length > 0 ? false : true)} onClick={() => addToStory()}>Add to Story</button>
            </div>            
            <Help category={"PUBLISHING"} pageName={"Adding Embed"} />           
        </div>
        )
}